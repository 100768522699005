import React from "react";
import { Text, View } from "@react-pdf/renderer";

const Address = ({ styles, data, title }) => {
  return (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.invoice}>{title}</Text>
          <Text style={styles.invoiceNumber}>code de bon: {data.id}</Text>
        </View>
      </View>
    </View>
  );
};

export default Address;
