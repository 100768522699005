import CustomButton from "components/Button/CustomButton";
import React, { useCallback } from "react";
import * as XLSX from "xlsx";

const CustomExcelDownload = ({ data }) => {
  const exportToExcel = useCallback(async () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "DataExport.xlsx");
  }, [data]);

  return (
    <CustomButton
      variant={"contained"}
      color={"primary"}
      children={"Télecharger Excel format"}
      onClick={exportToExcel}
    />
  );
};

export default CustomExcelDownload;
