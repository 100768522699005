import React, { useMemo, useState } from "react";
import useArticle from "hooks/useArticle";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomButton from "components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomModal from "components/Modal/CustomModal";

import CustomInput from "components/Input/CustomInput";
import CustomGrid from "components/Grid/CustomGrid";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";
import useConfined from "hooks/useConfined";
import { deleteConfined } from "store/confined/actions";
import useSide from "hooks/useSide";
import { addSide, deleteSide } from "store/side/actions";
import useConsommationMateriel from "hooks/useConsommationMateriel";
import { deleteConsommationMateriel } from "store/consommationMateriel/actions";

const ConsommationMateriels = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useConsommationMateriel();
  const { t } = useTranslation();
  const [distributeur, setDistributeur] = useState("");
  const [agent, setAgent] = useState("");
  const [quantite, setQuantite] = useState("");
  const [engin, setEngin] = useState("");
  const [typeConsommable, setTypeConsommable] = useState("");

  const typeData = [
    { label: "gasoil", value: "Gasoil" },
    { label: "diesel", value: "Diesel" },
    { label: "gaz", value: "Gaz" },
  ];

  const language = useSelector((state) => state.global.language);
  const agentData = useSelector((state) => state.agentReducer.data);
  const enginData = useSelector((state) => state.enginReducer.data);

  const transformedAgentData = agentData.map((item) => ({
    label: item.nom + " " + item.prenom,
    value: item.nom + " " + item.prenom,
  }));

  const transformedEnginData = enginData.map((item) => ({
    id: item.id_engin,
    label: item.mf,
  }));

  const dispatch = useDispatch();

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {};
    dispatch(addSide(postData));
    handleClose();
  };
  const handleUpdate = (e, articleId) => {
    e.preventDefault();

    // dispatch(updateArticle({ articleId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (consId) => {
    dispatch(deleteConsommationMateriel(consId));
  };
  const columns = [
    {
      field: "distributeur",
      headerName: "distributeur",
      flex: 0.4,
    },
    {
      field: "chauffeur",
      headerName: "agent",
      flex: 0.4,
    },
    {
      field: "quantite",
      headerName: "quantite",
      flex: 0.4,
    },
    {
      field: "type_consommable",
      headerName: "type consommable",
      flex: 0.4,
    },
    {
      field: "materiel",
      headerName: "materiel",
      flex: 0.4,
    },
    {
      field: "date",
      headerName: "date",
      flex: 0.4,
    },

    {
      field: "modifier",
      headerName: t("articles.modifier"),
      flex: 0.3,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("articles.supprimer"),
      flex: 0.3,
      renderCell: (params) => (
        <CustomButton
          children={t("famille.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const dateCreate = new Date(item.date);
      const formattedDate = dateCreate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return {
        id: item.id,
        distributeur: item.distributeur,
        date: formattedDate,
        chauffeur: item.chauffeur,
        quantite: item.quantite,
        type_consommable: item.type_consommable,
        materiel: item.id_materiel,
      };
    });
  }, [data]);
  const createConfinedButton = (
    <CustomButton
      children={"cree un brigade"}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );

  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste des brigades de police"}
        search={handleSearchChange}
        button={createConfinedButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("articles.creerarticle")}
              </Typography>
              <CustomInput
                label={"distributeur"}
                onChange={handleChange(setDistributeur)}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("articles.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default ConsommationMateriels;
