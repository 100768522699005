import { Box, Button, Typography, useTheme } from "@mui/material";
import CustomDataGrid from "components/DataGrid/DataGrid";
import Header from "components/Header/Header";
import useFournisseur from "hooks/useFournisseur";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFournisseur,
  deleteFournisseur,
  updateFournisseur,
} from "store/fournisseur/actions";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/Button/CustomButton";
import CustomModal from "components/Modal/CustomModal";
import CustomInput from "components/Input/CustomInput";
import { useTranslation } from "react-i18next";

const Fournisseur = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useFournisseur();

  const [fax, setFax] = useState("");
  const [raisonSociale, setRaisonSociale] = useState("");
  const [matricule, setMatricule] = useState("");
  const [adress, setAdress] = useState("");
  const [telephone, setTelephone] = useState(0);
  const [email, setEmail] = useState("");
  const [identificateur, setIdentificateur] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);
  const handleRaisonSocialeChange = (data) => {
    setRaisonSociale(data);
  };
  const handleMatriculeFiscaleChange = (data) => {
    setMatricule(data);
  };
  const handleIdentificateurChange = (data) => {
    setIdentificateur(data);
  };
  const handleAdressChange = (data) => {
    setAdress(data);
  };
  const handleTelephoneChange = (data) => {
    setTelephone(data);
  };
  const handleEmailChange = (data) => {
    setEmail(data);
  };
  const handleFaxChange = (data) => {
    setFax(data);
  };

  const handleUpdate = (e, fournisseurId) => {
    e.preventDefault();
    const selectedFournisseur = data.find(
      (fournisseur) => fournisseur.code_fournisseur === fournisseurId
    );
    const updatedData = {
      raison_sociale:
        raisonSociale !== ""
          ? raisonSociale
          : selectedFournisseur.raison_sociale,
      matricule_fiscale:
        matricule !== "" ? matricule : selectedFournisseur.matricule_fiscale,
      identificateur:
        identificateur !== ""
          ? identificateur
          : selectedFournisseur.identificateur,
      telephone: telephone !== 0 ? telephone : selectedFournisseur.telephone,
      email: email !== "" ? email : selectedFournisseur.email,
      adresse: adress !== "" ? adress : selectedFournisseur.adresse,
      fax: fax !== "" ? fax : selectedFournisseur.fax,
      etat: 1,
    };
    dispatch(updateFournisseur({ fournisseurId, updatedData }));
    handleUpdateClose();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      raison_sociale: raisonSociale,
      matricule_fiscale: matricule,
      identificateur: identificateur,
      adresse: adress,
      telephone: telephone,
      email: email,
      fax: fax,
      etat: 1,
    };
    dispatch(addFournisseur(postData));
    handleClose();
  };
  const handleDelete = async (depotId) => {
    dispatch(deleteFournisseur(depotId));
  };

  //************  GRID DATA ***********\\
  const columns = [
    {
      field: "raison_sociale",
      headerName: t("frs.raisonsociale"),
      flex: 1,
    },
    {
      field: "matricule_fiscale",
      headerName: t("frs.mf"),
      flex: 0.7,
    },
    {
      field: "identificateur",
      headerName: t("frs.id"),
      flex: 0.5,
    },
    {
      field: "adresse",
      headerName: t("frs.adresse"),
      flex: 0.7,
    },
    {
      field: "telephone",
      headerName: t("frs.tel"),
      flex: 0.6,
    },
    {
      field: "email",
      headerName: t("frs.email"),
      flex: 1,
    },
    {
      field: "etat",
      headerName: t("frs.etat"),
      flex: 0.5,
    },
    {
      field: "fax",
      headerName: t("frs.fax"),
      flex: 0.5,
    },
    {
      field: "modifier",
      headerName: t("frs.modifier"),
      flex: 0.8,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("frs.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("frs.supprimer"),
      flex: 0.8,
      renderCell: (params) => (
        <CustomButton
          children={t("frs.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.code_fournisseur,
        raison_sociale: item.raison_sociale,
        matricule_fiscale: item.matricule_fiscale,
        identificateur: item.identificateur,
        adresse: item.adresse,
        telephone: item.telephone,
        email: item.email,
        etat: item.etat === 1 ? "actif" : "inActif",
        fax: item.fax,
      };
    });
  }, [data]);
  const createFournisseurButton = (
    <CustomButton
      children={t("frs.createfournisseur")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={t("frs.list")}
        search={handleSearchChange}
        button={createFournisseurButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />

      {updateOpen && (
        <CustomModal
          key={selected.code_fournisseur}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("frs.editfrs")}
              </Typography>
              <CustomInput
                value={selected.raison_sociale}
                label={t("frs.raisonsociale")}
                onChange={handleRaisonSocialeChange}
              />
              <CustomInput
                value={selected.matricule_fiscale}
                label={t("frs.mf")}
                onChange={handleMatriculeFiscaleChange}
              />
              <CustomInput
                value={selected.identificateur}
                label={t("frs.id")}
                onChange={handleIdentificateurChange}
              />
              <CustomInput
                value={selected.adresse}
                label={t("frs.adresse")}
                onChange={handleAdressChange}
              />
              <CustomInput
                value={selected.telephone}
                label={t("frs.tel")}
                onChange={handleTelephoneChange}
              />
              <CustomInput
                value={selected.email}
                label={t("frs.email")}
                onChange={handleEmailChange}
              />
              <CustomInput
                value={selected.fax}
                label={t("frs.fax")}
                onChange={handleFaxChange}
              />
              <CustomButton
                onClick={(data) =>
                  handleUpdate(data, selected.code_fournisseur)
                }
                size={"large"}
                variant={"contained"}
                children={t("frs.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("frs.createfournisseur")}
              </Typography>
              <CustomInput
                value={raisonSociale}
                label={t("frs.raisonsociale")}
                onChange={handleRaisonSocialeChange}
              />
              <CustomInput
                value={matricule}
                label={t("frs.mf")}
                onChange={handleMatriculeFiscaleChange}
              />
              <CustomInput
                value={identificateur}
                label={t("frs.id")}
                onChange={handleIdentificateurChange}
              />
              <CustomInput
                value={adress}
                label={t("frs.adresse")}
                onChange={handleAdressChange}
              />
              <CustomInput
                value={telephone}
                label={t("frs.tel")}
                onChange={handleTelephoneChange}
              />
              <CustomInput
                value={email}
                label={t("frs.email")}
                onChange={handleEmailChange}
              />
              <CustomInput
                value={fax}
                label={t("frs.fax")}
                onChange={handleFaxChange}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("frs.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Fournisseur;
