import React, { useMemo, useState } from "react";
import useArticle from "hooks/useArticle";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomButton from "components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomModal from "components/Modal/CustomModal";

import CustomInput from "components/Input/CustomInput";
import CustomGrid from "components/Grid/CustomGrid";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";
import useConfined from "hooks/useConfined";
import { deleteConfined } from "store/confined/actions";
import useSide from "hooks/useSide";
import { addSide, deleteSide } from "store/side/actions";
import useConsommable from "hooks/useConsommable";
import {
  addConsommable,
  deleteConsommable,
  updateConsommable,
} from "store/consommable/actions";
const Consommable = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useConsommable();
  const { t } = useTranslation();
  const [distributeur, setDistributeur] = useState("");
  const [quantite, setQuantite] = useState(0);
  const [nbBidon, setNbBidon] = useState(0);
  const [typeConsommable, setTypeConsommable] = useState("");
  const typeData = [
    { label: "gasoil", value: "Gasoil" },
    { label: "diesel", value: "Diesel" },
    { label: "gaz", value: "Gaz" },
  ];
  const language = useSelector((state) => state.global.language);
  const defaultTypeOption = typeData.find(
    (option) => option.value == selected.type
  );
  const dispatch = useDispatch();

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };
  const handleTypeConsommableChange = (event, selectedOption) => {
    setTypeConsommable(selectedOption);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      distributeur: distributeur,
      type: typeConsommable.value,
      quantite: quantite,
      nb_bidon: nbBidon,
    };
    dispatch(addConsommable(postData));
    handleClose();
  };
  const handleUpdate = (e, consommableId) => {
    e.preventDefault();
    const updatedData = {
      distributeur: distributeur !== "" ? distributeur : selected.distributeur,
      type:
        typeConsommable.value !== undefined
          ? typeConsommable.value
          : selected.type,
      quantite: quantite !== 0 ? quantite : selected.quantite,
      nb_bidon: nbBidon !== 0 ? nbBidon : selected.nb_bidon,
    };
    dispatch(updateConsommable({ consommableId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (consommableId) => {
    dispatch(deleteConsommable(consommableId));
  };
  const columns = [
    {
      field: "distributeur",
      headerName: "distributeur",
      flex: 0.4,
    },
    {
      field: "type",
      headerName: "type",
      flex: 0.4,
    },
    {
      field: "quantite",
      headerName: "quantité",
      flex: 0.4,
    },
    {
      field: "nb_bidon",
      headerName: "nombre de bidon",
      flex: 0.4,
    },
    {
      field: "date",
      headerName: "date",
      flex: 0.4,
    },

    {
      field: "modifier",
      headerName: t("articles.modifier"),
      flex: 0.3,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("articles.supprimer"),
      flex: 0.3,
      renderCell: (params) => (
        <CustomButton
          children={t("famille.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const dateCreate = new Date(item.date);
      const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return {
        id: item.id,
        distributeur: item.distributeur,
        type: item.type,
        quantite: item.quantite,
        date: formattedDateCreate,
        nb_bidon: item.nb_bidon,
      };
    });
  }, [data]);
  const createConfinedButton = (
    <CustomButton
      children={"cree un carburant"}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );

  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste des carburant"}
        search={handleSearchChange}
        button={createConfinedButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.id}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {"modifier un carburant"}
              </Typography>

              <CustomInput
                value={selected.distributeur}
                label={"distributeur"}
                onChange={handleChange(setDistributeur)}
              />
              <CustomSelect
                defaultValue={defaultTypeOption}
                options={typeData}
                label={"type de consommable"}
                onChange={handleTypeConsommableChange}
              />
              <CustomInput
                value={selected.quantite}
                type={"number"}
                label={"quantite"}
                onChange={handleChange(setQuantite)}
              />
              <CustomInput
                value={selected.nb_bidon}
                type={"number"}
                label={"nombre bidon"}
                onChange={handleChange(setNbBidon)}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.id)}
                size={"large"}
                variant={"contained"}
                children={t("cat.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                Crée un carburant
              </Typography>
              <CustomInput
                label={"distributeur"}
                onChange={handleChange(setDistributeur)}
              />
              <CustomSelect
                options={typeData}
                label={"type de consommable"}
                onChange={handleTypeConsommableChange}
              />
              <CustomInput
                type={"number"}
                label={"quantite"}
                onChange={handleChange(setQuantite)}
              />
              <CustomInput
                type={"number"}
                label={"nombre bidon"}
                onChange={handleChange(setNbBidon)}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("articles.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Consommable;
