import { Text, View } from "@react-pdf/renderer";
import React from "react";

const TableHead = ({ styles, quantiteInput, quantite }) => {
  return (
    <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
      <View style={[styles.theader, styles.theader2]}>
        <Text>Code</Text>
      </View>
      <View style={styles.theader}>
        <Text>Designation</Text>
      </View>
      <View style={styles.theader}>
        <Text>{quantite}</Text>
      </View>
      <View style={styles.theader}>
        <Text>{quantiteInput}</Text>
      </View>
    </View>
  );
};

export default TableHead;
