import { createSlice } from "@reduxjs/toolkit";
import { getArticleNonMouve, getArticleVariation, getNbBonSortie, getTotalArticles, getTotalSites, getTotalUsers } from "./actions";


const initialState = {
  articleCount: "",
  sitesCount: "",
  usersCount: "",
  nbBonSortie: "",
  articleNonMouve: [],
  articleVariation: [],
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const dashboardReducer = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTotalArticles.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getTotalArticles.fulfilled, (state, action) => {
        state.articleCount = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getTotalArticles.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
      builder
      .addCase(getTotalSites.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getTotalSites.fulfilled, (state, action) => {
        state.sitesCount = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getTotalSites.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
      builder
      .addCase(getTotalUsers.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getTotalUsers.fulfilled, (state, action) => {
        state.usersCount = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getTotalUsers.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
      builder
      .addCase(getNbBonSortie.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getNbBonSortie.fulfilled, (state, action) => {
        state.nbBonSortie = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getNbBonSortie.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
      builder
      .addCase(getArticleNonMouve.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getArticleNonMouve.fulfilled, (state, action) => {
        state.articleNonMouve = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getArticleNonMouve.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
      builder
      .addCase(getArticleVariation.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getArticleVariation.fulfilled, (state, action) => {
        state.articleVariation = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getArticleVariation.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

export default dashboardReducer.reducer;
