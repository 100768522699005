import { createSlice } from "@reduxjs/toolkit";
import {
  handleSelectAction,
  handleClearSelectedAction,
  getFournisseurs,
  addFournisseur,
  deleteFournisseur,
  updateFournisseur,
} from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const fournisseurReducer = createSlice({
  name: "fournisseur",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFournisseurs.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getFournisseurs.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getFournisseurs.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addFournisseur.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addFournisseur.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addFournisseur.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(deleteFournisseur.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(deleteFournisseur.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.filteredData = action.payload.newFilteredData;
        state.loadingAll = "succeeded";
      })
      .addCase(deleteFournisseur.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updateFournisseur.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updateFournisseur.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updateFournisseur.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

//Action creators are generated for each case reducer function
export const { handleSelect, handleClearSelected } = fournisseurReducer.actions;

export const selectFournisseur = (state) => state.fournisseur;

export default fournisseurReducer.reducer;
