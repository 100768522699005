import { Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const Header = ({ title, button, Excel, search, isDashboard = false }) => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: "10px" }}>
      <FlexBetween>
        <Typography
          variant="h2"
          color={theme.palette.secondary[200]}
          fontWeight={"bold"}
          sx={{ mb: "5px" }}
        >
          {title}
        </Typography>
        {button}
      </FlexBetween>
      {!isDashboard ? (
        <FlexBetween>
          <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius={"9px"}
            gap={"3rem"}
            p={"0.1rem 1.5rem"}
          >
            <InputBase onChange={search} placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween>
          {Excel}
        </FlexBetween>
      ) : null}
    </Box>
  );
};

export default Header;
