import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomButton from "../../components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import useCategorie from "../../hooks/useCategorie";
import CustomModal from "../../components/Modal/CustomModal";
import CustomInput from "../../components/Input/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategorie,
  deleteCategorie,
  updateCategorie,
} from "../../store/categorie/actions";
// import CustomTypographie from "../../components/Typographie/CustomTypographie";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import { useTranslation } from "react-i18next";

const Categories = () => {
  // const role = window.localStorage.getItem("role");
  const [inputValue, setInputValue] = useState("");
  const [updatedInputValue, setUpdatedInputValue] = useState("");

  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useCategorie();

  const handleInputChange = (data) => {
    setInputValue(data);
  };
  const handleUpdateInputChange = (data) => {
    setUpdatedInputValue(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      designation: inputValue,
      etat: 1,
    };
    dispatch(addCategorie(postData));
    handleClose();
  };
  const handleUpdate = (e, categoryId) => {
    e.preventDefault();

    const selectedCategory = data.find(
      (category) => category.code_categorie === categoryId
    );

    const updatedData = {
      designation:
        updatedInputValue !== ""
          ? updatedInputValue
          : selectedCategory.designation,
      etat: 1,
    };
    dispatch(updateCategorie({ categoryId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (categoryId) => {
    dispatch(deleteCategorie(categoryId));
  };
  const columns = [
    {
      field: "designation",
      headerName: t("cat.designation"),
      flex: 1,
    },
    {
      field: "etat",
      headerName: t("cat.etat"),
      flex: 1,
    },
    {
      field: "modifier",
      headerName: t("cat.modifier"),
      flex: 0.5,
      renderCell: (params) => (
        <CustomButton
          children={t("cat.modifier")}
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        />
      ),
    },
    {
      field: "supprimer",
      headerName: t("cat.supprimer"),
      flex: 0.5,
      renderCell: (params) => (
        // <Button>modifier</Button>
        <CustomButton
          children={t("cat.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];

  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      return {
        id: item.code_categorie,
        designation: item.designation,
        etat: item.etat === 1 ? "actif" : "inActif",
      };
    });
  }, [data]);

  const createCategoryButton = (
    <CustomButton
      children={t("cat.creercategorie")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );

  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={t("cat.listcat")}
        search={handleSearchChange}
        button={createCategoryButton}
      />
      <CustomDataGrid
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.code_categorie}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("cat.modifcat")}
              </Typography>
              <CustomInput
                value={selected.designation}
                label={t("cat.designation")}
                onChange={handleUpdateInputChange}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.code_categorie)}
                size={"large"}
                variant={"contained"}
                children={t("cat.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("cat.creercategorie")}
              </Typography>
              <CustomInput
                value={inputValue}
                label={t("cat.designation")}
                onChange={handleInputChange}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("cat.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Categories;
