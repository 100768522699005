import { Text, View } from "@react-pdf/renderer";
import React from "react";

const UserAddress = ({ styles, data }) => {
  return (
    <View>
      <View style={styles.textRight}>
        <Text style={styles.text}>
          {data.side} {"     "}: الجهة الحاجزة
        </Text>
        <Text style={styles.text}>
          {data.fullName}
          {"     "}:اسم صاحب(ة)المحجوز و لقبه(ها)
        </Text>
        <Text style={styles.text}>
          {data.address} {"     "}:العنوان
        </Text>
        <Text style={styles.text}>
          {data.place} {"     "}: مكان الحجز
        </Text>
        <Text style={styles.text}>
          {data.reason} {"     "}: أسباب الحجز
        </Text>
        <Text style={styles.text}>: نوع و تفاصيل المحجوزات مع ذكر عددها</Text>
      </View>
    </View>
  );
};

export default UserAddress;
