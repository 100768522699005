import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSide = createAsyncThunk(
  "GET-SIDE",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/side/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data.sides;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addSide = createAsyncThunk(
  "ADD-SIDE",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const sideState = getState().sideReducer.data;

      const response = await axios.post("https://www.gbgc.tn/side/", postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return [...sideState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteSide = createAsyncThunk(
  "DELETE-SIDE",
  async (params, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().sideReducer.data;
      const prevFilteredData = getState().sideReducer.filteredData;
      let newData = [];
      let newFilteredData = [];

      await axios.delete(`https://www.gbgc.tn/side/${params}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      newData = [...prevData.filter((item) => item.id !== params)];
      newFilteredData = [
        ...prevFilteredData.filter((item) => item.id !== params),
      ];

      return { newData, newFilteredData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateSide = createAsyncThunk(
  "UPDATE-SIDE",
  async ({ sideId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().sideReducer.data;
      const response = await axios.put(
        `https://www.gbgc.tn/side/${sideId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedSide = response.data;

      const newData = prevData.map((side) =>
        side.id === sideId ? updatedSide : side
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find((s) => s.id === action.payload.key);
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
