import { createSlice } from "@reduxjs/toolkit";
import {
  handleSelectAction,
  handleClearSelectedAction,
  getConsommationMateriel,
  addConsommationMateriel,
  deleteConsommationMateriel,
  updateConsommationMateriel,
} from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const consommationMaterielReducer = createSlice({
  name: "consommationMateriel",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConsommationMateriel.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getConsommationMateriel.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getConsommationMateriel.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addConsommationMateriel.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addConsommationMateriel.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addConsommationMateriel.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(deleteConsommationMateriel.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(deleteConsommationMateriel.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.filteredData = action.payload.newFilteredData;
        state.loadingAll = "succeeded";
      })
      .addCase(deleteConsommationMateriel.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updateConsommationMateriel.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updateConsommationMateriel.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updateConsommationMateriel.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

//Action creators are generated for each case reducer function
export const { handleSelect, handleClearSelected } =
  consommationMaterielReducer.actions;

export const selectConsommationMateriel = (state) => state.consommationMateriel;

export default consommationMaterielReducer.reducer;
