import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getInventaire = createAsyncThunk(
  "GET-INVENTAIRE",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/inventaire/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addInventaire = createAsyncThunk(
  "ADD-INVENTAIRE",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const InventaireState = getState().inventaireReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/inventaire/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return [...InventaireState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateInventaire = createAsyncThunk(
  "UPDATE-INVENTAIRE",
  async ({ inventaireId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().inventaireReducer.data;

      // Assuming your backend API supports updating a category by its ID
      const response = await axios.put(
        `https://www.gbgc.tn/inventaire/modif/${inventaireId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      // Assuming your backend returns the updated category data
      const updatedInventaire = response.data;

      // Replace the old category data with the updated one
      const newData = prevData.map((inventaire) =>
        inventaire.code_inventaire === inventaireId
          ? updatedInventaire
          : inventaire
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//TODO: complete after update
export const gereInventaire = createAsyncThunk(
  "GERE-INVENTAIRE",
  async ({ inventaireId, updatedData }, { rejectWithValue, getState }) => {
    console.log("🚀 ~ inventaireId:", inventaireId);
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().inventaireReducer.data;
      const response = await axios.put(
        `https://www.gbgc.tn/inventaire/update/${inventaireId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      const updatedInventaire = response.data;
      console.log("🚀 ~ updatedInventaire:", updatedInventaire);
      const newData = prevData.map((inventaire) =>
        inventaire.code_inventaire === inventaireId
          ? updatedInventaire
          : inventaire
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_inventaire === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
