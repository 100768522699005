import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getArticleNonMouve,
  getArticleVariation,
  getNbBonSortie,
  getTotalArticles,
  getTotalSites,
  getTotalUsers,
} from "store/Dashboard/actions";

const useDashboard = () => {
  const {
    articleCount,
    sitesCount,
    usersCount,
    nbBonSortie,
    articleNonMouve,
    articleVariation,
    loadingAll,
    loading,
    error,
  } = useSelector((state) => state.dashboardReducer);
  const dispatch = useDispatch();
  const handleArticlechange = (e, selectedOption) => {
    console.log(
      "🚀 ~ handleArticlechange ~ selectedOption:",
      selectedOption.code_article
    );
    dispatch(getArticleVariation(selectedOption.code_article));
  };
  const fetchDashboard = useCallback(async () => {
    dispatch(getTotalArticles());
    dispatch(getTotalSites());
    dispatch(getTotalUsers());
    dispatch(getNbBonSortie());
    dispatch(getArticleNonMouve());
  }, [dispatch]);

  useEffect(() => {
    fetchDashboard();
  }, [dispatch, fetchDashboard]);

  return {
    articleCount,
    sitesCount,
    usersCount,
    nbBonSortie,
    articleNonMouve,
    articleVariation,
    loadingAll,
    loading,
    error,
    handleArticlechange,
  };
};

export default useDashboard;
