import { Box, Button, Typography, useTheme } from "@mui/material";
import CustomButton from "components/Button/CustomButton";
import CustomDataGrid from "components/DataGrid/DataGrid";
import Header from "components/Header/Header";
import CustomInput from "components/Input/CustomInput";
import CustomModal from "components/Modal/CustomModal";
import useDepot from "hooks/useDepot";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDepot, deleteDepot, updateDepot } from "store/depot/actions";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const Depot = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useDepot();

  const [inputValue, setInputValue] = useState("");
  const [updatedInputValue, setUpdatedInputValue] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);

  const handleInputChange = (data) => {
    setInputValue(data);
  };
  const handleUpdateInputChange = (data) => {
    setUpdatedInputValue(data);
  };
  const handleUpdate = (e, depotId) => {
    e.preventDefault();
    const selectedDepot = data.find((depot) => depot.code_depot === depotId);
    const updatedData = {
      designation:
        updatedInputValue !== ""
          ? updatedInputValue
          : selectedDepot.designation,
      etat: 1,
    };
    dispatch(updateDepot({ depotId, updatedData }));
    handleUpdateClose();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      designation: inputValue,
      etat: 1,
    };
    dispatch(addDepot(postData));
    handleClose();
  };
  const handleDelete = async (depotId) => {
    dispatch(deleteDepot(depotId));
  };

  //************  GRID DATA ***********\\
  const columns = [
    {
      field: "designation",
      headerName: t("dp.designation"),
      flex: 1,
    },
    {
      field: "modifier",
      headerName: t("dp.modifier"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("dp.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("dp.supprimer"),
      flex: 0.5,
      renderCell: (params) => (
        <CustomButton
          children={t("dp.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      return {
        id: item.code_depot,
        designation: item.designation,
      };
    });
  }, [data]);
  const createDepotButton = (
    <CustomButton
      children={t("dp.createdepot")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={t("dp.list")}
        search={handleSearchChange}
        button={createDepotButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.code_depot}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("dp.editdepot")}
              </Typography>
              <CustomInput
                value={selected.designation}
                label={t("dp.designation")}
                onChange={handleUpdateInputChange}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.code_depot)}
                size={"large"}
                variant={"contained"}
                children={t("dp.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("dp.createdepot")}
              </Typography>
              <CustomInput
                value={inputValue}
                label={t("dp.designation")}
                onChange={handleInputChange}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("dp.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Depot;
