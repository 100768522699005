import { createSlice } from "@reduxjs/toolkit";
import {
  handleSelectAction,
  handleClearSelectedAction,
  getConsommable,
  addConsommable,
  deleteConsommable,
  updateConsommable,
} from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const consommableReducer = createSlice({
  name: "consommable",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConsommable.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getConsommable.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getConsommable.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addConsommable.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addConsommable.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addConsommable.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(deleteConsommable.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(deleteConsommable.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.filteredData = action.payload.newFilteredData;
        state.loadingAll = "succeeded";
      })
      .addCase(deleteConsommable.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updateConsommable.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updateConsommable.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updateConsommable.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

//Action creators are generated for each case reducer function
export const { handleSelect, handleClearSelected } = consommableReducer.actions;

export const selectConsommable = (state) => state.consommable;

export default consommableReducer.reducer;
