import React, { useMemo, useState } from "react";
import useArticle from "hooks/useArticle";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomButton from "components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomModal from "components/Modal/CustomModal";

import CustomInput from "components/Input/CustomInput";
import CustomGrid from "components/Grid/CustomGrid";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";
import useConfined from "hooks/useConfined";
import { deleteConfined } from "store/confined/actions";
import useProduit from "hooks/useProduit";
import {
  addProduit,
  deleteProduit,
  updateProduit,
} from "store/produit/actions";
const Produit = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useProduit();
  const { t } = useTranslation();
  const [designation, setDesignation] = useState("");
  const [type, setType] = useState("");
  const [prix, setPrix] = useState("");

  const language = useSelector((state) => state.global.language);

  const dispatch = useDispatch();

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };
  const handleDesignationChange = handleChange(setDesignation);
  const handleTypeChange = handleChange(setType);
  const handlePrixChange = handleChange(setPrix);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      designation: designation,
      type: type,
      prix: prix,
    };
    dispatch(addProduit(postData));
    handleClose();
  };
  const handleUpdate = (e, ProduitId) => {
    e.preventDefault();
    const updatedData = {
      designation: designation !== "" ? designation : selected.designation,
      type: type !== "" ? type : selected.type,
      prix: prix !== "" ? prix : selected.prix,
    };
    dispatch(updateProduit({ ProduitId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (confinedId) => {
    dispatch(deleteProduit(confinedId));
  };
  const columns = [
    {
      field: "designation",
      headerName: "designation",
      flex: 0.4,
    },
    {
      field: "type",
      headerName: "type",
      flex: 0.4,
    },
    {
      field: "prix",
      headerName: "prix",
      flex: 0.4,
    },

    {
      field: "modifier",
      headerName: t("articles.modifier"),
      flex: 0.3,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("articles.supprimer"),
      flex: 0.3,
      renderCell: (params) => (
        <CustomButton
          children={t("famille.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      return {
        id: item.id,
        designation: item.designation,
        type: item.type,
        prix: item.prix,
      };
    });
  }, [data]);
  const createConfinedButton = (
    <CustomButton
      children={t("articles.creerarticle")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );

  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste des produits"}
        search={handleSearchChange}
        button={createConfinedButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.id}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {"modifier un produit"}
              </Typography>

              <CustomInput
                value={selected.designation}
                label={t("articles.designation")}
                onChange={handleDesignationChange}
              />
              <CustomInput
                value={selected.type}
                label={"type"}
                onChange={handleTypeChange}
              />
              <CustomInput
                value={selected.prix}
                label={"prix"}
                onChange={handlePrixChange}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.id)}
                size={"large"}
                variant={"contained"}
                children={t("cat.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("articles.creerarticle")}
              </Typography>
              <CustomInput
                value={designation}
                label={t("articles.designation")}
                onChange={handleDesignationChange}
              />
              <CustomInput
                value={type}
                label={"type"}
                onChange={handleTypeChange}
              />
              <CustomInput
                value={prix}
                label={"prix"}
                onChange={handlePrixChange}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("articles.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Produit;
