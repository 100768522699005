import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getPetitMateriel = createAsyncThunk(
  "GET-PETIT-MATERIEL",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/materiel/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addPetitMateriel = createAsyncThunk(
  "ADD-PETIT-MATERIEL",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const petitMaterielState = getState().petitMaterielReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/materiel/create",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return [...petitMaterielState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deletePetitMateriel = createAsyncThunk(
  "DELETE-PETIT-MATERIEL",
  async (params, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().petitMaterielReducer.data;
      const prevFilteredData = getState().petitMaterielReducer.filteredData;
      let newData = [];
      let newFilteredData = [];

      await axios.delete(`https://www.gbgc.tn/materiel/delete/${params}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      newData = [...prevData.filter((item) => item.id_materiel !== params)];
      newFilteredData = [
        ...prevFilteredData.filter((item) => item.id_materiel !== params),
      ];

      return { newData, newFilteredData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updatePetitMateriel = createAsyncThunk(
  "UPDATE-PETIT-MATERIEL",
  async ({ petitMaterielId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().petitMaterielReducer.data;
      const response = await axios.put(
        `https://www.gbgc.tn/materiel/update/${petitMaterielId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedPetitMateriel = response.data;

      const newData = prevData.map((petitMateriel) =>
        petitMateriel.id_materiel === petitMaterielId
          ? updatedPetitMateriel
          : petitMateriel
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.id_materiel === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
