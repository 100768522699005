import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import CustomPDF from "./CustomPDF";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DownloadButton = ({
  row,
  invoiceTitle,
  bonTitle,
  addressTitle,
  depot,
  date,
  quantite,
  quantiteInput,
}) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);

  return (
    <PDFDownloadLink
      document={
        <CustomPDF
          data={row || {}}
          quantite={quantite}
          quantiteInput={quantiteInput}
          invoiceTitle={invoiceTitle}
          bonTitle={bonTitle}
          depot={depot}
          addressTitle={addressTitle}
          date={date}
        />
      }
      fileName={row.id}
    >
      <Button
        color={"primary"}
        startIcon={language === "ar" ? <CloudDownloadIcon /> : null}
        endIcon={language === "fr" ? <CloudDownloadIcon /> : null}
        variant="contained"
      >
        <span>{t("bef.download")}</span>
      </Button>
    </PDFDownloadLink>
  );
};

export default DownloadButton;
