import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSelect } from "store/side/slice";
const useSide = () => {
  const { data, loadingAll, loading, selected, error } = useSelector(
    (state) => state.sideReducer
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUpdateOpen = () => setUpdateOpen(true);
  const handleUpdateClose = () => setUpdateOpen(false);
  const dispatch = useDispatch();
  const _handleSelectOne = (key) => {
    dispatch(handleSelect({ key: key }));
  };
  const handleOpenModal = (key) => {
    _handleSelectOne(key);
    handleUpdateOpen();
  };
  const handleSearchChange = (search) => {
    setSearchTerm(search.target.value);
    if (search) {
      const filteredItems = data.filter((article) =>
        article.designation
          .toLowerCase()
          .includes(search.target.value.toLowerCase())
      );
      setFilteredData(filteredItems);
    } else {
      setFilteredData(data);
    }
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  return {
    data: filteredData,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    setOpen,
    open,
    updateOpen,
    setUpdateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
    searchTerm,
  };
};

export default useSide;
