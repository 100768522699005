import { Text, View } from "@react-pdf/renderer";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

const TableBody = ({ styles, data }) => {
  return (
    data.list_article !== undefined &&
    data.list_article?.map((item, index) => (
      <Fragment key={item.id}>
        <View style={{ width: "100%", flexDirection: "row" }}>
          <View style={[styles.tbody, styles.tbody2]}>
            <Text>{index + 1}</Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.designation} </Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.quantity}</Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.quantityInput}</Text>
          </View>
        </View>
      </Fragment>
    ))
  );
};

export default TableBody;
