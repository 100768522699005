import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import Header from "components/Header/Header";
import { Email } from "@mui/icons-material";
import StatBox from "components/StatBox/StatBox";
import { useSelector } from "react-redux";
import CustomDataGrid from "components/DataGrid/DataGrid";
import { useTranslation } from "react-i18next";
import useDashboard from "hooks/useDashboard";
import CustomLineChart from "components/Charts/CustomLineChart";
import CustomPieChart from "components/Charts/CustomPieChart";
import CustomSelect from "components/Input/CustomSelect";

const Dashboard = () => {
  const {
    articleCount,
    sitesCount,
    usersCount,
    nbBonSortie,
    articleNonMouve,
    articleVariation,
    loading,
    loadingAll,
    error,
    handleArticlechange,
  } = useDashboard();

  const { t } = useTranslation();
  const articleData = useSelector((state) => state.articleReducer.data);
  const familleData = useSelector((state) => state.familleReducer.data);
  const depotData = useSelector((state) => state.depotReducer.data);
  const transformedArticleData = articleData.map((item) => ({
    label: item.designation,
    code_article: item.code_article,
  }));

  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width : 1200px)");
  const columns = [
    {
      field: "designation",
      headerName: t("dashboard.designation"),
      flex: 1,
    },
    {
      field: "famille",
      headerName: t("articles.famille"),
      flex: 1,
    },
    {
      field: "depot",
      headerName: t("articles.depot"),
      flex: 0.5,
    },
    {
      field: "quantite",
      headerName: t("bcf.quantite"),
      flex: 0.5,
    },
  ];

  const dataWithButtons = useMemo(() => {
    const result = articleNonMouve.data
      ?.map((code) => {
        const article = articleData.find((a) => a.code_article === code);
        if (article) {
          const famille = familleData.find(
            (f) => f.code_famille === article.code_famille
          );
          const depot = depotData.find(
            (d) => d.code_depot === article.code_depot
          );
          return {
            code: article.code_article,
            designation: article.designation,
            famille: famille ? famille.designation : null,
            depot: depot ? depot.designation : null,
            quantite: article.stock_actuel,
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    return result?.map((item) => {
      return {
        id: item.code,
        designation: item.designation,
        famille: item.famille,
        depot: item.depot,
        quantite: item.quantite,
      };
    });
  }, [
    loading,
    loadingAll,
    articleData,
    familleData,
    depotData,
    articleNonMouve,
  ]);
  return (
    <Box m={"1.5rem 2.5rem"} pb={"2.5rem"}>
      <Header title={t("menu.dashboard")} isDashboard={true} />
      <Box
        mt={"20px"}
        display={"grid"}
        gridTemplateColumns={"repeat(12 , 1fr)"}
        gridAutoRows={"160px"}
        gap={"20px"}
        sx={{
          "& > div": {
            gridColumn: isNonMediumScreens ? undefined : "span 12",
          },
        }}
      >
        <StatBox title={t("dashboard.nbtotarticles")} value={articleCount} />
        <StatBox title={t("dashboard.nbtotsites")} value={sitesCount} />
        <Box
          gridColumn={"span 8"}
          gridRow={"span 3"}
          backgroundColor={theme.palette.background.alt}
          p={"1rem"}
          borderRadius={"0.55rem"}
        >
          <Typography
            variant="h3"
            fontWeight={"bold"}
            sx={{
              color: theme.palette.secondary[100],
            }}
          >
            {t("dashboard.variationprix")}
            <CustomSelect
              label={t("bcf.prs")}
              options={transformedArticleData}
              onChange={handleArticlechange}
            />
          </Typography>
          <CustomLineChart articleVariation={articleVariation} />
        </Box>
        <StatBox title={t("dashboard.nbtotusers")} value={usersCount} />
        <StatBox title={t("dashboard.nbbonsorties")} value={nbBonSortie} />
        <Box
          gridColumn={"span 8"}
          gridRow={"span 3"}
          backgroundColor={theme.palette.background.alt}
          borderRadius={"0.55rem"}
        >
          <Typography
            variant="h3"
            p={"1rem"}
            fontWeight={"bold"}
            sx={{
              color: theme.palette.secondary[100],
            }}
          >
            {t("dashboard.articlenonmouvement")}
          </Typography>
          <CustomDataGrid
            loading={loadingAll !== "succeeded"}
            getRowId={(row) => row.id}
            dataWithButtons={dataWithButtons || []}
            columns={columns}
            height={"88%"}
          />
        </Box>
        <Box
          gridColumn={"span 4"}
          gridRow={"span 3"}
          backgroundColor={theme.palette.background.alt}
          borderRadius={"0.55rem"}
          p={"1.5rem"}
        >
          <Typography
            variant="h3"
            p={"1rem"}
            fontWeight={"bold"}
            sx={{
              color: theme.palette.secondary[100],
              textAlign: "center",
            }}
          >
            Article count par site
          </Typography>
          <CustomPieChart />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
