import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDepots = createAsyncThunk(
  "GET-DEPOT",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/depot/allDepots", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data[0];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addDepot = createAsyncThunk(
  "ADD-DEPOT",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const depotState = getState().depotReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/depot/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return [...depotState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteDepot = createAsyncThunk(
  "DELETE-DEPOT",
  async (params, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().depotReducer.data;
      const prevFilteredData = getState().depotReducer.filteredData;
      let newData = [];
      let newFilteredData = [];

      await axios.delete(`https://www.gbgc.tn/depot/delete/${params}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      newData = [...prevData.filter((item) => item.code_depot !== params)];
      newFilteredData = [
        ...prevFilteredData.filter((item) => item.code_depot !== params),
      ];

      return { newData, newFilteredData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateDepot = createAsyncThunk(
  "UPDATE-DEPOT",
  async ({ depotId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().depotReducer.data;

      // Assuming your backend API supports updating a category by its ID
      const response = await axios.put(
        `https://www.gbgc.tn/depot/update/${depotId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      // Assuming your backend returns the updated category data
      const updatedDepot = response.data;

      // Replace the old category data with the updated one
      const newData = prevData.map((depot) =>
        depot.code_depot === depotId ? updatedDepot : depot
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_depot === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
