import React from "react";
import { Page, Document, StyleSheet, Text, Font } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import Address from "./Address";
import UserAddress from "./UserAddress";
import TableHead from "../TableHead";
import TableBody from "../TableBody";
import Cairo from "../../../assets/fonts/Cairo/Cairo-Regular.ttf";
import CairoBold from "../../../assets/fonts/Cairo/Cairo-Bold.ttf";
import List from "./List";

const CustomConfinedPDF = ({ data }) => {
  Font.register({
    family: "Cairo",
    fonts: [
      { src: Cairo, fontWeight: 400 },
      { src: CairoBold, fontWeight: 600 },
    ],
    format: "truetype",
  });
  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    text: {
      fontFamily: "Cairo",
    },
    textRight: {
      flexDirection: "column",
      alignItems: "flex-end",
      display: "flex",
      textAlign: "right",
    },
    textRightList: {
      marginTop: 10,
      alignItems: "flex-end",
      display: "flex",
      textAlign: "right",
    },
    spaceBetween: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },
    titleContainer: {
      width: "100%",
      marginRight: 20,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 24,
      fontSize: 14,
      fontWeight: 600,
    },
    logo: { width: 70 },
    reportTitle: { fontSize: 10, textAlign: "center", maxWidth: "50%" },
    tableTitle: {
      marginTop: 20,
      fontSize: 16,
      textAlign: "left",
    },
    addressTitle: {
      fontSize: 11,
      fontStyle: 600,
    },
    invoice: { fontWeight: 600, fontSize: 20 },
    invoiceNumber: { fontSize: 11, fontWeight: 600 },
    address: { fontWeight: 400, fontSize: 10 },
    theader: {
      fontSize: 10,
      fontStyle: 600,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: "#DEDEDE",
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    theader2: { flex: 0.5, borderRightWidth: 0, borderBottomWidth: 1 },
    tbody: {
      fontFamily: "Cairo",
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    tbody2: { flex: 0.5, borderRightWidth: 1 },
  });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle styles={styles} />
        <Address styles={styles} />
        <UserAddress styles={styles} data={data} />
        <List styles={styles} data={data} />
      </Page>
    </Document>
  );
};

export default CustomConfinedPDF;
