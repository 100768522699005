import { BlobProvider } from "@react-pdf/renderer";
import React from "react";
import Link from "@mui/material/Link";
import PrintIcon from "@mui/icons-material/Print";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomConfinedPDF from "./CustomConfinedPDF";

const PrintConfined = ({ row }) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);
  return (
    <BlobProvider document={<CustomConfinedPDF data={row || {}} />}>
      {({ url, blob }) => (
        <Link href={url} target="_blank">
          <Button
            color={"primary"}
            startIcon={language === "ar" ? <PrintIcon /> : null}
            endIcon={language === "fr" ? <PrintIcon /> : null}
            variant="contained"
          >
            <span>{t("bef.imprimer")}</span>
          </Button>
        </Link>
      )}
    </BlobProvider>
  );
};

export default PrintConfined;
