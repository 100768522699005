import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomButton from "../../components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../../components/Modal/CustomModal";
import CustomInput from "../../components/Input/CustomInput";
import { useDispatch, useSelector } from "react-redux";

// import CustomTypographie from "../../components/Typographie/CustomTypographie";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import useUser from "hooks/useUser";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomSelect from "components/Input/CustomSelect";
import { addUser, deleteUser, updateUser } from "store/user/actions";
import { useTranslation } from "react-i18next";

const User = () => {
  // const role = window.localStorage.getItem("role");
  const dispatch = useDispatch();
  const theme = useTheme();
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [site, setSite] = useState(0);
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useUser();
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);

  const siteData = useSelector((state) => state.siteReducer.data);
  const transformedSiteData = siteData.map((item) => ({
    code_site: item.code_site,
    label: item.designation,
  }));
  const roleData = [
    { label: "Gestionaire de stock", value: "GS" },
    { label: "Resposable depot", value: "RS" },
    { label: "Agent administratif", value: "AA" },
    { label: "DSI", value: "DSI" },
    { label: "Super Admin", value: "SA" },
    { label: "Gestionaire De Park", value: "GP" },
    { label: "Gestionaire De Carburant", value: "GC" },
  ];

  const handleNomChange = (data) => setNom(data);
  const handlePrenomChange = (data) => setPrenom(data);
  const handleUsernameChange = (data) => setUsername(data);
  const handlePasswordChange = (data) => setPassword(data);
  const handleRoleChange = (event, selectedOption) => {
    setRole(selectedOption);
  };
  const handleSiteChange = (event, selectedOption) => {
    setSite(selectedOption);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      nom: nom,
      prenom: prenom,
      username: username,
      password: password,
      role: role.value,
      site: site.code_site,
      etat: 1,
    };
    dispatch(addUser(postData));
    handleClose();
  };
  const handleUpdate = (e, userId) => {
    e.preventDefault();

    const selectedUser = data.find((user) => user.id === userId);

    const updatedData = {
      nom: nom !== "" ? nom : selectedUser.nom,
      prenom: prenom !== "" ? prenom : selectedUser.prenom,
      username: username !== "" ? username : selectedUser.username,
      password: password !== "" ? password : selectedUser.password,
      role: role.value !== undefined ? role.value : selectedUser.role,
      site:
        site.code_site !== undefined ? site.code_site : selectedUser.code_site,
      etat: 1,
    };
    dispatch(updateUser({ userId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (userId) => {
    dispatch(deleteUser(userId));
  };
  const columns = [
    {
      field: "nom",
      headerName: t("users.nom"),
      flex: 1,
    },
    {
      field: "prenom",
      headerName: t("users.prenom"),
      flex: 1,
    },
    {
      field: "username",
      headerName: "username",
      flex: 1,
    },
    {
      field: "password",
      headerName: t("users.password"),
      flex: 1,
    },
    {
      field: "role",
      headerName: t("users.role"),
      flex: 1,
    },
    {
      field: "site",
      headerName: t("bci.site"),
      flex: 1,
    },
    {
      field: "etat",
      headerName: t("brf.etat"),
      flex: 1,
    },
    {
      field: "modifier",
      headerName: t("brf.modifier"),
      flex: 0.4,
      renderCell: (params) => (
        <CustomIconButton
          children={<CreateIcon />}
          color={"white"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleOpenModal(params.id)}
        />
      ),
    },
    {
      field: "supprimer",
      headerName: t("frs.supprimer"),
      flex: 0.4,
      renderCell: (params) => (
        <CustomIconButton
          children={<DeleteIcon />}
          color={"white"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];

  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const site = siteData.find((site) => site.code_site === item.code_site);
      const siteDesignation = site ? site.designation : "";
      return {
        id: item.id,
        nom: item.nom,
        prenom: item.prenom,
        username: item.username,
        password: item.password,
        site: siteDesignation,
        role: item.role,
        etat: item.etat === 1 ? "actif" : "inActif",
      };
    });
  }, [data]);

  const createCategoryButton = (
    <CustomButton
      children={t("users.creeruser")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  const defaultSiteOption = transformedSiteData.find(
    (option) => option.code_site === selected.code_site
  );
  const defaultRoleOption = roleData.find(
    (option) => option.value === selected.role
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={t("users.list")}
        search={handleSearchChange}
        button={createCategoryButton}
      />
      <CustomDataGrid
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.id}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("users.edit")}
              </Typography>
              <CustomInput
                value={selected.nom}
                label={t("users.nom")}
                onChange={handleNomChange}
              />
              <CustomInput
                value={selected.prenom}
                label={t("users.prenom")}
                onChange={handlePrenomChange}
              />
              <CustomInput
                value={selected.username}
                label={"Username"}
                onChange={handleUsernameChange}
              />
              <CustomInput
                value={selected.password}
                label={t("users.password")}
                onChange={handlePasswordChange}
              />
              <CustomSelect
                defaultValue={defaultRoleOption}
                onChange={handleRoleChange}
                label={"users.listr"}
                options={roleData}
              />
              <CustomSelect
                defaultValue={defaultSiteOption}
                onChange={handleSiteChange}
                label={t("users.lists")}
                options={transformedSiteData}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.id)}
                size={"large"}
                variant={"contained"}
                children={"modifier"}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("users.creeruser")}
              </Typography>
              <CustomInput
                value={nom}
                label={t("users.nom")}
                onChange={handleNomChange}
              />
              <CustomInput
                value={prenom}
                label={t("users.prenom")}
                onChange={handlePrenomChange}
              />
              <CustomInput
                value={username}
                label={"Username"}
                onChange={handleUsernameChange}
              />
              <CustomInput
                value={password}
                label={t("users.password")}
                onChange={handlePasswordChange}
              />
              <CustomSelect
                onChange={handleRoleChange}
                label={t("users.listr")}
                options={roleData}
              />
              <CustomSelect
                onChange={handleSiteChange}
                label={t("users.lists")}
                options={transformedSiteData}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("frs.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default User;
