import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInventaire } from "store/inventaire/actions";
import { handleSelect } from "store/inventaire/slice";

const useInventaire = () => {
  const { data, loadingAll, loading, selected, error } = useSelector(
    (state) => state.inventaireReducer
  );

  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [gereOpen, setGereOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUpdateOpen = () => setUpdateOpen(true);
  const handleUpdateClose = () => setUpdateOpen(false);
  const handleGereOpen = () => setGereOpen(true);
  const handleGereClose = () => setGereOpen(false);
  const handleDetailOpen = () => setDetailOpen(true);
  const handleDetailClose = () => setDetailOpen(false);
  const dispatch = useDispatch();

  const fetchInventaire = useCallback(async () => {
    dispatch(getInventaire());
  }, [dispatch]);

  useEffect(() => {
    fetchInventaire();
  }, [dispatch, fetchInventaire]);

  const _handleSelectOne = (key) => {
    dispatch(handleSelect({ key: key }));
  };
  const handleOpenModal = (key) => {
    _handleSelectOne(key);
    handleUpdateOpen();
  };
  const handleOpenGereModal = (key) => {
    _handleSelectOne(key);
    handleGereOpen();
  };
  const handleDetailOpenModal = (key) => {
    _handleSelectOne(key);
    handleDetailOpen();
  };
  return {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    setOpen,
    open,
    updateOpen,
    detailOpen,
    setUpdateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    gereOpen,
    handleGereOpen,
    handleGereClose,
    handleOpenGereModal,
    handleDetailClose,
    handleDetailOpenModal,
    handleDetailOpen,
  };
};

export default useInventaire;
