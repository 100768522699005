import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomConfinedPDF from "./CustomConfinedPDF";

const DownloadConfined = ({ row }) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);
  return (
    <PDFDownloadLink
      document={<CustomConfinedPDF data={row || {}} />}
      fileName={row.fullName}
    >
      <Button
        color={"primary"}
        startIcon={language === "ar" ? <CloudDownloadIcon /> : null}
        endIcon={language === "fr" ? <CloudDownloadIcon /> : null}
        variant="contained"
      >
        <span>{t("bef.download")}</span>
      </Button>
    </PDFDownloadLink>
  );
};

export default DownloadConfined;
