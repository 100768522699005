import { createSlice } from "@reduxjs/toolkit";
import { handleSelectAction, handleClearSelectedAction, getUser, addUser, deleteUser, updateUser } from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addUser.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(deleteUser.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.filteredData = action.payload.newFilteredData;
        state.loadingAll = "succeeded";
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updateUser.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

export const { handleSelect, handleClearSelected } = userReducer.actions;

export const selectSite = (state) => state.user;

export default userReducer.reducer;
