import React, { useEffect, useState } from "react";
import profileImage from "assets/profile.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  Groups2Outlined,
  HomeOutlined,
} from "@mui/icons-material";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FlexBetween from "components/FlexBetween/FlexBetween";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import InventoryIcon from "@mui/icons-material/Inventory";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import logo from "../../assets/logo.png";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import CarpenterIcon from "@mui/icons-material/Carpenter";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import EvStationIcon from "@mui/icons-material/EvStation";
import GasMeterIcon from "@mui/icons-material/GasMeter";
const navItems = [
  {
    text: "menu.general",
    icon: null,
    url: "/general",
    lcText: "general",
  },
  {
    text: "menu.dashboard",
    icon: <HomeOutlined />,
    lcText: "dashboard",
    url: "dashboard",
  },
  {
    text: "menu.categories",
    icon: <CategoryIcon />,
    lcText: "categorie",
    url: "categorie",
  },
  {
    text: "menu.familles",
    icon: <Groups2Outlined />,
    url: "famille",
    lcText: "famille",
  },
  {
    text: "menu.articles",
    icon: <LocalGroceryStoreOutlinedIcon />,
    url: "articles",
    lcText: "articles",
  },
  {
    text: "menu.depot",
    icon: <StorefrontIcon />,
    url: "depot",
    lcText: "depot",
  },
  {
    text: "menu.fournisseurs",
    icon: <AddBusinessIcon />,
    url: "fournisseur",
    lcText: "fournisseur",
  },
  {
    text: "menu.gestionBonFournisseur",
    icon: null,
  },
  {
    text: "menu.boncommande",
    icon: <DriveFileMoveIcon />,
    url: "bon-commande-fournisseur",
    lcText: "bon-commande-fournisseur",
  },
  {
    text: "menu.bonentre",
    icon: <AssignmentReturnedIcon />,
    url: "bon-entre",
    lcText: "bon-entre",
  },
  {
    text: "menu.bonavoir",
    icon: <PendingActionsIcon />,
    url: "bon-avoir",
    lcText: "bon-avoir",
  },
  {
    text: "menu.bonretour",
    icon: <AssignmentReturnIcon />,
    url: "bon-retour",
    lcText: "bon-retour",
  },
  {
    text: "menu.gestionboninterne",
    icon: null,
  },
  {
    text: "menu.boncommande",
    icon: <CreateNewFolderIcon />,
    url: "bon-commande-interne",
    lcText: "bon-commande-interne",
  },
  {
    text: "menu.bonsortie",
    icon: <DriveFileMoveIcon />,
    url: "bon-sortie",
    lcText: "bon-sortie",
  },
  {
    text: "menu.bonretour",
    icon: <AssignmentReturnIcon />,
    url: "bon-retour-interne",
    lcText: "bon-retour-interne",
  },
  {
    text: "menu.management",
    icon: null,
    url: "management",
  },
  {
    text: "menu.users",
    icon: <PersonAddAltIcon />,
    url: "utilisateur",
    lcText: "utilisateur",
  },
  {
    text: "menu.site",
    icon: <AccountTreeIcon />,
    url: "site",
    lcText: "site",
  },
  {
    text: "menu.inventaire",
    icon: <InventoryIcon />,
    url: "inventaire",
    lcText: "inventaire",
  },
  {
    text: "menu.gestionquota",
    icon: <DonutSmallIcon />,
    url: "quota",
    lcText: "quota",
  },
  {
    text: "menu.park",
    icon: null,
  },
  {
    text: "menu.confined",
    icon: <LockIcon />,
    url: "confine",
    lcText: "confine",
  },
  {
    text: "menu.receipt",
    icon: <LockOpenIcon />,
    url: "recu",
    lcText: "recu",
  },
  {
    text: "menu.product",
    icon: <ProductionQuantityLimitsIcon />,
    url: "produit",
    lcText: "produit",
  },
  {
    text: "menu.side",
    icon: <LocalPoliceIcon />,
    url: "police",
    lcText: "police",
  },
  {
    text: "menu.agentConfined",
    icon: <EngineeringIcon />,
    url: "agentConfined",
    lcText: "agentConfined",
  },
  {
    text: "menu.carburant",
    icon: null,
  },
  {
    text: "Consommable",
    icon: <LocalGasStationIcon />,
    url: "consommable",
    lcText: "consommable",
  },
  {
    text: "Agents",
    icon: <SupportAgentIcon />,
    url: "agents",
    lcText: "agents",
  },
  {
    text: "Engins",
    icon: <TimeToLeaveIcon />,
    url: "engins",
    lcText: "engins",
  },
  {
    text: "Petit Matériaux",
    icon: <CarpenterIcon />,
    url: "petitMateriaux",
    lcText: "petitMateriaux",
  },
  {
    text: "Consomation Engin",
    icon: <EvStationIcon />,
    url: "consommationEngin",
    lcText: "consommationEngin",
  },
  {
    text: "Consomation Materiel",
    icon: <GasMeterIcon />,
    url: "consommationMateriels",
    lcText: "consommationMateriels",
  },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  let lang = window.localStorage.getItem("language");
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  theme.direction = useSelector((state) => state.global.direction);
  document.body.dir = theme.direction;

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);
  return (
    <Box component={"nav"}>
      {isSidebarOpen && (
        <Drawer
          variant="persistent"
          anchor={theme.direction === "ltr" ? "left" : "right"}
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              background: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
        >
          <Box width={"100%"}>
            <Box m="1.5rem 2rem 0rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display={"flex"} pl={3}>
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                      width: 100,
                    }}
                  />
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ lcText, text, icon, url }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "1.5rem 0 1rem 1rem" }}>
                      {t(text)}
                    </Typography>
                  );
                }

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${url}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "1rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={t(text)}>
                        {active === lcText && (
                          <ChevronRightOutlined
                            sx={{
                              ml: "auto",
                            }}
                          />
                        )}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
