import { createSlice } from "@reduxjs/toolkit";
import {
  handleSelectAction,
  handleClearSelectedAction,
  getPetitMateriel,
  addPetitMateriel,
  deletePetitMateriel,
  updatePetitMateriel,
} from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const petitMaterielReducer = createSlice({
  name: "petitMateriel",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPetitMateriel.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getPetitMateriel.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getPetitMateriel.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addPetitMateriel.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addPetitMateriel.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addPetitMateriel.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(deletePetitMateriel.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(deletePetitMateriel.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.filteredData = action.payload.newFilteredData;
        state.loadingAll = "succeeded";
      })
      .addCase(deletePetitMateriel.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updatePetitMateriel.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updatePetitMateriel.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updatePetitMateriel.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

//Action creators are generated for each case reducer function
export const { handleSelect, handleClearSelected } =
  petitMaterielReducer.actions;

export const selectPetitMateriel = (state) => state.petitMateriel;

export default petitMaterielReducer.reducer;
