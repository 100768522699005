import { Text, View } from "@react-pdf/renderer";
import React from "react";

const Address = ({ styles }) => {
  return (
    <View style={styles.titleContainer}>
      <View>
        <Text style={styles.text}>وصل في محجوز</Text>
      </View>
    </View>
  );
};

export default Address;
