import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import CustomDataGrid from "components/DataGrid/DataGrid";
import Header from "components/Header/Header";
import useInventaire from "hooks/useInventaire";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/Button/CustomButton";
import CustomModal from "components/Modal/CustomModal";
import CustomSelect from "components/Input/CustomSelect";
import CustomInput from "components/Input/CustomInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DeleteForeverSharp } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import dayjs from "dayjs";
import {
  addInventaire,
  gereInventaire,
  updateInventaire,
} from "store/inventaire/actions";

const Inventaire = () => {
  const {
    data,
    loading,
    loadingAll,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    gereOpen,
    detailOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleGereOpen,
    handleGereClose,
    handleOpenGereModal,
    handleDetailClose,
    handleDetailOpenModal,
    handleDetailOpen,
  } = useInventaire();

  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);
  const depotData = useSelector((state) => state.depotReducer.data);
  const articleData = useSelector((state) => state.articleReducer.data);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cart, setCart] = useState([]);
  const [depot, setDepot] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [jsonList, setJsonList] = useState([]);
  const [justification, setJustification] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateOpen && selected.list_article_inventaire !== undefined) {
      try {
        const parsedCart = JSON.parse(selected.list_article_inventaire);
        setCart(parsedCart);
      } catch (error) {
        console.error("Error parsing list_article:", error);
        setCart([]);
      }
    }
  }, [updateOpen, selected.list_article_inventaire]);
  useEffect(() => {
    if (gereOpen && selected.list_article_inventaire !== undefined) {
      try {
        const parsedCart = JSON.parse(selected.list_article_inventaire);
        setJsonList(parsedCart);
      } catch (error) {
        console.error("Error parsing list_article:", error);
        setJsonList([]);
      }
    }
  }, [gereOpen, selected.list_article_inventaire]);

  const transformedArticleData = articleData.map((product) => ({
    code_article: product.code_article,
    quantity: product.stock_actuel,
    label: product.designation,
  }));
  const transformedDepotData = depotData.map((depot) => ({
    code_depot: depot.code_depot,
    label: depot.designation,
  }));
  const defaultDepotData = transformedDepotData.find(
    (option) => option.code_depot == selected.code_depot
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      code_depot: depot.code_depot,
      date_deb: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
      date_fin: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
      list_article_inventaire: cart,
      etat: 1,
    };
    dispatch(addInventaire(postData));
    handleClose();
  };
  const addToCart = () => {
    if (selectedProduct) {
      const { code_article, label: designation, quantity } = selectedProduct;
      const newItem = {
        code_article,
        designation,
        quantity,
      };
      setCart([...cart, newItem]);
      setSelectedProduct(null);
    }
  };
  const handleProductChange = (event, selectedOption) => {
    setSelectedProduct(selectedOption);
  };
  const handleDepotChange = (event, selectedOption) => {
    setDepot(selectedOption);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleRemoveItem = (item) => {
    setCart(
      cart.filter((cartItem) => cartItem.code_article !== item.code_article)
    );
  };
  const handleQuantityChange = (index, value) => {
    // Create a copy of the current jsonList
    const updatedList = [...jsonList];

    // Update the specific item in the copied array
    updatedList[index] = {
      ...updatedList[index],
      quantityInput: value,
    };

    // Update the state with the modified array
    setCart(updatedList);
  };

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };
  const handleJustificationChange = handleChange(setJustification);
  const handleGereSubmit = (e, inventaireId) => {
    e.preventDefault();

    const selectedInventaire = data.find(
      (inventaire) => inventaire.code_inventaire === inventaireId
    );

    const updatedData = {
      list_article_inventaire:
        cart !== "" ? cart : selectedInventaire.list_article_inventaire,
      justification:
        justification !== "" ? justification : selectedInventaire.justification,
      etat: 1,
    };

    dispatch(gereInventaire({ inventaireId, updatedData }));
    handleGereClose();
  };
  const handleUpdate = (e, inventaireId) => {
    e.preventDefault();

    const selectedInventaire = data.find(
      (inventaire) => inventaire.code_inventaire === inventaireId
    );

    const updatedData = {
      code_depot:
        depot !== 0 ? depot.code_depot : selectedInventaire.code_depot,
      date_deb:
        startDate !== null
          ? dayjs(startDate).format("YYYY-MM-DD")
          : dayjs(selected.date_deb).format("YYYY-MM-DD"),
      date_fin:
        endDate !== null
          ? dayjs(endDate).format("YYYY-MM-DD")
          : dayjs(selected.date_fin).format("YYYY-MM-DD"),
      list_article_inventaire:
        cart !== "" ? cart : selectedInventaire.list_article_inventaire,
      etat: 1,
    };

    dispatch(updateInventaire({ inventaireId, updatedData }));
    handleUpdateClose();
  };
  const columns = [
    {
      field: "id",
      headerName: "code inventaire",
      flex: 0.5,
    },
    {
      field: "depot",
      headerName: "depot",
      flex: 0.5,
    },
    {
      field: "date_debut",
      headerName: "date debut",
      flex: 0.5,
    },
    {
      field: "date_fin",
      headerName: "date fin",
      flex: 0.5,
    },
    {
      field: "detail",
      headerName: t("bcf.details"),
      flex: 0.3,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <InfoOutlinedIcon /> : null}
          endIcon={language === "fr" ? <InfoOutlinedIcon /> : null}
          variant="contained"
          color={"success"}
          onClick={() => handleDetailOpenModal(params.id)}
        >
          {t("bcf.details")}
        </Button>
      ),
    },
    {
      field: "modifier",
      headerName: t("frs.modifier"),
      flex: 0.3,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("frs.modifier")}
        </Button>
      ),
    },
    {
      field: "gere",
      headerName: "gere inventaire",
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <EditOutlinedIcon /> : null}
          endIcon={language === "fr" ? <EditOutlinedIcon /> : null}
          variant="contained"
          color={"primary"}
          onClick={() => handleOpenGereModal(params.id)}
        >
          {"gere inventaire"}
        </Button>
      ),
    },
  ];
  const columnsListArticle = [
    {
      field: "designation",
      headerName: t("bcf.designation"),
      flex: 1,
    },
    {
      field: "quantity",
      headerName: t("bcf.quantite"),
      flex: 1,
    },
    {
      field: "quantityInput",
      headerName: "quantity trouvée",
      flex: 1,
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data?.map((item) => {
      const depot = depotData.find((dep) => dep.code_depot === item.code_depot);
      const depotDesignation = depot ? depot.designation : "";
      const date = new Date(item.date_deb);
      const formattedDateDeb = date.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const dateFin = new Date(item.date_fin);
      const formattedDateFin = dateFin.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return {
        id: item.code_inventaire,
        depot: depotDesignation,
        date_debut: formattedDateDeb,
        date_fin: formattedDateFin,
      };
    });
  }, [data, depotData]);
  const DetailListArticle = useMemo(() => {
    // Extracting properties from the selected object
    const { list_article_inventaire } = selected;
    const dataArray = JSON.parse(list_article_inventaire || "[]");
    return dataArray.map((item) => {
      return {
        id: item.code_article,
        designation: item.designation,
        quantity: item.quantity,
        quantityInput: item.quantityInput,
      };
    });
  }, [selected]);
  const createInventaireButton = (
    <CustomButton
      children={"create an Inventaire"}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header title={"liste des inventaires"} button={createInventaireButton} />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.code_inventaire}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {"create an inventaire"}
              </Typography>
              <CustomSelect
                defaultValue={defaultDepotData}
                onChange={handleDepotChange}
                label={"depots"}
                options={transformedDepotData}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="date Debut"
                  value={dayjs(selected.date_deb)}
                  onChange={handleStartDateChange}
                  sx={{ width: "100%" }}
                />
                <DatePicker
                  label="date Fin"
                  value={dayjs(selected.date_fin)}
                  onChange={handleEndDateChange}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" mb={"1rem"}>
                    {t("bcf.prs")}
                  </Typography>
                  <CustomSelect
                    onChange={handleProductChange}
                    label={t("bcf.listart")}
                    options={transformedArticleData}
                    value={
                      selectedProduct ? selectedProduct.code_article : null
                    }
                  />

                  {selectedProduct && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addToCart}
                      disabled={!selectedProduct}
                    >
                      {t("bcf.add")}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" m={"1rem 0"}>
                    {t("bcf.listart")}
                  </Typography>
                  {cart.length === 0 ? (
                    <p>{t("bcf.listvide")}</p>
                  ) : (
                    <List>
                      {cart.map((item) => (
                        <ListItem
                          key={item.code_article}
                          secondaryAction={
                            <IconButton
                              onClick={() => handleRemoveItem(item)}
                              edge="end"
                            >
                              <DeleteForeverSharp />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={item.designation} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.code_inventaire)}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={"submit"}
              />
            </form>
          }
        />
      )}
      {gereOpen && (
        <CustomModal
          key={selected.code_inventaire}
          onClose={handleGereClose}
          open={gereOpen}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {"gere an inventaire"}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: 15,
                }}
              >
                <Typography variant="body1" style={{ minWidth: 150 }}>
                  designation
                </Typography>
                <Typography variant="body1" style={{ minWidth: 50 }}>
                  quantite
                </Typography>
                <Typography variant="body1" style={{ minWidth: 50 }}>
                  quantite trouvée
                </Typography>
              </div>
              {jsonList.map((article, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: 15,
                  }}
                >
                  <Typography variant="body1" style={{ minWidth: 150 }}>
                    {article.designation}
                  </Typography>
                  <Typography variant="body1" style={{ minWidth: 50 }}>
                    {article.quantity}
                  </Typography>
                  <TextField
                    label="quantite trouvée"
                    type="number"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      handleQuantityChange(index, e.target.value)
                    }
                  />
                </div>
              ))}

              <Typography variant="body1" style={{ width: "100%" }}>
                justification
              </Typography>
              <TextField
                label="justification"
                variant="outlined"
                fullWidth
                onChange={(e) => handleJustificationChange(e.target.value)}
              />
              <CustomButton
                onClick={(e) => handleGereSubmit(e, selected.code_inventaire)}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={"submit"}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {"create an inventaire"}
              </Typography>
              <CustomSelect
                onChange={handleDepotChange}
                label={"depots"}
                options={transformedDepotData}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="date Debut"
                  value={startDate}
                  onChange={handleStartDateChange}
                  sx={{ width: "100%" }}
                />
                <DatePicker
                  label="date Fin"
                  value={endDate}
                  onChange={handleEndDateChange}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" mb={"1rem"}>
                    {t("bcf.prs")}
                  </Typography>
                  <CustomSelect
                    onChange={handleProductChange}
                    label={t("bcf.listart")}
                    options={transformedArticleData}
                    value={
                      selectedProduct ? selectedProduct.code_article : null
                    }
                  />

                  {selectedProduct && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={addToCart}
                      disabled={!selectedProduct}
                    >
                      {t("bcf.add")}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" m={"1rem 0"}>
                    {t("bcf.listart")}
                  </Typography>
                  {cart.length === 0 ? (
                    <p>{t("bcf.listvide")}</p>
                  ) : (
                    <List>
                      {cart.map((item) => (
                        <ListItem
                          key={item.code_article}
                          secondaryAction={
                            <IconButton
                              onClick={() => handleRemoveItem(item)}
                              edge="end"
                            >
                              <DeleteForeverSharp />
                            </IconButton>
                          }
                        >
                          <ListItemText primary={item.designation} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={"submit"}
              />
            </form>
          }
        />
      )}
      {detailOpen && (
        <CustomModal
          onClose={handleDetailClose}
          open={detailOpen}
          children={
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "75vh",
                overflowY: "auto",
                gap: 10,
              }}
            >
              <Typography variant="h3" mt={"1rem"}>
                {t("bcf.listart")} :
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailListArticle}
                columns={columnsListArticle}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
              />
            </Box>
          }
        />
      )}
    </Box>
  );
};

export default Inventaire;
