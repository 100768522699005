import useBonAvoir from "hooks/useBonAvoir";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import CustomDataGrid from "components/DataGrid/DataGrid";
import Header from "components/Header/Header";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/Button/CustomButton";
import CustomModal from "components/Modal/CustomModal";
import CustomSelect from "components/Input/CustomSelect";
import { DataGrid } from "@mui/x-data-grid";
import PrintButton from "components/PDF/PrintButton";
import DownloadButton from "components/PDF/DownloadButton";
import { addBonAvoir, updateBonAvoir } from "store/BonAvoir/actions";
import { addBonCommandeInterne } from "store/BonCommandeInterne/actions";
import { useTranslation } from "react-i18next";
const BonAvoir = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    detailOpen,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleDetailClose,
    handleDetailOpenModal,
    handleDetailOpen,
    handleSearchChange,
    articles,
    setArticles,
  } = useBonAvoir();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [fournisseur, setFournisseur] = useState(0);
  const [code, setCode] = useState("");
  const [selectedBCF, setselectedBCF] = useState(null);
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);

  const fournisseurData = useSelector((state) => state.fournisseurReducer.data);
  const articleData = useSelector((state) => state.articleReducer.data);
  const BCFData = useSelector(
    (state) => state.bonCommandeFournisseurReducer.data
  );
  const BonEntre = useSelector((state) => state.bonEntreReducer.data);
  const userData = useSelector((state) => state.userReducer.data);

  useEffect(() => {
    if (selectedBCF && BonEntre) {
      const filteredArticles = selectedBCF.list_article
        .map((article) => {
          const correspondingBonEntreArticle = BonEntre.find(
            (bonArticle) =>
              bonArticle.code_bon_commande_fournisseur ===
              selectedBCF.code_bon_commande_fournisseur
          );

          if (correspondingBonEntreArticle) {
            const parsedListArticleLivree = JSON.parse(
              correspondingBonEntreArticle.list_article_livree
            );

            // Check if any article in parsedListArticleLivree matches the quantity
            const matchingArticle = parsedListArticleLivree.find(
              (livreeArticle) =>
                livreeArticle.code_article === article.code_article
            );
            if (matchingArticle) {
              // Check if quantities are equal
              if (matchingArticle.quantityInput === article.quantity) {
                return [];
              } else {
                return {
                  id: 1,
                  quantity: article.quantity - matchingArticle.quantity,
                  ...article,
                  quantityInput: 0,
                };
              }
            }
          }
          return {
            id: 1, // Add id property
            ...article,
            quantityInput: 0,
          };
        })
        .filter((article) => article !== null);
      const filteredList = filteredArticles.map((item) => ({
        id: item.code_article,
        ...item,
      }));
      if (
        filteredList.length > 0 &&
        filteredList.some((item) => item.code_article)
      ) {
        setArticles(filteredList);
      } else {
        setArticles([]);
      }
    }
  }, [selectedBCF, BonEntre]);
  useEffect(() => {
    if (updateOpen && selected.list_article_manque !== undefined) {
      try {
        const parsedCart = JSON.parse(selected.list_article_manque);
        setArticles(parsedCart);
      } catch (error) {
        console.error("Error parsing list_article:", error);
        setArticles([]);
      }
    }
  }, [updateOpen, selected.list_article_manque]);
  const handleQuantityChange = (id, value) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.code_article === id
          ? { ...article, quantityInput: value }
          : article
      )
    );
  };

  const transformedBonCommandeFournisseurData = BCFData.map((BCF) => ({
    code_bon_commande_fournisseur: BCF.code_bon_commande_fournisseur,
    code_fournisseur: BCF.code_fournisseur,
    list_article: JSON.parse(BCF.list_article),
    label: BCF.code_bon_commande_fournisseur,
  }));

  const defaultBCFOption = transformedBonCommandeFournisseurData.find(
    (option) =>
      option.code_bon_commande_fournisseur === selected.code_bon_commande
  );

  const handleBCFChange = (event, selectedOption) => {
    setselectedBCF(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      code_bon_commande: selectedBCF.code_bon_commande_fournisseur,
      list_article_manque: articles,
      etat: 1,
    };
    dispatch(addBonAvoir(postData));
    handleClose();
  };
  const handleUpdate = (e, bonAvoirId) => {
    e.preventDefault();

    const selectedBCF = data.find((BA) => BA.code_bon_avoir === bonAvoirId);

    const updatedData = {
      code_bon_commande: code !== "" ? code : selectedBCF.code_bon_commande,
      list_article_manque:
        articles !== "" ? articles : selected.list_article_manque,
      etat: 1,
    };
    dispatch(updateBonAvoir({ bonAvoirId, updatedData }));
    handleUpdateClose();
  };

  const columns = [
    {
      field: "id",
      headerName: "code bon avoir",
      flex: 1,
    },
    {
      field: "BCF",
      headerName: "code bon commande fournisseur",
      flex: 1,
    },
    {
      field: "detail",
      headerName: "detail",
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <InfoOutlinedIcon /> : null}
          endIcon={language === "fr" ? <InfoOutlinedIcon /> : null}
          variant="contained"
          color={"success"}
          onClick={() => handleDetailOpenModal(params.id)}
        >
          {t("brf.details")}
        </Button>
      ),
    },
    {
      field: "modifier",
      headerName: "Update",
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "PDF",
      headerName: "Telecharger",
      flex: 0.6,
      renderCell: (params) => (
        <DownloadButton
          row={params.row}
          invoiceTitle={
            "REPUBLIQUE TUNISIENNE MINISTERE DE L'INTERIEUR COMMUNE DE JERBA MIDOUN"
          }
          bonTitle={"Bon Avoir Fournisseur"}
          addressTitle={"Fournisseur: " + params.row.fournisseur}
          quantite={"quantité in bon commande"}
          quantiteInput={"quantité manque"}
        />
      ),
    },
    {
      field: "Print",
      headerName: "Imprimer",
      flex: 0.5,
      renderCell: (params) => (
        <PrintButton
          row={params.row}
          invoiceTitle={
            "REPUBLIQUE TUNISIENNE MINISTERE DE L'INTERIEUR COMMUNE DE JERBA MIDOUN"
          }
          bonTitle={"Bon Avoir Fournisseur"}
          addressTitle={"Fournisseur: " + params.row.fournisseur}
          quantite={"quantité in bon commande"}
          quantiteInput={"quantité manque"}
        />
      ),
    },
  ];
  const columnsDetail = [
    {
      field: "UserC",
      headerName: "user create",
      flex: 1,
    },
    {
      field: "DateC",
      headerName: "Date create",
      flex: 1,
    },
  ];
  const columnsListArticle = [
    {
      field: "designation",
      headerName: "designation",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "quantité",
      flex: 1,
    },
  ];

  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const bonCommande = BCFData.find(
        (bcf) => bcf.code_bon_commande_fournisseur === item.code_bon_commande
      );
      const fournisseur = fournisseurData.find(
        (four) => four?.code_fournisseur === bonCommande?.code_fournisseur
      );

      return {
        id: item.code_bon_avoir,
        BCF: item.code_bon_commande,
        fournisseur: fournisseur?.raison_sociale,
        etat: item.etat,
        list_article: JSON.parse(item.list_article_manque).map((item) => {
          return {
            id: item.code_article,
            designation: item.designation,
            quantity: item.quantity,
            quantityInput: item.quantityInput,
          };
        }),
      };
    });
  }, [data, BCFData, fournisseurData]);
  const DetailData = useMemo(() => {
    // Extracting properties from the selected object
    const { code_user_create, date_create } = selected;
    const dateCreate = new Date(date_create);
    const userCreate = userData.find((user) => {
      return code_user_create == user.id;
    });

    const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return [
      {
        id: selected.code_bon_avoir,
        UserC: userCreate?.nom + " " + userCreate?.prenom,
        DateC: formattedDateCreate,
      },
    ];
  }, [selected]);
  const DetailListArticle = useMemo(() => {
    // Extracting properties from the selected object
    const { list_article_manque } = selected;
    const dataArray = JSON.parse(list_article_manque || "[]");
    return dataArray.map((item) => {
      return {
        id: item.code_article,
        designation: item.designation,
        quantity: item.quantityInput,
      };
    });
  }, [selected]);

  const createBAButton = (
    <CustomButton
      children={"crée un bon Avoir fournisseur"}
      startIcon={<AddIcon />}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste Bon Avoir Fournisseur"}
        search={handleSearchChange}
        button={createBAButton}
      />
      <CustomDataGrid
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {detailOpen && (
        <CustomModal
          onClose={handleDetailClose}
          open={detailOpen}
          children={
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "75vh",
                overflowY: "auto",
                gap: 10,
              }}
            >
              <Typography variant="h3" mt={"1rem"}>
                Details de Bons avoir fournisseur:
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailData}
                columns={columnsDetail}
                sx={{ "& .MuiDataGrid-footerContainer": { display: "none" } }}
              />
              <Typography variant="h3" mt={"1rem"}>
                Liste des articles:
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailListArticle}
                columns={columnsListArticle}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
              />
            </Box>
          }
        />
      )}
      {updateOpen && (
        <CustomModal
          key={selected.code_bon_avoir}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                modifier un bon avoir
              </Typography>
              <CustomSelect
                defaultValue={defaultBCFOption}
                onChange={handleBCFChange}
                label={"bon commande fournisseur"}
                options={transformedBonCommandeFournisseurData}
              />

              {selectedBCF && (
                <Box sx={{ width: "100%", mt: 2 }}>
                  <Typography variant="h6" mb={2}>
                    Articles in Bon Avoir Fournisseur
                  </Typography>
                  {articles.length > 0 ? (
                    <Box sx={{ width: "100%" }}>
                      <DataGrid
                        rows={articles}
                        columns={[
                          {
                            field: "designation",
                            headerName: "Designation",
                            flex: 1,
                          },
                          {
                            field: "quantityInput",
                            headerName: "Quantity Input",
                            flex: 1,
                            renderCell: (params) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={params.row.quantityInput}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      params.row.code_article,
                                      e.target.value
                                    )
                                  }
                                />
                              </Box>
                            ),
                          },
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1">
                      No articles available.
                    </Typography>
                  )}
                </Box>
              )}

              <CustomButton
                onClick={(e) => handleUpdate(e, selected.code_bon_avoir)}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={"submit"}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                crée un bon Avoir fournisseur
              </Typography>
              <CustomSelect
                onChange={handleBCFChange}
                label={"bon commande fournisseur"}
                options={transformedBonCommandeFournisseurData}
              />
              {selectedBCF && (
                <Box sx={{ width: "100%", mt: 2 }}>
                  <Typography variant="h6" mb={2}>
                    Articles in Bon Avoir Fournisseur
                  </Typography>
                  {articles.length > 0 ? (
                    <Box sx={{ width: "100%" }}>
                      <DataGrid
                        rows={articles}
                        columns={[
                          {
                            field: "designation",
                            headerName: "Designation",
                            flex: 1,
                          },
                          {
                            field: "quantityInput",
                            headerName: "Quantity Input",
                            flex: 1,
                            renderCell: (params) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={params.row.quantityInput}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      params.row.code_article,
                                      e.target.value
                                    )
                                  }
                                />
                              </Box>
                            ),
                          },
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1">
                      No articles available.
                    </Typography>
                  )}
                </Box>
              )}

              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={"submit"}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default BonAvoir;
