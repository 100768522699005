import { BlobProvider } from "@react-pdf/renderer";
import React from "react";
import CustomPDF from "./CustomPDF";
import Link from "@mui/material/Link";
import PrintIcon from "@mui/icons-material/Print";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const PrintButton = ({
  row,
  invoiceTitle,
  bonTitle,
  addressTitle,
  depot,
  date,
  quantiteInput,
  quantite,
}) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);

  return (
    <BlobProvider
      document={
        <CustomPDF
          data={row || {}}
          date={date}
          quantite={quantite}
          quantiteInput={quantiteInput}
          invoiceTitle={invoiceTitle}
          bonTitle={bonTitle}
          addressTitle={addressTitle}
          depot={depot}
        />
      }
    >
      {({ url, blob }) => (
        <Link href={url} target="_blank">
          <Button
            color={"primary"}
            startIcon={language === "ar" ? <PrintIcon /> : null}
            endIcon={language === "fr" ? <PrintIcon /> : null}
            variant="contained"
          >
            <span>{t("bef.imprimer")}</span>
          </Button>
        </Link>
      )}
    </BlobProvider>
  );
};

export default PrintButton;
