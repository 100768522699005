import { createSlice } from "@reduxjs/toolkit";
import {
  handleSelectAction,
  handleClearSelectedAction,
  getBonAvoir,
  addBonAvoir,
  updateBonAvoir,
} from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const bonAvoirReducer = createSlice({
  name: "bonAvoir",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBonAvoir.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getBonAvoir.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getBonAvoir.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addBonAvoir.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addBonAvoir.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addBonAvoir.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updateBonAvoir.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updateBonAvoir.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updateBonAvoir.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

export const { handleSelect, handleClearSelected } = bonAvoirReducer.actions;

export const selectBonAvoir = (state) => state.bonAvoir;

export default bonAvoirReducer.reducer;
