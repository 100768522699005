import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getFournisseurs = createAsyncThunk(
  "GET-FOURNISSEUR",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/fournisseur/allFournisseurs",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addFournisseur = createAsyncThunk(
  "ADD-FOURNISSEUR",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const fournisseurState = getState().fournisseurReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/fournisseur/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return [...fournisseurState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteFournisseur = createAsyncThunk(
  "DELETE-FOURNISSEUR",
  async (params, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().fournisseurReducer.data;
      const prevFilteredData = getState().fournisseurReducer.filteredData;
      let newData = [];
      let newFilteredData = [];

      await axios.delete(`https://www.gbgc.tn/fournisseur/delete/${params}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      newData = [
        ...prevData.filter((item) => item.code_fournisseur !== params),
      ];
      newFilteredData = [
        ...prevFilteredData.filter((item) => item.code_fournisseur !== params),
      ];

      return { newData, newFilteredData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateFournisseur = createAsyncThunk(
  "UPDATE-FOURNISSEUR",
  async ({ fournisseurId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().fournisseurReducer.data;

      // Assuming your backend API supports updating a category by its ID
      const response = await axios.put(
        `https://www.gbgc.tn/fournisseur/update/${fournisseurId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      // Assuming your backend returns the updated category data
      const updatedFournisseur = response.data;

      // Replace the old category data with the updated one
      const newData = prevData.map((fournisseur) =>
        fournisseur.code_fournisseur === fournisseurId
          ? updatedFournisseur
          : fournisseur
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_fournisseur === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
