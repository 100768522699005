import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getFamilles = createAsyncThunk(
  "GET-FAMILLE",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/famille/allFamilles",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addFamille = createAsyncThunk(
  "ADD-FAMILLE",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const familleState = getState().familleReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/famille/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return [{ ...response.data }, ...familleState];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteFamille = createAsyncThunk(
  "DELETE-FAMILLE",
  async (params, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().familleReducer.data;
      const prevFilteredData = getState().familleReducer.filteredData;
      let newData = [];
      let newFilteredData = [];

      await axios.delete(
        `https://www.gbgc.tn/famille/deleteFamille/${params}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      newData = [...prevData.filter((item) => item.code_famille !== params)];
      newFilteredData = [
        ...prevFilteredData.filter((item) => item.code_famille !== params),
      ];

      return { newData, newFilteredData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateFamille = createAsyncThunk(
  "UPDATE-FAMILLE",
  async ({ familleId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().familleReducer.data;

      // Assuming your backend API supports updating a category by its ID
      const response = await axios.put(
        `https://www.gbgc.tn/famille/updateFamille/${familleId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      // Assuming your backend returns the updated category data
      const updatedFamille = response.data;

      // Replace the old category data with the updated one
      const newData = prevData.map((famille) =>
        famille.code_famille === familleId ? updatedFamille : famille
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_famille === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
