import { Box, Button, Typography } from "@mui/material";
import CustomDataGrid from "components/DataGrid/DataGrid";
import Header from "components/Header/Header";
import useQuota from "hooks/useQuota";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/Button/CustomButton";
import CustomModal from "components/Modal/CustomModal";
import CustomSelect from "components/Input/CustomSelect";
import CustomInput from "components/Input/CustomInput";
import { addQuota, deleteQuota, updateQuota } from "store/quota/actions";
const Quota = () => {
  const {
    data,
    loading,
    loadingAll,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
  } = useQuota();
  const [quota, setQuota] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [site, setSite] = useState(null);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);
  const articleData = useSelector((state) => state.articleReducer.data);
  const siteData = useSelector((state) => state.siteReducer.data);
  const transformedArticleData = articleData.map((product) => ({
    code_article: product.code_article,
    label: product.designation,
  }));
  const transformedSiteData = siteData.map((site) => ({
    code_site: site.code_site,
    label: site.designation,
  }));
  const defaultArticleOption = transformedArticleData.find(
    (option) => option.code_article == selected.code_article
  );
  const defaultSiteOption = transformedSiteData.find(
    (option) => option.code_site == selected.code_site
  );

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };
  const handleQuotaChange = handleChange(setQuota);
  const handleQuantityChange = handleChange(setQuantity);
  const handleArticleChange = (event, selectedOption) => {
    setSelectedArticle(selectedOption);
  };
  const handleSiteChange = (event, selectedOption) => {
    setSite(selectedOption);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      code_article: selectedArticle.code_article,
      code_site: site.code_site,
      quota: parseInt(quota),
      quantite: parseInt(quantity),
      etat: 1,
    };
    dispatch(addQuota(postData));
    handleClose();
  };
  const handleUpdate = (e, id) => {
    e.preventDefault();

    const selectedQuota = data.find((quota) => quota.id === id);

    const updatedData = {
      code_article:
        selectedArticle !== null
          ? selectedArticle.code_article
          : selectedQuota.code_article,
      code_site: site !== null ? site.code_site : selectedQuota.code_site,
      quota: quota !== 0 ? parseInt(quota) : selectedQuota.quota,
      quantite: quantity !== 0 ? parseInt(quantity) : selectedQuota.quantite,
      etat: 1,
    };

    dispatch(updateQuota({ id, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (id) => {
    dispatch(deleteQuota(id));
  };
  const columns = [
    {
      field: "article",
      headerName: "article",
      flex: 0.5,
    },
    {
      field: "quantite",
      headerName: "quantité",
      flex: 0.5,
    },
    {
      field: "quota",
      headerName: "quota",
      flex: 0.5,
    },
    {
      field: "site",
      headerName: "site",
      flex: 0.5,
    },
    {
      field: "modifier",
      headerName: t("frs.modifier"),
      flex: 0.8,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("frs.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("frs.supprimer"),
      flex: 0.8,
      renderCell: (params) => (
        <CustomButton
          children={t("cat.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data?.map((item) => {
      const article = articleData.find(
        (article) => article.code_article === item.code_article
      );
      const site = siteData.find((site) => site.code_site === item.code_site);
      return {
        id: item.id,
        article: article?.designation,
        quantite: item.quantite,
        quota: item.quota,
        site: site?.designation,
      };
    });
  }, [data, articleData, siteData]);
  const createQuotaButton = (
    <CustomButton
      children={"create quota"}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste des Quota des articles"}
        button={createQuotaButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.id}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {"modify a quota"}
              </Typography>
              <CustomSelect
                defaultValue={defaultArticleOption}
                onChange={handleArticleChange}
                label={"liste des articles"}
                options={transformedArticleData}
              />
              <CustomSelect
                defaultValue={defaultSiteOption}
                onChange={handleSiteChange}
                label={"liste des sites"}
                options={transformedSiteData}
              />
              <CustomInput
                value={selected.quota}
                label={"quota"}
                type={"number"}
                onChange={handleQuotaChange}
              />
              <CustomInput
                value={selected.quantite}
                type={"number"}
                label={"quantité actuel"}
                onChange={handleQuantityChange}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.id)}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={"submit"}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {"create a quota"}
              </Typography>
              <CustomSelect
                onChange={handleArticleChange}
                label={"liste des articles"}
                options={transformedArticleData}
              />
              <CustomSelect
                onChange={handleSiteChange}
                label={"liste des sites"}
                options={transformedSiteData}
              />
              <CustomInput
                label={"quota"}
                type={"number"}
                onChange={handleQuotaChange}
              />
              <CustomInput
                type={"number"}
                label={"quantité actuel"}
                onChange={handleQuantityChange}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={"submit"}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Quota;
