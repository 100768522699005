import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import logo from "../../assets/logo.png";

const InvoiceTitle = ({ styles, title }) => {
  return (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.reportTitle}>{title}</Text>
      </View>
    </View>
  );
};

export default InvoiceTitle;
