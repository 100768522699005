import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Layout from "components/Layout/Layout";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "Pages/Dashboard";
import Categories from "Pages/Categories";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useMemo, useState } from "react";
import { themeSettings } from "theme";
import Famille from "Pages/Famille";
import Depot from "Pages/Depot";
import Fournisseur from "Pages/Fournisseur";
import Articles from "Pages/Articles";
import { getCategories } from "store/categorie/actions";
import { getFamilles } from "store/famille/actions";
import { getDepots } from "store/depot/actions";
import { getFournisseurs } from "store/fournisseur/actions";
import { getArticles } from "store/article/actions";
import BonCommandeFournisseur from "Pages/BonCommandeFournisseur";
import BonEntre from "Pages/BonEntre";
import { getBonCommandeFournisseur } from "store/BonCommandeFournisseur/actions";
import { getBonEntre } from "store/BonEntre/actions";
import BonAvoir from "Pages/BonAvoir";
import { getSites } from "store/site/actions";
import { getUser } from "store/user/actions";
import Site from "Pages/site";
import User from "Pages/user";
import BonRetourFournisseur from "Pages/BonRetourFournisseur";
import { getBonCommandeInterne } from "store/BonCommandeInterne/actions";
import BonCommandeInterne from "Pages/BonCommandeInterne";
import BonSortie from "Pages/BonSortie";
import BonRetourInterne from "Pages/BonRetourInterne";
import Inventaire from "Pages/Inventaire";
import Quota from "Pages/Quota";
import { getBonSortie } from "store/BonSortie/actions";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import arTranslation from "./translations/ar.json"; // Import Arabic translations
import frTranslation from "./translations/fr.json";
import Login from "Pages/Auth";
import { jwtDecode } from "jwt-decode";
import { getConfined } from "store/confined/actions";
import { getAgentConfined } from "store/agentConfined/actions";
import { getReceipt } from "store/receipt/actions";
import { getSide } from "store/side/actions";
import { getProduit } from "store/produit/actions";
import Confined from "Pages/Confined";
import Receipt from "Pages/Receipt";
import AgentConfined from "Pages/AgentConfined";
import Side from "Pages/Side";
import Produit from "Pages/Produit";
import Agent from "Pages/agent";
import Consommable from "Pages/consommable";
import ConsommationEngin from "Pages/ConsomationEngin";
import PetitMateriaux from "Pages/petitMateriaux";
import Engin from "Pages/engin";
import ConsommationMateriels from "Pages/ConsomationMaterial";
import { getAgent } from "store/agent/actions";
import { getConsommable } from "store/consommable/actions";
import { getConsommationEngin } from "store/consommationEngin/actions";
import { getConsommationMateriel } from "store/consommationMateriel/actions";
import { getPetitMateriel } from "store/petitMateriaux/actions";
import { getEngin } from "store/engin/actions";

i18n.use(initReactI18next).init({
  resources: {
    ar: {
      translation: arTranslation,
    },
    fr: {
      translation: frTranslation,
    },
  },
  lng: window.localStorage.getItem("language") || "ar",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const token = window.localStorage.getItem("token");
  const role = window.localStorage.getItem("role");
  const [decodedToken, setDecodedToken] = useState("");
  useEffect(() => {
    if (token) {
      try {
        setDecodedToken(jwtDecode(token));
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [token]);
  const currentDate = new Date();
  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    localStorage.clear();
    window.location = "/";
  }
  const mode = useSelector((state) => state.global.mode);
  const direction = useSelector((state) => state.global.direction);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getFamilles());
    dispatch(getDepots());
    dispatch(getFournisseurs());
    dispatch(getArticles());
    dispatch(getBonCommandeFournisseur());
    dispatch(getBonCommandeInterne());
    dispatch(getBonEntre());
    dispatch(getBonSortie());
    dispatch(getSites());
    dispatch(getUser());
    dispatch(getConfined());
    dispatch(getAgentConfined());
    dispatch(getReceipt());
    dispatch(getSide());
    dispatch(getProduit());
    dispatch(getAgent());
    dispatch(getConsommable());
    dispatch(getConsommationEngin());
    dispatch(getConsommationMateriel());
    dispatch(getPetitMateriel());
    dispatch(getEngin());
  }, [dispatch]);
  const theme = useMemo(
    () => createTheme(themeSettings(mode, direction)),
    [mode, direction]
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          {token ? (
            <Fragment>
              {role === "SA" && (
                <Route element={<Layout />}>
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/categorie" element={<Categories />} />
                  <Route path="/famille" element={<Famille />} />
                  <Route path="/depot" element={<Depot />} />
                  <Route path="/articles" element={<Articles />} />
                  <Route path="/fournisseur" element={<Fournisseur />} />

                  <Route
                    path="/bon-commande-fournisseur"
                    element={<BonCommandeFournisseur />}
                  />
                  <Route path="/bon-entre" element={<BonEntre />} />
                  <Route path="/bon-avoir" element={<BonAvoir />} />
                  <Route
                    path="/bon-retour"
                    element={<BonRetourFournisseur />}
                  />

                  <Route
                    path="/bon-commande-interne"
                    element={<BonCommandeInterne />}
                  />
                  <Route path="/bon-sortie" element={<BonSortie />} />
                  <Route
                    path="/bon-retour-interne"
                    element={<BonRetourInterne />}
                  />
                  <Route path="/site" element={<Site />} />
                  <Route path="/utilisateur" element={<User />} />
                  <Route path="/inventaire" element={<Inventaire />} />
                  <Route path="/quota" element={<Quota />} />
                  <Route path="/confine" element={<Confined />} />
                  <Route path="/recu" element={<Receipt />} />
                  <Route path="/police" element={<Side />} />
                  <Route path="/produit" element={<Produit />} />
                  <Route path="/agentConfined" element={<AgentConfined />} />
                  <Route path="/consommable" element={<Consommable />} />
                  <Route path="/agents" element={<Agent />} />
                  <Route path="/Engins" element={<Engin />} />
                  <Route path="/petitMateriaux" element={<PetitMateriaux />} />
                  <Route
                    path="/consommationEngin"
                    element={<ConsommationEngin />}
                  />
                  <Route
                    path="/consommationMateriels"
                    element={<ConsommationMateriels />}
                  />
                </Route>
              )}
              {role === "DSI" && (
                <Route element={<Layout />}>
                  <Route path="/*" element={<Navigate to="/categorie" />} />
                  <Route path="/categorie" element={<Categories />} />
                  <Route path="/famille" element={<Famille />} />
                  <Route path="/articles" element={<Articles />} />

                  <Route
                    path="/bon-commande-fournisseur"
                    element={<BonCommandeFournisseur />}
                  />

                  <Route path="/site" element={<Site />} />
                  <Route path="/utilisateur" element={<User />} />
                </Route>
              )}
              {role === "RS" && (
                <Route element={<Layout />}>
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/categorie" element={<Categories />} />
                  <Route path="/famille" element={<Famille />} />
                  <Route path="/depot" element={<Depot />} />
                  <Route path="/articles" element={<Articles />} />
                  <Route path="/fournisseur" element={<Fournisseur />} />

                  <Route
                    path="/bon-commande-fournisseur"
                    element={<BonCommandeFournisseur />}
                  />
                  <Route path="/bon-entre" element={<BonEntre />} />
                  <Route path="/bon-avoir" element={<BonAvoir />} />
                  <Route
                    path="/bon-retour"
                    element={<BonRetourFournisseur />}
                  />

                  <Route
                    path="/bon-commande-interne"
                    element={<BonCommandeInterne />}
                  />
                  <Route path="/bon-sortie" element={<BonSortie />} />
                  <Route
                    path="/bon-retour-interne"
                    element={<BonRetourInterne />}
                  />
                  <Route path="/inventaire" element={<Inventaire />} />
                  <Route path="/quota" element={<Quota />} />
                </Route>
              )}
              {role === "GS" && (
                <Route element={<Layout />}>
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/categorie" element={<Categories />} />
                  <Route path="/famille" element={<Famille />} />
                  <Route path="/depot" element={<Depot />} />
                  <Route path="/articles" element={<Articles />} />
                  <Route path="/fournisseur" element={<Fournisseur />} />

                  <Route
                    path="/bon-commande-fournisseur"
                    element={<BonCommandeFournisseur />}
                  />
                  <Route path="/bon-entre" element={<BonEntre />} />
                  <Route path="/bon-avoir" element={<BonAvoir />} />
                  <Route
                    path="/bon-retour"
                    element={<BonRetourFournisseur />}
                  />

                  <Route
                    path="/bon-commande-interne"
                    element={<BonCommandeInterne />}
                  />
                  <Route path="/bon-sortie" element={<BonSortie />} />
                  <Route
                    path="/bon-retour-interne"
                    element={<BonRetourInterne />}
                  />
                </Route>
              )}
              {role === "AA" && (
                <Route element={<Layout />}>
                  <Route path="/*" element={<Navigate to="/categorie" />} />
                  <Route path="/categorie" element={<Categories />} />
                  <Route path="/famille" element={<Famille />} />
                  <Route path="/articles" element={<Articles />} />
                  <Route
                    path="/bon-commande-interne"
                    element={<BonCommandeInterne />}
                  />
                </Route>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Route path="/*" element={<Navigate to="/" />} />
              <Route path="/" element={<Login />} />
            </Fragment>
          )}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
