import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const StatBox = ({ title, value, increase, icon, description }) => {
  const theme = useTheme();
  return (
    <Box
      gridColumn={"span 2"}
      gridRow={"span 1"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      p="1.25rem 1rem"
      flex={"1 1 100%"}
      backgroundColor={theme.palette.background.alt}
      borderRadius={"0.55rem"}
    >
      <FlexBetween>
        <Typography variant="h4" sx={{ color: theme.palette.secondary[100], fontWeight: 'bold',textAlign: 'center'}}>
          {title}
        </Typography>
        {/* {icon} */}
      </FlexBetween>
      <Typography
        variant="h1"
        sx={{ color: theme.palette.secondary[200], textAlign: 'center'}}
        fontWeight={"600"}
      >
        {value}
      </Typography>
      <FlexBetween gap={"1rem"}>
        <Typography
          variant="h5"
          fontStyle={"italic"}
          sx={{ color: theme.palette.secondary.light }}
        >
          {increase}
        </Typography>
        <Typography>{description}</Typography>
      </FlexBetween>
    </Box>
  );
};

export default StatBox;
