import { createSlice } from "@reduxjs/toolkit";
import { handleSelectAction, handleClearSelectedAction, getBonSortie, addBonSortie, updateBonSortie } from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const bonSortieReducer = createSlice({
  name: "bonSortie",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBonSortie.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getBonSortie.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getBonSortie.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addBonSortie.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addBonSortie.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addBonSortie.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updateBonSortie.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updateBonSortie.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updateBonSortie.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

export const { handleSelect, handleClearSelected } = bonSortieReducer.actions;

export const selectBonSortie = (state) => state.bonSortie;

export default bonSortieReducer.reducer;
