import { Text, View } from "@react-pdf/renderer";
import React from "react";

const UserAddress = ({ styles, addressTitle, depot, date }) => {
  return (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View style={{ maxWidth: 200 }}>
          <Text style={[styles.text]}>{addressTitle}</Text>
          <Text style={styles.address}>{depot}</Text>
        </View>
        <Text style={styles.addressTitle}>{date}</Text>
      </View>
    </View>
  );
};

export default UserAddress;
