import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBonCommandeFournisseur = createAsyncThunk(
  "GET-BON-COMMANDE-FOURNISSEUR",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/bon_commande_fournisseur/allBC",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addBonCommandeFournisseur = createAsyncThunk(
  "ADD-BON-COMMANDE-FOURNISSEUR",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const bonCommandeFournisseurState =
        getState().bonCommandeFournisseurReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/bon_commande_fournisseur/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      return [...bonCommandeFournisseurState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBonCommandeFournisseur = createAsyncThunk(
  "UPDATE-BON-COMMANDE-FOURNISSEUR",
  async (
    { bonCommandeFournisseurId, updatedData },
    { rejectWithValue, getState }
  ) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().bonCommandeFournisseurReducer.data;

      const response = await axios.put(
        `https://www.gbgc.tn/bon_commande_fournisseur/update/${bonCommandeFournisseurId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedBonCommandeFournisseur = response.data;

      const newData = prevData.map((bonCommandeFournisseur) =>
        bonCommandeFournisseur.code_bon_commande_fournisseur ===
        bonCommandeFournisseurId
          ? updatedBonCommandeFournisseur
          : bonCommandeFournisseur
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_bon_commande_fournisseur === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
