import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBonRetourFournisseur = createAsyncThunk(
  "GET-BON-RETOUR-FOURNISSEUR",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/bonRetourFournisseur/all",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addBonRetourFournisseur = createAsyncThunk(
  "ADD-BON-RETOUR-FOURNISSEUR",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const bonRetourFournisseurState =
        getState().bonRetourFournisseurReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/bonRetourFournisseur/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      return [...bonRetourFournisseurState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBonRetourFournisseur = createAsyncThunk(
  "UPDATE-BON-RETOUR-FOURNISSEUR",
  async ({ bonRetourId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().bonRetourFournisseurReducer.data;

      const response = await axios.put(
        `https://www.gbgc.tn/bonRetourFournisseur/${bonRetourId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedBonRetourFournisseur = response.data;

      const newData = prevData.map((bonRetourFournisseur) =>
        bonRetourFournisseur.code_bon_retour === bonRetourId
          ? updatedBonRetourFournisseur
          : bonRetourFournisseur
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_bon_retour === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
