import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBonAvoir = createAsyncThunk(
  "GET-BON-AVOIR",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/bonAvoir/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addBonAvoir = createAsyncThunk(
  "ADD-BON-AVOIR",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const bonAvoirState = getState().bonAvoirReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/bonAvoir/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      return [...bonAvoirState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBonAvoir = createAsyncThunk(
  "UPDATE-BON-AVOIR",
  async ({ bonAvoirId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().bonAvoirReducer.data;

      const response = await axios.put(
        `https://www.gbgc.tn/bonAvoir/${bonAvoirId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedBonAvoir = response.data;

      const newData = prevData.map((bonAvoir) =>
        bonAvoir.code_bon_avoir === bonAvoirId ? updatedBonAvoir : bonAvoir
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_bon_avoir === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
