import React, { useMemo, useState } from "react";
import useArticle from "hooks/useArticle";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomButton from "components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomModal from "components/Modal/CustomModal";

import CustomInput from "components/Input/CustomInput";
import CustomGrid from "components/Grid/CustomGrid";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";
import useConfined from "hooks/useConfined";
import { deleteConfined } from "store/confined/actions";
import useSide from "hooks/useSide";
import { addSide, deleteSide } from "store/side/actions";
import usePetitMateriel from "hooks/usePetitMateriel";
import {
  addPetitMateriel,
  deletePetitMateriel,
  updatePetitMateriel,
} from "store/petitMateriaux/actions";
const PetitMateriaux = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = usePetitMateriel();
  const { t } = useTranslation();
  const [marque, setMarque] = useState("");
  const [type, setType] = useState("");
  const [nSerie, setNSerie] = useState("");
  const [model, setModel] = useState("");
  const [typeConsommable, setTypeConsommable] = useState("");
  const typeData = [
    { label: "gasoil", value: "Gasoil" },
    { label: "diesel", value: "Diesel" },
    { label: "gaz", value: "Gaz" },
  ];
  const language = useSelector((state) => state.global.language);
  const defaultTypeOption = typeData.find(
    (option) => option.value == selected.type_consommation
  );
  const dispatch = useDispatch();

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };
  const handleTypeConsommableChange = (event, selectedOption) => {
    setTypeConsommable(selectedOption);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      marque: marque,
      type: type,
      nSerie: nSerie,
      model: model,
      type_consommation: typeConsommable.value,
    };
    dispatch(addPetitMateriel(postData));
    handleClose();
  };
  const handleUpdate = (e, petitMaterielId) => {
    e.preventDefault();
    const updatedData = {
      marque: marque !== "" ? marque : selected.marque,
      type: type !== "" ? type : selected.type,
      nSerie: nSerie !== "" ? nSerie : selected.nSerie,
      model: model !== "" ? model : selected.model,
      type_consommation:
        typeConsommable.value !== undefined
          ? typeConsommable.value
          : selected.type_consommation,
    };
    dispatch(updatePetitMateriel({ petitMaterielId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (petitMaterielId) => {
    dispatch(deletePetitMateriel(petitMaterielId));
  };
  const columns = [
    {
      field: "marque",
      headerName: "marque",
      flex: 0.4,
    },
    {
      field: "type",
      headerName: "type",
      flex: 0.4,
    },
    {
      field: "nSerie",
      headerName: "numero de serie",
      flex: 0.4,
    },
    {
      field: "model",
      headerName: "model",
      flex: 0.4,
    },
    {
      field: "type_consommable",
      headerName: "type consommable",
      flex: 0.4,
    },

    {
      field: "modifier",
      headerName: t("articles.modifier"),
      flex: 0.3,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("articles.supprimer"),
      flex: 0.3,
      renderCell: (params) => (
        <CustomButton
          children={t("famille.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const dateCreate = new Date(item.date);
      const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return {
        id: item.id_materiel,
        marque: item.marque,
        type: item.type,
        nSerie: item.nSerie,
        model: item.model,
        type_consommable: item.type_consommation,
      };
    });
  }, [data]);
  const createConfinedButton = (
    <CustomButton
      children={"cree un brigade"}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );

  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste des brigades de police"}
        search={handleSearchChange}
        button={createConfinedButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.id_materiel}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {"modifier un produit"}
              </Typography>

              <CustomInput
                value={selected.marque}
                label={"marque"}
                onChange={handleChange(setMarque)}
              />
              <CustomInput
                label={"type"}
                value={selected.type}
                onChange={handleChange(setType)}
              />
              <CustomInput
                value={selected.nSerie}
                label={"numero de serie"}
                onChange={handleChange(setNSerie)}
              />
              <CustomInput
                value={selected.model}
                label={"model"}
                onChange={handleChange(setModel)}
              />
              <CustomSelect
                options={typeData}
                defaultValue={defaultTypeOption}
                label={"type de consommable"}
                onChange={handleTypeConsommableChange}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.id_materiel)}
                size={"large"}
                variant={"contained"}
                children={t("cat.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("articles.creerarticle")}
              </Typography>
              <CustomInput
                label={"marque"}
                onChange={handleChange(setMarque)}
              />
              <CustomInput label={"type"} onChange={handleChange(setType)} />
              <CustomInput
                label={"numero de serie"}
                onChange={handleChange(setNSerie)}
              />
              <CustomInput label={"model"} onChange={handleChange(setModel)} />
              <CustomSelect
                options={typeData}
                label={"type de consommable"}
                onChange={handleTypeConsommableChange}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("articles.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default PetitMateriaux;
