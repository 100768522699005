import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBonEntre = createAsyncThunk(
  "GET-BON-ENTRE",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/bonEntre/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data[0];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addBonEntre = createAsyncThunk(
  "ADD-BON-ENTRE",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const bonEntreState = getState().bonEntreReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/bonEntre/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return [{ ...response.data }, ...bonEntreState];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBonEntre = createAsyncThunk(
  "UPDATE-BON-ENTRE",
  async ({ bonEntreId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().bonEntreReducer.data;

      const response = await axios.put(
        `https://www.gbgc.tn/bonEntre/update/${bonEntreId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedBonEntre = response.data;

      const newData = prevData.map((bonEntre) =>
        bonEntre.code_bon_entre === bonEntreId ? updatedBonEntre : bonEntre
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_bon_entre === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
