import React from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import logo from "../../../assets/logo.png";

const InvoiceTitle = ({ styles }) => {
  return (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <Text style={styles.text}>نظير للحفظ</Text>
        <Image style={styles.logo} src={logo} />
        <View style={styles.textRight}>
          <Text style={[styles.text, styles.textRight]}>
            الجمهورية التونسية
          </Text>
          <Text style={[styles.text, styles.textRight]}>وزارة الداخلية</Text>
          <Text style={[styles.text, styles.textRight]} textAnchor="end">
            بلدية العامرة
          </Text>
        </View>
      </View>
    </View>
  );
};

export default InvoiceTitle;
