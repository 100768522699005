import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getReceipt = createAsyncThunk(
  "GET-RECEIPT",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/receipt/all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addReceipt = createAsyncThunk(
  "ADD-RECEIPT",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const ReceiptState = getState().receiptReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/receipt/create",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return [...ReceiptState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteReceipt = createAsyncThunk(
  "DELETE-RECEIPT",
  async (params, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().receiptReducer.data;
      const prevFilteredData = getState().receiptReducer.filteredData;
      let newData = [];
      let newFilteredData = [];

      await axios.delete(`https://www.gbgc.tn/receipt/${params}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      newData = [...prevData.filter((item) => item.id !== params)];
      newFilteredData = [
        ...prevFilteredData.filter((item) => item.id !== params),
      ];

      return { newData, newFilteredData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateReceipt = createAsyncThunk(
  "UPDATE-RECEIPT",
  async ({ ReceiptId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().receiptReducer.data;
      const response = await axios.put(
        `https://www.gbgc.tn/receipt/update/${ReceiptId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedReceipt = response.data;

      const newData = prevData.map((Receipt) =>
        Receipt.id === ReceiptId ? updatedReceipt : Receipt
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find((s) => s.id === action.payload.key);
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
