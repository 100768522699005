import React, { useEffect, useMemo, useState } from "react";
import useArticle from "hooks/useArticle";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomButton from "components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomModal from "components/Modal/CustomModal";

import CustomInput from "components/Input/CustomInput";
import CustomGrid from "components/Grid/CustomGrid";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";
import useConfined from "hooks/useConfined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  addConfined,
  deleteConfined,
  updateConfined,
} from "store/confined/actions";
import styled from "styled-components";
import DownloadConfined from "components/PDF/ConfinedPDF/DownloadConfined";
import PrintConfined from "components/PDF/ConfinedPDF/PrintConfined";
import { DeleteForeverSharp } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween/FlexBetween";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Confined = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useConfined();
  const { t } = useTranslation();
  useEffect(() => {
    if (updateOpen && selected.details !== undefined) {
      try {
        const parsedCart = JSON.parse(selected.details);
        setDetails(parsedCart);
      } catch (error) {
        setDetails([]);
      }
    }
  }, [updateOpen, selected.details]);
  const language = useSelector((state) => state.global.language);
  const [fullname, setFullname] = useState("");
  const [side, setSide] = useState("");
  const [agent, setAgent] = useState("");
  const [adress, setAdress] = useState("");
  const [reason, setReason] = useState("");
  const [place, setPlace] = useState("");
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [details, setDetails] = useState([]);
  const [detailInput, setDetailInput] = useState("");
  const agentData = useSelector((state) => state.agentConfinedReducer.data);
  const sideData = useSelector((state) => state.sideReducer.data);
  const transformedAgentData = agentData.map((item) => ({
    uid: item.uid,
    label: item.nom + " " + item.prenom,
  }));
  const transformedSideData = sideData.map((item) => ({
    id: item.id,
    label: item.designation,
  }));
  const defaultSideOption = transformedSideData.find(
    (option) => option.id == selected.side
  );
  const defaultAgentOption = transformedAgentData.find((option) => {
    return option.label == selected.agent;
  });

  const dispatch = useDispatch();
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      try {
        const base64String = await convertFileToBase64(file);
        setImage(base64String);
      } catch (error) {
        console.error("Error converting image to base64:", error);
      }
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };
  const handleSideChange = (event, selectedOption) => {
    setSide(selectedOption);
  };
  const handleAgentChange = (event, selectedOption) => {
    setAgent(selectedOption);
  };

  const addDetail = () => {
    if (detailInput.trim() !== "") {
      setDetails((prevDetails) => [...prevDetails, detailInput]);
      setDetailInput("");
    }
  };

  const removeDetail = (index) => {
    setDetails((prevDetails) => prevDetails.filter((_, i) => i !== index));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      fullName: fullname,
      side: side.id,
      agent: agent.label,
      address: adress,
      details: details,
      reason: reason,
      place: place,
      image: image,
    };
    dispatch(addConfined(postData));
    handleClose();
  };
  const handleUpdate = (e, confinedId) => {
    e.preventDefault();
    const updatedData = {
      fullName: fullname !== "" ? fullname : selected.fullName,
      side: side.id !== undefined ? side.id : selected.side,
      agent: agent.label !== undefined ? agent.label : selected.agent,
      address: adress !== "" ? adress : selected.address,
      details: details !== "" ? details : selected.details,
      reason: reason !== "" ? reason : selected.reason,
      place: place !== "" ? place : selected.place,
    };
    dispatch(updateConfined({ confinedId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (confinedId) => {
    dispatch(deleteConfined(confinedId));
  };
  const columns = [
    {
      field: "fullName",
      headerName: "full name",
      flex: 0.4,
    },
    {
      field: "side",
      headerName: "brigade",
      flex: 0.4,
    },
    {
      field: "address",
      headerName: "adress",
      flex: 0.4,
    },
    {
      field: "place",
      headerName: "place",
      flex: 0.4,
    },
    {
      field: "reason",
      headerName: "reason",
      flex: 0.4,
    },
    {
      field: "agent",
      headerName: "agent",
      flex: 0.4,
    },
    {
      field: "date",
      headerName: "date",
      flex: 0.4,
    },
    {
      field: "modifier",
      headerName: t("articles.modifier"),
      flex: 0.4,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("articles.supprimer"),
      flex: 0.4,
      renderCell: (params) => (
        <CustomButton
          children={t("famille.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
    {
      field: "PDF",
      headerName: t("bef.downloads"),
      flex: 0.5,
      renderCell: (params) => <DownloadConfined row={params.row} />,
    },
    {
      field: "Print",
      headerName: t("bef.imprimer"),
      flex: 0.4,
      renderCell: (params) => <PrintConfined row={params.row} />,
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const dateCreate = new Date(item.date);
      const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const side = sideData.find((side) => {
        return side.id == item.side;
      });
      const sideDesignation = side ? side.designation : "";
      const list = JSON.parse(item.details);
      const detail = list?.map((item) => {
        return { item };
      });
      return {
        id: item.id,
        fullName: item.fullName,
        address: item.address,
        side: sideDesignation,
        place: item.place,
        reason: item.reason,
        detail: detail,
        date: formattedDateCreate,
        agent: item.agent,
        barCode: item.barCode,
      };
    });
  }, [data, sideData]);
  const createConfinedButton = (
    <CustomButton
      children={"créer un confisquée"}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );

  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste des confisquée"}
        search={handleSearchChange}
        button={createConfinedButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.uid}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {"modifier un confisquée"}
              </Typography>
              <CustomInput
                value={selected.fullName}
                label={"Full Name"}
                onChange={handleChange(setFullname)}
              />
              <CustomInput
                value={selected.address}
                label={"Address"}
                onChange={handleChange(setAdress)}
              />
              <CustomInput
                value={selected.reason}
                label={"reason"}
                onChange={handleChange(setReason)}
              />
              <CustomInput
                value={selected.place}
                label={"place"}
                onChange={handleChange(setPlace)}
              />

              <CustomSelect
                defaultValue={defaultSideOption}
                options={transformedSideData}
                label={"brigade de police"}
                onChange={handleSideChange}
              />
              <CustomSelect
                defaultValue={defaultAgentOption}
                options={transformedAgentData}
                label={"agent confined"}
                onChange={handleAgentChange}
              />
              <FlexBetween gap={2} width={"100%"}>
                <CustomInput
                  label={"detail"}
                  onChange={handleChange(setDetailInput)}
                />
                <Button variant="contained" color="primary" onClick={addDetail}>
                  Add Detail
                </Button>
              </FlexBetween>
              <List>
                {details.map((detail, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={detail} />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeDetail(index)}
                    >
                      <DeleteForeverSharp />
                    </IconButton>
                  </ListItem>
                ))}
              </List>

              <CustomButton
                onClick={(e) => handleUpdate(e, selected.id)}
                size={"large"}
                variant={"contained"}
                children={t("cat.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {"créer un confisquée"}
              </Typography>
              <CustomInput
                label={"Full Name"}
                onChange={handleChange(setFullname)}
              />
              <CustomInput
                label={"Address"}
                onChange={handleChange(setAdress)}
              />
              <CustomInput
                label={"reason"}
                onChange={handleChange(setReason)}
              />
              <CustomInput label={"place"} onChange={handleChange(setPlace)} />
              <CustomSelect
                options={transformedSideData}
                label={"brigade de police"}
                onChange={handleSideChange}
              />
              <CustomSelect
                options={transformedAgentData}
                label={"agent confined"}
                onChange={handleAgentChange}
              />
              <FlexBetween gap={2} width={"100%"}>
                <CustomInput
                  label={"detail"}
                  onChange={handleChange(setDetailInput)}
                />
                <Button variant="contained" color="primary" onClick={addDetail}>
                  Add Detail
                </Button>
              </FlexBetween>
              <List>
                {details.map((detail, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={detail} />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeDetail(index)}
                    >
                      <DeleteForeverSharp />
                    </IconButton>
                  </ListItem>
                ))}
              </List>

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={language === "ar" ? <CloudUploadIcon /> : null}
                endIcon={language === "fr" ? <CloudUploadIcon /> : null}
              >
                <VisuallyHiddenInput type="file" onChange={handleImageChange} />
              </Button>
              {fileName && (
                <p>
                  {t("bcf.simg")} : {fileName}
                </p>
              )}
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("articles.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};
export default Confined;
