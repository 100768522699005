import { Input, TextField } from "@mui/material";
import React from "react";

const CustomInput = ({ type, onChange, value, label }) => {
  const handleInputChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <TextField
      type={type}
      defaultValue={value}
      onChange={handleInputChange}
      fullWidth
      id="outlined-required"
      label={label}
      color="primary"
      variant="outlined"
    />
  );
};

export default CustomInput;
