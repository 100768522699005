import React, { useMemo, useState } from "react";
import useArticle from "hooks/useArticle";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomButton from "components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomModal from "components/Modal/CustomModal";
import {
  addArticle,
  deleteArticle,
  updateArticle,
} from "store/article/actions";
import CustomInput from "components/Input/CustomInput";
import CustomGrid from "components/Grid/CustomGrid";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";
import CustomExcelDownload from "components/Excel/CustomExcelDownload";

const Articles = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useArticle();
  const { t } = useTranslation();

  const isNonMobile = useMediaQuery("(min-width : 1024px)");
  const [designation, setDesignation] = useState("");
  const [famille, setFamille] = useState(0);
  const [marque, setMarque] = useState("");
  const [unite, setUnite] = useState("");
  const [oldPrice, setOldPrice] = useState(0);
  const [stockMin, setStockMin] = useState(0);
  const [stockMax, setStockMax] = useState(0);
  const [stockActuel, setStockActuel] = useState(0);
  const [TVA, setTVA] = useState(0.0);
  const [depot, setDepot] = useState(0);

  const categorieData = useSelector((state) => state.categorieReducer.data);
  const familleData = useSelector((state) => state.familleReducer.data);
  const depotData = useSelector((state) => state.depotReducer.data);
  const language = useSelector((state) => state.global.language);

  const transformedFamilleData = familleData.map((item) => ({
    label: item.designation,
    code_famille: item.code_famille,
  }));

  const transformedDepotData = depotData.map((item) => ({
    label: item.designation,
    code_depot: item.code_depot,
  }));
  const tvaData = [
    { label: "7%", value: 0.07 },
    { label: "19%", value: 0.19 },
  ];
  const dispatch = useDispatch();

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };

  const handleDesignationChange = handleChange(setDesignation);
  const handleMarqueChange = handleChange(setMarque);
  const handleUniteChange = handleChange(setUnite);
  const handleOldPriceChange = handleChange(setOldPrice);
  const handleStockMinChange = handleChange(setStockMin);
  const handleStockMaxChange = handleChange(setStockMax);
  const handleStockActuelChange = handleChange(setStockActuel);

  const handleTVAChange = (event, selectedOption) => {
    setTVA(selectedOption);
  };
  const handleFamilleChange = (event, selectedOption) => {
    setFamille(selectedOption);
  };
  const handleDepotChange = (event, selectedOption) => {
    setDepot(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedFamille = familleData.find(
      (familleOption) => familleOption.code_famille == famille.code_famille
    );

    const code_categorie = selectedFamille
      ? selectedFamille.code_categorie
      : null;
    const postData = {
      designation: designation,
      marque: marque,
      unite: unite,
      prix_ancien: oldPrice,
      stock_minimal: stockMin,
      stock_maximal: stockMax,
      stock_actuel: stockActuel,
      TVA: TVA.value,
      code_famille: famille.code_famille,
      code_categorie: code_categorie,
      code_depot: depot.code_depot,
      etat: 1,
    };
    dispatch(addArticle(postData));
    handleClose();
  };
  const handleUpdate = (e, articleId) => {
    e.preventDefault();

    const selectedArticle = data.find(
      (article) => article.code_article === articleId
    );
    const selectedFamille = familleData.find(
      (familleOption) => familleOption.code_famille == famille.code_famille
    );
    const code_categorie = selectedFamille
      ? selectedFamille.code_categorie
      : null;

    const updatedData = {
      designation:
        designation !== "" ? designation : selectedArticle.designation,
      marque: marque !== "" ? marque : selectedArticle.marque,
      unite: unite !== "" ? unite : selectedArticle.unite,
      prix_ancien: oldPrice !== 0 ? oldPrice : selectedArticle.prix_ancien,
      stock_minimal: stockMin !== 0 ? stockMin : selectedArticle.stock_minimal,
      stock_maximal: stockMax !== 0 ? stockMax : selectedArticle.stock_maximal,
      stock_actuel:
        stockActuel !== 0 ? stockActuel : selectedArticle.stock_actuel,
      TVA: TVA.value !== undefined ? TVA.value : selectedArticle.TVA,
      code_famille:
        famille.code_famille !== undefined
          ? selectedFamille.code_famille
          : selectedArticle.code_famille,
      code_categorie:
        selectedFamille.code_famille !== undefined
          ? code_categorie !== undefined
            ? code_categorie
            : selectedArticle.code_categorie
          : selectedArticle.code_categorie,
      code_depot:
        depot.code_depot !== undefined
          ? depot.code_depot
          : selectedArticle.code_depot,

      etat: 1,
    };

    dispatch(updateArticle({ articleId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (articleId) => {
    dispatch(deleteArticle(articleId));
  };
  const columns = [
    {
      field: "designation",
      headerName: t("articles.designation"),
      flex: 0.4,
    },
    {
      field: "categorie",
      headerName: t("articles.categorie"),
      flex: 0.4,
    },
    {
      field: "famille",
      headerName: t("articles.famille"),
      flex: 0.4,
    },
    {
      field: "depot",
      headerName: t("articles.depot"),
      flex: 0.4,
    },
    {
      field: "marque",
      headerName: t("articles.marque"),
      flex: 0.4,
    },
    {
      field: "unite",
      headerName: t("articles.unite"),
      flex: 0.2,
    },
    {
      field: "prix_ancien",
      headerName: t("articles.ancienprix"),
      flex: 0.2,
    },
    {
      field: "PMP",
      headerName: t("articles.pmp"),
      flex: 0.2,
    },
    {
      field: "stock_actuel",
      headerName: t("articles.stockact"),
      flex: 0.3,
    },
    {
      field: "stock_minimal",
      headerName: t("articles.stockmin"),
      flex: 0.3,
    },
    {
      field: "stock_maximal",
      headerName: t("articles.stockmax"),
      flex: 0.3,
    },
    {
      field: "TVA",
      headerName: t("articles.tva"),
      flex: 0.2,
    },
    {
      field: "etat",
      headerName: t("articles.etat"),
      flex: 0.2,
    },
    {
      field: "modifier",
      headerName: t("articles.modifier"),
      flex: 0.2,
      renderCell: (params) => (
        <CustomIconButton
          children={<CreateIcon />}
          color={"white"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("articles.modifier")}
        </CustomIconButton>
      ),
    },
    {
      field: "supprimer",
      headerName: t("articles.supprimer"),
      flex: 0.2,
      renderCell: (params) => (
        <CustomIconButton
          children={<DeleteIcon />}
          color={"white"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        >
          {t("articles.supprimer")}
        </CustomIconButton>
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const category = categorieData.find(
        (cat) => cat.code_categorie === item.code_categorie
      );
      const familly = familleData.find(
        (fam) => fam.code_famille === item.code_famille
      );
      const depot = depotData.find((dep) => dep.code_depot === item.code_depot);
      const categorieDesignation = category ? category.designation : "";
      const familleDesignation = familly ? familly.designation : "";
      const depotDesignation = depot ? depot.designation : "";
      return {
        id: item.code_article,
        designation: item.designation,
        categorie: categorieDesignation,
        famille: familleDesignation,
        depot: depotDesignation,
        marque: item.marque,
        unite: item.unite,
        prix_ancien: item.prix_ancien,
        PMP: item.PMP,
        stock_actuel: item.stock_actuel,
        stock_minimal: item.stock_minimal,
        stock_maximal: item.stock_maximal,
        TVA: item.TVA === 0.19 ? "19%" : "7%",
        etat: item.etat === 1 ? "actif" : "inActif",
      };
    });
  }, [data, categorieData, familleData, depotData]);
  const createArticleButton = (
    <CustomButton
      children={t("articles.creerarticle")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  const downloadExcelButton = (
    <CustomExcelDownload data={dataWithButtons || []} />
  );
  const defaultFamilleOption = transformedFamilleData.find(
    (option) => option.code_famille === selected.code_famille
  );
  const defaultDepotOption = transformedDepotData.find(
    (option) => option.code_depot === selected.code_depot
  );
  const defaultTVAOption = tvaData.find(
    (option) => option.value === selected.TVA
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={t("articles.list")}
        Excel={downloadExcelButton}
        search={handleSearchChange}
        button={createArticleButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.code_article}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("articles.editarticle")}
              </Typography>
              <CustomGrid gridTemplateColumns={isNonMobile ? "1fr 1fr" : null}>
                <CustomInput
                  value={selected.designation}
                  label={t("articles.designation")}
                  onChange={handleDesignationChange}
                />
                <CustomInput
                  value={selected.marque}
                  label={t("articles.marque")}
                  onChange={handleMarqueChange}
                />
                <CustomInput
                  value={selected.unite}
                  label={t("articles.unite")}
                  onChange={handleUniteChange}
                />
                <CustomInput
                  value={selected.prix_ancien}
                  label={t("articles.ancienprix")}
                  onChange={handleOldPriceChange}
                />
                <CustomInput
                  value={selected.stock_minimal}
                  label={t("articles.stockmin")}
                  onChange={handleStockMinChange}
                />
                <CustomInput
                  value={selected.stock_maximal}
                  label={t("articles.stockmax")}
                  onChange={handleStockMaxChange}
                />
                <CustomInput
                  value={selected.stock_actuel}
                  label={t("articles.stockact")}
                  onChange={handleStockActuelChange}
                />
                <CustomSelect
                  defaultValue={defaultFamilleOption}
                  onChange={handleFamilleChange}
                  label={t("articles.famille")}
                  options={transformedFamilleData}
                />
                <CustomSelect
                  defaultValue={defaultDepotOption}
                  onChange={handleDepotChange}
                  label={t("articles.depot")}
                  options={transformedDepotData}
                />
                <CustomSelect
                  defaultValue={defaultTVAOption}
                  onChange={handleTVAChange}
                  label={t("articles.tva")}
                  options={tvaData}
                />
              </CustomGrid>
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.code_article)}
                size={"large"}
                variant={"contained"}
                children={t("articles.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("articles.creerarticle")}
              </Typography>
              <CustomGrid gridTemplateColumns={isNonMobile ? "1fr 1fr" : null}>
                <CustomInput
                  value={designation}
                  label={t("articles.designation")}
                  onChange={handleDesignationChange}
                />
                <CustomInput
                  value={marque}
                  label={t("articles.marque")}
                  onChange={handleMarqueChange}
                />
                <CustomInput
                  value={unite}
                  label={t("articles.unite")}
                  onChange={handleUniteChange}
                />
                <CustomInput
                  value={oldPrice}
                  label={t("articles.ancienprix")}
                  onChange={handleOldPriceChange}
                />
                <CustomInput
                  value={stockMin}
                  label={t("articles.stockmin")}
                  onChange={handleStockMinChange}
                />
                <CustomInput
                  value={stockMax}
                  label={t("articles.stockmax")}
                  onChange={handleStockMaxChange}
                />
                <CustomInput
                  value={stockActuel}
                  label={t("articles.stockact")}
                  onChange={handleStockActuelChange}
                />
                <CustomSelect
                  onChange={handleFamilleChange}
                  label={t("articles.famille")}
                  options={transformedFamilleData}
                />
                <CustomSelect
                  onChange={handleDepotChange}
                  label={t("articles.depot")}
                  options={transformedDepotData}
                />
                <CustomSelect
                  onChange={handleTVAChange}
                  label={t("articles.tva")}
                  options={tvaData}
                />
              </CustomGrid>

              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("articles.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Articles;
