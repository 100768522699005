import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import CustomDataGrid from "components/DataGrid/DataGrid";
import Header from "components/Header/Header";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/Button/CustomButton";

import CustomModal from "components/Modal/CustomModal";

import CustomSelect from "components/Input/CustomSelect";
import { updateBonCommandeFournisseur } from "store/BonCommandeFournisseur/actions";
import useBonEntre from "hooks/useBonEntre";
import { DataGrid } from "@mui/x-data-grid";
import { addBonEntre } from "store/BonEntre/actions";
import useBonCommandeInterne from "hooks/useBonCommandeInterne";
import PrintButton from "components/PDF/PrintButton";
import DownloadButton from "components/PDF/DownloadButton";
import {
  addBonCommandeInterne,
  updateBonCommandeInterne,
} from "store/BonCommandeInterne/actions";
import { useTranslation } from "react-i18next";
import { DeleteForeverSharp } from "@mui/icons-material";

const BonCommandeInterne = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    detailOpen,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleDetailClose,
    handleDetailOpenModal,
    handleDetailOpen,
    handleSearchChange,
    cart,
    setCart,
  } = useBonCommandeInterne();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [depot, setDepot] = useState(0);
  const [fournisseur, setFournisseur] = useState(0);
  const [code, setCode] = useState("");
  const [selectedSite, setSelectedSite] = useState(null);
  const [articles, setArticles] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (updateOpen && selected.list_article !== undefined) {
      try {
        const parsedCart = JSON.parse(selected.list_article);
        setCart(parsedCart);
      } catch (error) {
        console.error("Error parsing list_article:", error);
        setCart([]);
      }
    }
  }, [updateOpen, selected.list_article]);
  const language = useSelector((state) => state.global.language);

  const articleData = useSelector((state) => state.articleReducer.data);
  const BCIData = useSelector((state) => state.bonCommandeInterneReducer.data);
  const siteData = useSelector((state) => state.siteReducer.data);
  const userData = useSelector((state) => state.userReducer.data);

  const transformedBCIData = BCIData.map((BCI) => ({
    code_bon_commande_interne: BCI.code_bon_commande_interne,
    label: BCI.code_bon_commande_interne,
  }));
  const transformedsiteData = siteData.map((site) => ({
    code_site: site.code_site,
    label: site.designation,
  }));
  const transformedArticleData = articleData.map((product) => ({
    code_article: product.code_article,
    label: product.designation,
    stock_actuel: product.stock_actuel,
  }));
  const defaultSiteOption = transformedsiteData.find(
    (option) => option.code_site == selected.nom_department
  );

  const handleSiteChange = (event, selectedOption) => {
    setSelectedSite(selectedOption);
  };
  const handleProductChange = (event, selectedOption) => {
    setSelectedProduct(selectedOption);
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value));
  };
  const addToCart = () => {
    if (selectedProduct) {
      const {
        code_article,
        label: designation,
        stock_actuel,
      } = selectedProduct;
      const newItem = {
        code_article,
        designation,
        quantity,
        stock_actuel,
      };
      setCart([...cart, newItem]);
      setSelectedProduct(null);
      setQuantity(1);
    }
  };
  const handleRemoveItem = (item) => {
    setCart(
      cart.filter((cartItem) => cartItem.code_article !== item.code_article)
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      nom_department: selectedSite.code_site,
      list_article: cart,
      etat: 1,
    };
    dispatch(addBonCommandeInterne(postData));
    handleClose();
  };
  const handleUpdate = (e, bonCommandeInterneId) => {
    e.preventDefault();

    const selectedBCI = data.find(
      (BCF) => BCF.code_bon_commande_interne === bonCommandeInterneId
    );

    const updatedData = {
      code_bon_commande_interne:
        code !== "" ? code : selectedBCI.code_bon_commande_interne,
      nom_department:
        selectedSite !== null
          ? selectedSite.code_site
          : selectedBCI.nom_department,
      list_article: cart !== "" ? cart : selectedBCI.list_article,
      etat: 1,
    };
    // console.log("🚀 ~ handleUpdate ~ updatedData:", updatedData);
    dispatch(updateBonCommandeInterne({ bonCommandeInterneId, updatedData }));
    handleUpdateClose();
  };

  const columns = [
    {
      field: "id",
      headerName: t("bci.code"),
      flex: 1,
    },
    {
      field: "Site",
      headerName: t("bci.site"),
      flex: 1,
    },
    {
      field: "detail",
      headerName: t("brf.details"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <InfoOutlinedIcon /> : null}
          endIcon={language === "fr" ? <InfoOutlinedIcon /> : null}
          variant="contained"
          color={"success"}
          onClick={() => handleDetailOpenModal(params.id)}
        >
          {t("brf.details")}
        </Button>
      ),
    },
    {
      field: "modifier",
      headerName: t("brf.modifier"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "PDF",
      headerName: t("bef.downloads"),
      flex: 0.6,
      renderCell: (params) => (
        <DownloadButton
          row={params.row}
          invoiceTitle={
            "REPUBLIQUE TUNISIENNE MINISTERE DE L'INTERIEUR COMMUNE DE JERBA MIDOUN"
          }
          bonTitle={"Bon Commande Interne"}
          addressTitle={"service a livrée: " + params.row.Site}
          date={"date: " + params.row.date}
          quantite={"quantité in bon commande"}
        />
      ),
    },
    {
      field: "Print",
      headerName: t("bef.imprimer"),
      flex: 0.5,
      renderCell: (params) => (
        <PrintButton
          row={params.row}
          invoiceTitle={
            "REPUBLIQUE TUNISIENNE MINISTERE DE L'INTERIEUR COMMUNE DE JERBA MIDOUN"
          }
          bonTitle={"Bon Commande Interne"}
          addressTitle={"service a livrée: " + params.row.Site}
          date={"date: " + params.row.date}
          quantite={"quantité in bon commande"}
        />
      ),
    },
  ];
  const columnsDetail = [
    {
      field: "UserC",
      headerName: t("brf.userc"),
      flex: 1,
    },
    {
      field: "DateC",
      headerName: t("brf.datecreation"),
      flex: 1,
    },
  ];
  const columnsListArticle = [
    {
      field: "designation",
      headerName: t("brf.designation"),
      flex: 1,
    },
    {
      field: "quantity",
      headerName: t("brf.quantite"),
      flex: 1,
    },
  ];

  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const site = siteData.find(
        (site) => site.code_site == item.nom_department
      );
      const dateCreate = new Date(item.date_create);
      const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return {
        id: item.code_bon_commande_interne,
        Site: site?.designation,
        etat: item.etat,
        list_article: JSON.parse(item.list_article).map((item) => {
          return {
            id: item.code_article,
            designation: item.designation,
            quantity: item.quantity,
          };
        }),
        date: formattedDateCreate,
      };
    });
  }, [data]);
  const DetailData = useMemo(() => {
    // Extracting properties from the selected object
    const { code_user_create, date_create } = selected;
    const date = new Date(date_create);
    const user = userData.find((user) => {
      return code_user_create == user.id;
    });
    const formattedDate = date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return [
      {
        id: selected.code_bon_commande_interne,
        UserC: user?.nom + " " + user?.prenom,
        DateC: formattedDate,
      },
    ];
  }, [selected, userData]);
  const DetailListArticle = useMemo(() => {
    // Extracting properties from the selected object
    const { list_article } = selected;
    const dataArray = JSON.parse(list_article || "[]");
    return dataArray.map((item) => {
      return {
        id: item.code_article,
        designation: item.designation,
        quantity: item.quantity,
      };
    });
  }, [selected]);

  const createBCIButton = (
    <CustomButton
      children={t("bci.creerbci")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={t("bci.list")}
        button={createBCIButton}
        search={handleSearchChange}
      />
      <CustomDataGrid
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {detailOpen && (
        <CustomModal
          onClose={handleDetailClose}
          open={detailOpen}
          children={
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "75vh",
                overflowY: "auto",
                gap: 10,
              }}
            >
              <Typography variant="h3" mt={"1rem"}>
                {t("bci.detail")} :
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailData}
                columns={columnsDetail}
                sx={{ "& .MuiDataGrid-footerContainer": { display: "none" } }}
              />
              <Typography variant="h3" mt={"1rem"}>
                {t("brf.listart")} :
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailListArticle}
                columns={columnsListArticle}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
              />
            </Box>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {t("bci.creerbci")}
              </Typography>
              <CustomSelect
                onChange={handleSiteChange}
                label={t("bci.site")}
                options={transformedsiteData}
              />
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" mb={"1rem"}>
                    {t("bcf.prs")}
                  </Typography>
                  <CustomSelect
                    onChange={handleProductChange}
                    label={t("brf.listart")}
                    options={transformedArticleData}
                    value={
                      selectedProduct ? selectedProduct.code_article : null
                    }
                  />

                  {selectedProduct && (
                    <Fragment>
                      <TextField
                        label={t("brf.quantite")}
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        fullWidth
                        margin="normal"
                        inputProps={{
                          min: 1,
                          max: selectedProduct.stock_actuel,
                        }}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={addToCart}
                        disabled={!selectedProduct}
                      >
                        {t("brf.add")}
                      </Button>
                    </Fragment>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" m={"1rem 0"}>
                    {t("brf.selart")}
                  </Typography>
                  {cart.length === 0 ? (
                    <p style={{ textAlign: "center" }}>{t("brf.listvide")}</p>
                  ) : (
                    <List>
                      {cart.map((item) => (
                        <ListItem
                          key={item.code_article}
                          secondaryAction={
                            <IconButton
                              onClick={() => handleRemoveItem(item)}
                              edge={language === "ar" ? "start" : "end"}
                            >
                              <DeleteForeverSharp />
                            </IconButton>
                          }
                        >
                          <ListItemText
                            primary={item.designation}
                            secondary={`${t("brf.quantite")}: ${item.quantity}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("frs.creer")}
              />
            </form>
          }
        />
      )}
      {updateOpen && (
        <CustomModal
          key={selected.code_bon_commande_interne}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <CustomSelect
                defaultValue={defaultSiteOption}
                onChange={handleSiteChange}
                label={t("bci.site")}
                options={transformedsiteData}
              />
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" mb={"1rem"}>
                    {t("bcf.prs")}
                  </Typography>
                  <CustomSelect
                    onChange={handleProductChange}
                    label={t("brf.listart")}
                    options={transformedArticleData}
                    value={
                      selectedProduct ? selectedProduct.code_article : null
                    }
                  />

                  {selectedProduct && (
                    <Fragment>
                      <TextField
                        label={t("brf.quantite")}
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 1 }}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={addToCart}
                        disabled={!selectedProduct}
                      >
                        {t("brf.add")}
                      </Button>
                    </Fragment>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" m={"1rem 0"}>
                    {t("bsi.listartbc")}
                  </Typography>
                  {cart.length === 0 ? (
                    <p>{t("brf.listvide")}</p>
                  ) : (
                    <List>
                      {cart.map((item) => (
                        <ListItem
                          key={item.code_article}
                          secondaryAction={
                            <IconButton
                              onClick={() => handleRemoveItem(item)}
                              edge="end"
                            >
                              <DeleteForeverSharp />
                            </IconButton>
                          }
                        >
                          <ListItemText
                            primary={item.designation}
                            secondary={`${t("brf.quantite")}: ${item.quantity}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
              <CustomButton
                onClick={(e) =>
                  handleUpdate(e, selected.code_bon_commande_interne)
                }
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("brf.modifier")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default BonCommandeInterne;
