import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getTotalArticles = createAsyncThunk(
  "GET-ARTICLES-TOTAL",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/statistiques/articles/count",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data.count;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTotalSites = createAsyncThunk(
  "GET-SITES-TOTAL",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/statistiques/site/count",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data.count;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTotalUsers = createAsyncThunk(
  "GET-USERS-TOTAL",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/statistiques/total-users",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data.userCount;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getNbBonSortie = createAsyncThunk(
  "GET-BONSORTIE-TOTAL",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/statistiques/bon-sortie-count",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data.count;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getArticleNonMouve = createAsyncThunk(
  "GET-ARTICLE-NON-MOUVE",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/statistiques/articles/not/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getArticleVariation = createAsyncThunk(
  "GET-ARTICLE-VARIATION",
  async (code_article, { rejectWithValue }) => {
    console.log("🚀 ~ code_article:", code_article);
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        `https://www.gbgc.tn/statistiques/article-prices-by-date/${code_article}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      console.log("🚀 ~ response.data:", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
