import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  userId: "63701cc1f03239b7f700000e",
  direction: "ltr",
  language: "ar",
  loadingAll: "idle",
  error: undefined,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setDirection: (state) => {
      state.direction = state.direction === "rtl" ? "ltr" : "rtl";
      state.language = state.language === "ar" ? "fr" : "ar";
    },
  },
});

export const { setMode, setDirection } = globalSlice.actions;

export default globalSlice.reducer;
