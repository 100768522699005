import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/logo.png";
import logoTechno from "../../assets/logoTechno.png";
import axios from "axios";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
const textContent = "Bienvnue A Techno-Stock Djerba";
const Login = () => {
  const theme = useTheme();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const response = await axios.post("https://www.gbgc.tn/auth/login", {
        username: data.get("username"),
        password: data.get("password"),
      });
      window.localStorage.setItem("token", response.data.token);
      window.localStorage.setItem("role", response.data.role);
      window.localStorage.setItem("language", "fr");

      console.log(response);
      if (response.data.role === "GC") {
        window.location.href = "/consommable";
      } else if (response.data.role === "GP") {
        window.location.href = "/Confined";
      } else if (
        response.data.role === "SA" ||
        response.data.role === "RS" ||
        response.data.role === "GS"
      ) {
        window.location.href = "/dashboard";
      } else {
        window.location.href = "/categories";
      }
    } catch (error) {
      console.error("Échec de la connexion:", error);
    }
  };
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            p: 10,
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              style={{
                backgroundColor: "#fff",
                borderRadius: "50%",
                aspectRatio: "1/1",
                objectFit: "cover",
              }}
              width={200}
              loading="lazy"
            />
          </Box>
          <Typography variant="h1" sx={{ fontSize: 80, fontWeight: 700 }}>
            BIENVNUE A TECHNO-STOCK El AMRA
          </Typography>
        </Box>
        <Box
          sx={{
            height: "20vh",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "end",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={logoTechno}
              style={{
                objectFit: "contain",
              }}
              width={100}
              loading="lazy"
            />
            <Typography
              variant="h2"
              sx={{ fontSize: 18, fontWeight: 400, opacity: 0.4 }}
            >
              Fehritechno-consulting
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{ fontSize: 18, fontWeight: 400, opacity: 0.4, ml: -2.5 }}
              >
                <LocationOnIcon />
                Bureau B2 immeuble Lella rue Med Ali
              </Typography>
              <Typography
                variant="h2"
                sx={{ fontSize: 18, fontWeight: 400, opacity: 0.4 }}
              >
                8011 Dar chaabane el fehri
              </Typography>
              <Typography
                variant="h2"
                sx={{ fontSize: 18, fontWeight: 400, opacity: 0.4 }}
              >
                Matricule fiscale 1818846/R
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h2"
                sx={{
                  display: "flex",
                  gap: 1,
                  fontSize: 18,
                  fontWeight: 400,
                  opacity: 0.4,
                }}
              >
                <LocalPhoneIcon /> +216 28 808 223 / 36 499 048
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  display: "flex",
                  gap: 1,
                  fontSize: 18,
                  fontWeight: 400,
                  opacity: 0.4,
                }}
              >
                <EmailIcon /> contact@techno-consting.tn
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  display: "flex",
                  gap: 1,
                  fontSize: 18,
                  fontWeight: 400,
                  opacity: 0.4,
                }}
              >
                <LanguageIcon /> www.fehrithechno-consulting.tn
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="h2"
            sx={{ fontSize: 18, fontWeight: 400, opacity: 0.4 }}
          >
            © 2024 techno-consulting. Tous droits réservés.
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        sx={{
          backgroundColor: theme.palette.background.alt,
        }}
        square
      >
        <Box
          sx={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
