import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBonSortie = createAsyncThunk(
  "GET-BON-SORTIE",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/bon_sortie/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data[0];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addBonSortie = createAsyncThunk(
  "ADD-BON-SORTIE",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const bonSortieState = getState().bonSortieReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/bon_sortie/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      return [...bonSortieState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBonSortie = createAsyncThunk(
  "UPDATE-BON-SORTIE",
  async ({ bonSortieId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().bonSortieReducer.data;

      const response = await axios.put(
        `https://www.gbgc.tn/bon_sortie/${bonSortieId}/update`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedBonSortie = response.data;

      const newData = prevData.map((bonSortie) =>
        bonSortie.code_bon_sortie === bonSortieId ? updatedBonSortie : bonSortie
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_bon_sortie === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
