import { createSlice } from "@reduxjs/toolkit";
import {
  handleSelectAction,
  handleClearSelectedAction,
  getReceipt,
  addReceipt,
  deleteReceipt,
  updateReceipt,
} from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const receiptReducer = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReceipt.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getReceipt.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getReceipt.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addReceipt.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addReceipt.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addReceipt.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(deleteReceipt.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(deleteReceipt.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.filteredData = action.payload.newFilteredData;
        state.loadingAll = "succeeded";
      })
      .addCase(deleteReceipt.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updateReceipt.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updateReceipt.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updateReceipt.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

//Action creators are generated for each case reducer function
export const { handleSelect, handleClearSelected } = receiptReducer.actions;

export const selectReceipt = (state) => state.receipt;

export default receiptReducer.reducer;
