import { Box } from "@mui/material";
import { styled } from "@mui/system";

const CustomGrid = styled(Box)(({ gridTemplateColumns }) => ({
  display: "grid",
  gridTemplateColumns:
    gridTemplateColumns || "repeat(auto-fill, minmax(200px, 1fr))",
  gap: "16px", // Adjust gap between grid items
}));

export default CustomGrid;
