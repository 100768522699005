import React from "react";
import { BarChart, axisClasses } from "@mui/x-charts";
import { Box } from "@mui/material";

const chartSetting = {
  yAxis: [
    {
      label: "price rass zebi (mm)",
    },
  ],
  height: 400,

  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-20px, 0)",
    },
    p: "1rem",
  },
};

const CustomLineChart = ({}) => {
  const data = [
    { month: "Jan", prices: [100, 110, 120] },
    { month: "Feb", prices: [150, 160] },
    { month: "Mar", prices: [200] },
    { month: "Apr", prices: [250, 260, 270, 280] },
    // Add more data points as needed
  ];
  const allMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // Extract unique months
  const months = data.map((item) => item.month);

  // Prepare dataset with only the months present in the data
  // const dataset = data.map((item) => ({
  //   month: item.month,
  //   ...item.prices.reduce((acc, price, index) => {
  //     acc[`price${index + 1}`] = price;
  //     return acc;
  //   }, {}),
  // }));
  const dataset = allMonths.map((month) => {
    const existingData = data.find((item) => item.month === month);
    console.log("🚀 ~ dataset ~ existingData:", existingData);
    const prices = existingData ? existingData.prices : [];
    console.log("🚀 ~ dataset ~ prices:", prices);
    return { month, prices };
  });
  console.log("🚀 ~ dataset ~ dataset:", dataset);

  // Generate series dynamically
  const series = dataset?.map((price, index) => ({}));
  console.log("🚀 ~ series ~ series:", series);
  return <Box />;
  // <BarChart
  //   dataset={dataset}
  //   xAxis={[{ scaleType: "band", dataKey: "month" }]}
  //   series={series}
  //   width={800}
  //   height={400}
  //   {...chartSetting}
  // />
};

export default CustomLineChart;
