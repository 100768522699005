import useBonRetourFournisseur from "hooks/useBonRetourFournisseur";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import CustomDataGrid from "components/DataGrid/DataGrid";
import Header from "components/Header/Header";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/Button/CustomButton";
import CustomModal from "components/Modal/CustomModal";
import CustomSelect from "components/Input/CustomSelect";
import { updateBonCommandeFournisseur } from "store/BonCommandeFournisseur/actions";
import { DataGrid } from "@mui/x-data-grid";
import DownloadButton from "components/PDF/DownloadButton";
import PrintButton from "components/PDF/PrintButton";
import { useTranslation } from "react-i18next";
import {
  addBonRetourFournisseur,
  updateBonRetourFournisseur,
} from "store/BonRetourFournisseur/actions";

const BonRetourFournisseur = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    detailOpen,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleDetailClose,
    handleDetailOpenModal,
    handleDetailOpen,
    articles,
    setArticles,
    handleSearchChange,
  } = useBonRetourFournisseur();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [depot, setDepot] = useState(0);
  const [fournisseur, setFournisseur] = useState(0);
  const [code, setCode] = useState("");
  const [selectedBCF, setSelectedBCF] = useState(null);
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);

  useEffect(() => {
    if (selectedBCF) {
      setArticles(
        selectedBCF.list_article.map((article) => ({
          id: article.code_article,
          ...article,
          quantityInput: 0,
        }))
      );
    }
  }, [selectedBCF]);
  const handleQuantityChange = (id, value) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.code_article === id
          ? { ...article, quantityInput: value }
          : article
      )
    );
  };
  const fournisseurData = useSelector((state) => state.fournisseurReducer.data);
  const BCFData = useSelector(
    (state) => state.bonCommandeFournisseurReducer.data
  );
  const userData = useSelector((state) => state.userReducer.data);

  const transformedBonCommandeFournisseurData = BCFData.map((BCF) => ({
    code_bon_commande_fournisseur: BCF.code_bon_commande_fournisseur,
    code_fournisseur: BCF.code_fournisseur,
    list_article: JSON.parse(BCF.list_article),
    label: BCF.code_bon_commande_fournisseur,
  }));

  const defaultBCFOption = transformedBonCommandeFournisseurData.find(
    (option) =>
      option.code_bon_commande_fournisseur ===
      selected.code_bon_commande_fournisseur
  );

  const handleDepotChange = (event, selectedOption) => {
    setDepot(selectedOption);
  };
  const handleBCFChange = (event, selectedOption) => {
    setSelectedBCF(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      code_bon_commande_fournisseur: selectedBCF.code_bon_commande_fournisseur,
      list_article_retour: articles,
      etat: 1,
    };
    dispatch(addBonRetourFournisseur(postData));
    handleClose();
  };
  const handleUpdate = (e, bonRetourId) => {
    e.preventDefault();

    const selectedBRF = data.find((BRF) => BRF.code_bon_retour === bonRetourId);
    console.log("🚀 ~ handleUpdate ~ selectedBRF:", selectedBRF);

    const updatedData = {
      code_bon_commande_fournisseur:
        code !== "" ? code : selectedBRF.code_bon_commande_fournisseur,
      list_article_retour:
        articles !== "" ? articles : selected.list_article_retour,
      etat: 1,
    };
    // console.log("🚀 ~ handleUpdate ~ updatedData:", updatedData);
    dispatch(updateBonRetourFournisseur({ bonRetourId, updatedData }));
    handleUpdateClose();
  };

  const columns = [
    {
      field: "id",
      headerName: t("brf.code"),
      flex: 1,
    },
    {
      field: "BCF",
      headerName: t("brf.code"),
      flex: 1,
    },
    {
      field: "detail",
      headerName: t("brf.details"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <InfoOutlinedIcon /> : null}
          endIcon={language === "fr" ? <InfoOutlinedIcon /> : null}
          variant="contained"
          color={"success"}
          onClick={() => handleDetailOpenModal(params.id)}
        >
          {t("brf.details")}
        </Button>
      ),
    },
    {
      field: "modifier",
      headerName: t("brf.modifier"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={<CreateIcon />}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "PDF",
      headerName: t("bef.downloads"),
      flex: 0.6,
      renderCell: (params) => (
        <DownloadButton
          row={params.row}
          invoiceTitle={
            "REPUBLIQUE TUNISIENNE MINISTERE DE L'INTERIEUR COMMUNE DE JERBA MIDOUN"
          }
          bonTitle={"Bon Retour Fournisseur"}
          addressTitle={"Fournisseur: " + params.row.fournisseur}
          date={"date: " + params.row.date}
          quantite={"quantité in bon commande"}
          quantiteInput={"quantité retour"}
        />
      ),
    },
    {
      field: "Print",
      headerName: t("bef.imprimer"),
      flex: 0.5,
      renderCell: (params) => (
        <PrintButton
          row={params.row}
          invoiceTitle={
            "REPUBLIQUE TUNISIENNE MINISTERE DE L'INTERIEUR COMMUNE DE JERBA MIDOUN"
          }
          bonTitle={"Bon Retour Fournisseur"}
          addressTitle={"Fournisseur: " + params.row.fournisseur}
          date={"date: " + params.row.date}
          quantite={"quantité in bon commande"}
          quantiteInput={"quantité retour"}
        />
      ),
    },
  ];
  const columnsDetail = [
    {
      field: "UserC",
      headerName: t("brf.userc"),
      flex: 1,
    },
    {
      field: "DateC",
      headerName: t("brf.datecreation"),
      flex: 1,
    },
  ];
  const columnsListArticle = [
    {
      field: "designation",
      headerName: t("brf.designation"),
      flex: 1,
    },
    {
      field: "quantity",
      headerName: t("brf.quantite"),
      flex: 1,
    },
  ];

  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const bonCommande = BCFData.find(
        (bcf) =>
          bcf.code_bon_commande_fournisseur ===
          item.code_bon_commande_fournisseur
      );
      const fournisseur = fournisseurData.find(
        (four) => four?.code_fournisseur === bonCommande?.code_fournisseur
      );
      const dateCreate = new Date(item.date_create);
      const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return {
        id: item.code_bon_retour,
        BCF: item.code_bon_commande_fournisseur,
        fournisseur: fournisseur?.raison_sociale,
        etat: item.etat,
        list_article: JSON.parse(item.list_article_retour).map((item) => {
          return {
            id: item.code_article,
            designation: item.designation,
            quantity: item.quantity,
            quantityInput: item.quantityInput,
          };
        }),
        date: formattedDateCreate,
      };
    });
  }, [data, BCFData, fournisseurData]);
  const DetailData = useMemo(() => {
    // Extracting properties from the selected object
    const { code_user_create, date_create } = selected;
    const date = new Date(date_create);
    const user = userData.find((user) => {
      return code_user_create == user.id;
    });
    const formattedDate = date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return [
      {
        id: selected.code_bon_retour,
        UserC: user?.nom + " " + user?.prenom,
        DateC: formattedDate,
      },
    ];
  }, [selected, userData]);
  const DetailListArticle = useMemo(() => {
    // Extracting properties from the selected object
    const { list_article_retour } = selected;
    const dataArray = JSON.parse(list_article_retour || "[]");
    return dataArray.map((item) => {
      return {
        id: item.code_article,
        designation: item.designation,
        quantity: item.quantityInput,
      };
    });
  }, [selected]);

  const createBAButton = (
    <CustomButton
      children={t("brf.createbrf")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={t("brf.list")}
        search={handleSearchChange}
        button={createBAButton}
      />
      <CustomDataGrid
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.code_bon_avoir}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {t("brf.edit")}
              </Typography>
              <CustomSelect
                defaultValue={defaultBCFOption}
                onChange={handleBCFChange}
                label={t("bcf.code")}
                options={transformedBonCommandeFournisseurData}
              />

              {selectedBCF && (
                <Box sx={{ width: "100%", mt: 2 }}>
                  <Typography variant="h6" mb={2}>
                    {t("brf.artbcf")}
                  </Typography>
                  {articles.length > 0 ? (
                    <Box sx={{ width: "100%" }}>
                      <DataGrid
                        rows={articles}
                        columns={[
                          {
                            field: "designation",
                            headerName: t("brf.designation"),
                            flex: 1,
                          },
                          {
                            field: "quantity",
                            headerName: t("brf.quantite"),
                            flex: 1,
                          },
                          {
                            field: "quantityInput",
                            headerName: t("brf.quantiter"),
                            flex: 1,
                            renderCell: (params) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={params.row.quantityInput}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      params.row.code_article,
                                      e.target.value
                                    )
                                  }
                                />
                              </Box>
                            ),
                          },
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1">{t("brf.listvide")}</Typography>
                  )}
                </Box>
              )}

              <CustomButton
                onClick={(e) => handleUpdate(e, selected.code_bon_retour)}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("brf.modifier")}
              />
            </form>
          }
        />
      )}
      {detailOpen && (
        <CustomModal
          onClose={handleDetailClose}
          open={detailOpen}
          children={
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "75vh",
                overflowY: "auto",
                gap: 10,
              }}
            >
              <Typography variant="h3" mt={"1rem"}>
                {t("brf.detail")} :
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailData}
                columns={columnsDetail}
                sx={{ "& .MuiDataGrid-footerContainer": { display: "none" } }}
              />
              <Typography variant="h3" mt={"1rem"}>
                {t("brf.listart")} :
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailListArticle}
                columns={columnsListArticle}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
              />
            </Box>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {t("brf.createbrf")}
              </Typography>
              <CustomSelect
                onChange={handleBCFChange}
                label={t("bcf.code")}
                options={transformedBonCommandeFournisseurData}
              />
              {selectedBCF && (
                <Box sx={{ width: "100%", mt: 2 }}>
                  <Typography variant="h6" mb={2}>
                    {t("brf.artbcf")}
                  </Typography>
                  {articles.length > 0 ? (
                    <Box sx={{ width: "100%" }}>
                      <DataGrid
                        rows={articles}
                        columns={[
                          {
                            field: "designation",
                            headerName: t("brf.designation"),
                            flex: 1,
                          },
                          {
                            field: "quantity",
                            headerName: t("brf.quantite"),
                            flex: 1,
                          },
                          {
                            field: "quantityInput",
                            headerName: t("brf.quantiter"),
                            flex: 1,
                            renderCell: (params) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={params.row.quantityInput}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      params.row.code_article,
                                      e.target.value
                                    )
                                  }
                                />
                              </Box>
                            ),
                          },
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1">{t("brf.listvide")}</Typography>
                  )}
                </Box>
              )}

              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("frs.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default BonRetourFournisseur;
