import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const CustomSelect = ({ options, label, onChange, defaultValue }) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      getOptionLabel={(option) => option.label}
      fullWidth
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default CustomSelect;
