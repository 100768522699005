import { Box, useTheme } from "@mui/material";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const CustomDataGrid = ({
  loading,
  dataWithButtons,
  columns,
  sx,
  initialState,
  height = "75vh",
}) => {
  const theme = useTheme();
  const CustomDirection = theme.direction;

  return (
    <Box
      backgroundColor={theme.palette.background.alt}
      borderRadius={"0.55rem"}
      width={"100%"}
      height={height}
    >
      <DataGrid
        loading={loading}
        rows={dataWithButtons || []}
        columns={columns}
        getRowId={(row) => row.id}
        initialState={initialState}
        sx={{
          "& .MuiDataGrid-cell": {
            textAlign: CustomDirection === "ltr" ? "left" : "right",
          },
        }}
      />
    </Box>
  );
};

export default CustomDataGrid;
