import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
function FrenchFlagIcon(props) {
  return (
    <SvgIcon {...props} >
      <path fill="#0055A4" d="M0 0h8v16H0z" />
      <path fill="#FFFFFF" d="M8 0h8v16H8z" />
      <path fill="#EF4135" d="M16 0h8v16h-8z" />
    </SvgIcon>
  );
}
export default FrenchFlagIcon;