import { Box, Button, Typography, useTheme } from "@mui/material";
import CustomButton from "components/Button/CustomButton";
import Header from "components/Header/Header";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFamille from "hooks/useFamille";
//icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomModal from "components/Modal/CustomModal";
// import CustomTypographie from "components/Typographie/CustomTypographie";
import CustomInput from "components/Input/CustomInput";
import {
  addFamille,
  deleteFamille,
  updateFamille,
} from "store/famille/actions";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";

const Famille = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useFamille();

  const categorieData = useSelector((state) => state.categorieReducer.data);

  const transformedData = categorieData.map((item) => ({
    label: item.designation,
    code_categorie: item.code_categorie,
  }));
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [selectedCategorie, setSelectedCategorie] = useState(0);
  const [updatedInputValue, setUpdatedInputValue] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const language = useSelector((state) => state.global.language);
  const defaultOption = transformedData.find(
    (option) => option.code_categorie === selected.code_categorie
  );
  const handleInputChange = (data) => {
    setInputValue(data);
  };
  const handleCategorieChange = (event, selectedOption) => {
    setSelectedCategorie(selectedOption);
  };
  const handleUpdateInputChange = (data) => {
    setUpdatedInputValue(data);
  };
  const handleUpdate = (e, familleId) => {
    e.preventDefault();
    const selectedFamilly = data.find(
      (familly) => familly.code_famille === familleId
    );
    const updatedData = {
      designation:
        updatedInputValue !== ""
          ? updatedInputValue
          : selectedFamilly.designation,
      code_categorie:
        selectedCategorie !== 0
          ? selectedCategorie.code_categorie
          : selectedFamilly.code_categorie,
      etat: 1,
    };
    dispatch(updateFamille({ familleId, updatedData }));
    handleUpdateClose();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      designation: inputValue,
      code_categorie: selectedCategorie.code_categorie,
      etat: 1,
    };
    dispatch(addFamille(postData));
    handleClose();
  };
  const handleDelete = async (familleId) => {
    dispatch(deleteFamille(familleId));
  };

  //************  GRID DATA ***********\\
  const columns = [
    {
      field: "designation",
      headerName: t("famille.designation"),
      flex: 1,
    },
    {
      field: "categorie",
      headerName: t("famille.categorie"),
      flex: 1,
    },
    {
      field: "etat",
      headerName: t("famille.etat"),
      flex: 1,
    },
    {
      field: "modifier",
      headerName: t("famille.modifier"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("famille.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("famille.supprimer"),
      flex: 0.5,
      renderCell: (params) => (
        <CustomButton
          children={t("famille.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const category = categorieData.find(
        (cat) => cat.code_categorie === item.code_categorie
      );
      const categorieDesignation = category ? category.designation : "";
      return {
        id: item.code_famille,
        designation: item.designation,
        categorie: categorieDesignation,
        etat: item.etat === 1 ? "actif" : "inActif",
      };
    });
  }, [data, categorieData]);
  const createFamilleButton = (
    <CustomButton
      children={t("famille.creerfamille")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  //************ END GRID DATA ***********\\
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={t("famille.listfamille")}
        search={handleSearchChange}
        button={createFamilleButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.code_famille}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("famille.editfamille")}
              </Typography>
              <CustomInput
                value={selected.designation}
                label={t("famille.designation")}
                onChange={handleUpdateInputChange}
              />
              <CustomSelect
                defaultValue={defaultOption}
                onChange={handleCategorieChange}
                label={t("famille.categorie")}
                options={transformedData}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.code_famille)}
                size={"large"}
                variant={"contained"}
                children={t("famille.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("famille.creerfamille")}
              </Typography>
              <CustomInput
                value={inputValue}
                label={t("famille.designation")}
                onChange={handleInputChange}
              />
              <CustomSelect
                onChange={handleCategorieChange}
                label={t("famille.categorie")}
                options={transformedData}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("famille.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Famille;
