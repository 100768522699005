import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCategories = createAsyncThunk(
  "GET-CATEGORIES",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/categorie/allCat", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addCategorie = createAsyncThunk(
  "ADD-CATEGORIE",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const categorieState = getState().categorieReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/categorie/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      return [{ ...response.data }, ...categorieState];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteCategorie = createAsyncThunk(
  "DELETE-CATEGORIE",
  async (params, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().categorieReducer.data;
      const prevFilteredData = getState().categorieReducer.filteredData;
      let newData = [];
      let newFilteredData = [];

      await axios.delete(`https://www.gbgc.tn/categorie/delete/${params}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      newData = [...prevData.filter((item) => item.code_categorie !== params)];
      newFilteredData = [
        ...prevFilteredData.filter((item) => item.code_categorie !== params),
      ];

      return { newData, newFilteredData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateCategorie = createAsyncThunk(
  "UPDATE-CATEGORIE",
  async ({ categoryId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().categorieReducer.data;

      // Assuming your backend API supports updating a category by its ID
      const response = await axios.put(
        `https://www.gbgc.tn/categorie/update/${categoryId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      // Assuming your backend returns the updated category data
      const updatedCategory = response.data;

      // Replace the old category data with the updated one
      const newData = prevData.map((category) =>
        category.code_categorie === categoryId ? updatedCategory : category
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_categorie === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
