import React, { useMemo, useState } from "react";
import useArticle from "hooks/useArticle";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomButton from "components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomModal from "components/Modal/CustomModal";

import CustomInput from "components/Input/CustomInput";
import CustomGrid from "components/Grid/CustomGrid";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";
import useConfined from "hooks/useConfined";
import { deleteConfined } from "store/confined/actions";
import useAgentConfined from "hooks/useAgentConfined";
import {
  addAgentConfined,
  deleteAgentConfined,
  updateAgentConfined,
} from "store/agentConfined/actions";

const AgentConfined = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useAgentConfined();
  const { t } = useTranslation();
  const [uid, setUID] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [telephone, setTelephone] = useState(0);

  const language = useSelector((state) => state.global.language);

  const dispatch = useDispatch();

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };
  const handleUIDChange = handleChange(setUID);
  const handleNomChange = handleChange(setNom);
  const handlePrenomChange = handleChange(setPrenom);
  const handleTelephonehange = handleChange(setTelephone);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      uid: uid,
      nom: nom,
      prenom: prenom,
      telephone: telephone,
    };
    dispatch(addAgentConfined(postData));
    handleClose();
  };
  const handleUpdate = (e, agentConfinedId) => {
    e.preventDefault();

    const updatedData = {
      nom: nom !== "" ? nom : selected.nom,
      prenom: prenom !== "" ? prenom : selected.prenom,
      telephone: telephone !== 0 ? telephone : selected.telephone,
    };
    dispatch(updateAgentConfined({ agentConfinedId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (confinedId) => {
    dispatch(deleteAgentConfined(confinedId));
  };
  const columns = [
    {
      field: "id",
      headerName: "uid",
      flex: 0.4,
    },
    {
      field: "nom",
      headerName: "nom",
      flex: 0.4,
    },
    {
      field: "prenom",
      headerName: "prenom",
      flex: 0.4,
    },
    {
      field: "telephone",
      headerName: "telephone",
      flex: 0.4,
    },

    {
      field: "modifier",
      headerName: t("articles.modifier"),
      flex: 0.3,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("articles.supprimer"),
      flex: 0.3,
      renderCell: (params) => (
        <CustomButton
          children={t("famille.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const dateCreate = new Date(item.date);
      const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return {
        id: item.uid,
        nom: item.nom,
        prenom: item.prenom,
        telephone: item.telephone,
      };
    });
  }, [data]);
  const createConfinedButton = (
    <CustomButton
      children={t("articles.creerarticle")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );

  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste des agents confinées"}
        search={handleSearchChange}
        button={createConfinedButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.uid}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {"modifier un agent confined"}
              </Typography>

              <CustomInput
                value={selected.nom}
                label={"nom"}
                onChange={handleNomChange}
              />
              <CustomInput
                value={selected.prenom}
                label={"prenom"}
                onChange={handlePrenomChange}
              />
              <CustomInput
                value={selected.telephone}
                label={"telephone"}
                onChange={handleTelephonehange}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.uid)}
                size={"large"}
                variant={"contained"}
                children={t("cat.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("articles.creerarticle")}
              </Typography>
              <CustomInput
                value={uid}
                label={"uid"}
                onChange={handleUIDChange}
              />
              <CustomInput
                value={nom}
                label={"nom"}
                onChange={handleNomChange}
              />
              <CustomInput
                value={prenom}
                label={"prenom"}
                onChange={handlePrenomChange}
              />
              <CustomInput
                value={telephone}
                label={"telephone"}
                onChange={handleTelephonehange}
              />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("articles.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default AgentConfined;
