import { Text, View } from "@react-pdf/renderer";
import React, { Fragment } from "react";

const List = ({ data, styles }) => {
  return (
    data.detail !== undefined &&
    data.detail?.map((item, index) => (
      <View style={styles.textRightList}>
        <Text>
          {item.item}
          {"("}
          {index + 1}
        </Text>
      </View>
    ))
  );
};

export default List;
