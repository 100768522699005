import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuota } from "store/quota/actions";
import { handleSelect } from "store/quota/slice";

const useQuota = () => {
  const { data, loadingAll, loading, selected, error } = useSelector(
    (state) => state.quotaReducer
  );
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUpdateOpen = () => setUpdateOpen(true);
  const handleUpdateClose = () => setUpdateOpen(false);
  const dispatch = useDispatch();

  const fetchQuota = useCallback(async () => {
    dispatch(getQuota());
  }, [dispatch]);

  useEffect(() => {
    fetchQuota();
  }, [dispatch, fetchQuota]);

  const _handleSelectOne = (key) => {
    dispatch(handleSelect({ key: key }));
  };
  const handleOpenModal = (key) => {
    _handleSelectOne(key);
    handleUpdateOpen();
  };
  return {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    setOpen,
    open,
    updateOpen,
    setUpdateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
  };
};

export default useQuota;
