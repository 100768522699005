import { createSlice } from "@reduxjs/toolkit";
import {
  handleSelectAction,
  handleClearSelectedAction,
  getBonCommandeFournisseur,
  addBonCommandeFournisseur,
  updateBonCommandeFournisseur,
} from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const bonCommandeFournisseurReducer = createSlice({
  name: "bonCommandeFournisseur",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBonCommandeFournisseur.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getBonCommandeFournisseur.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getBonCommandeFournisseur.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addBonCommandeFournisseur.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addBonCommandeFournisseur.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addBonCommandeFournisseur.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updateBonCommandeFournisseur.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updateBonCommandeFournisseur.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updateBonCommandeFournisseur.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

//Action creators are generated for each case reducer function
export const { handleSelect, handleClearSelected } =
  bonCommandeFournisseurReducer.actions;

export const selectBonCommandeFournisseur = (state) =>
  state.bonCommandeFournisseur;

export default bonCommandeFournisseurReducer.reducer;
