import useBonRetourInterne from "hooks/useBonRetourInterne";
import {
  Box,
  Button,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import CustomDataGrid from "components/DataGrid/DataGrid";
import Header from "components/Header/Header";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/Button/CustomButton";

import CustomModal from "components/Modal/CustomModal";

import CustomSelect from "components/Input/CustomSelect";
import { updateBonCommandeFournisseur } from "store/BonCommandeFournisseur/actions";
import useBonEntre from "hooks/useBonEntre";
import { DataGrid } from "@mui/x-data-grid";
import { addBonEntre } from "store/BonEntre/actions";
import DownloadButton from "components/PDF/DownloadButton";
import PrintButton from "components/PDF/PrintButton";
import {
  addBonRetourInterne,
  updateBonRetourInterne,
} from "store/bonRetourInterne/actions";
import { useTranslation } from "react-i18next";

const BonRetourInterne = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    detailOpen,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleDetailClose,
    handleDetailOpenModal,
    handleSearchChange,
    handleDetailOpen,
    articles,
    setArticles,
  } = useBonRetourInterne();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [depot, setDepot] = useState(0);
  const [fournisseur, setFournisseur] = useState(0);
  const [code, setCode] = useState("");
  const [selectedBS, setSelectedBS] = useState(null);
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);

  useEffect(() => {
    if (selectedBS) {
      setArticles(
        selectedBS.list_article.map((article) => ({
          id: article.code_article,
          ...article,
          quantityInput: 0,
        }))
      );
    }
  }, [selectedBS]);
  const handleQuantityChange = (id, value) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.code_article === id
          ? { ...article, quantityInput: value }
          : article
      )
    );
  };
  const BSData = useSelector((state) => state.bonSortieReducer.data);
  const userData = useSelector((state) => state.userReducer.data);
  const siteData = useSelector((state) => state.siteReducer.data);

  const transformedBonSortieData = BSData.map((BS) => ({
    code_bon_sortie: BS.code_bon_sortie,
    list_article: JSON.parse(BS.list_article),
    label: BS.code_bon_sortie,
  }));

  const handleBSChange = (event, selectedOption) => {
    setSelectedBS(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      code_bon_sortie: selectedBS.code_bon_sortie,
      list_article_livree: articles,
      etat: 1,
    };
    dispatch(addBonRetourInterne(postData));
    handleClose();
  };
  const handleUpdate = (e, bonRetourInterneId) => {
    e.preventDefault();

    const selectedBRI = data.find(
      (BRI) => BRI.code_bon_retour_interne === bonRetourInterneId
    );
    console.log("🚀 ~ handleUpdate ~ selectedBRI:", selectedBRI);

    const updatedData = {
      code_bon_sortie:
        selectedBS !== ""
          ? selectedBS.code_bon_sortie
          : selected.code_bon_sortie,
      list_article: articles !== "" ? articles : selected.list_article,
      site: selected.nom_department,
      etat: 1,
    };
    // console.log("🚀 ~ handleUpdate ~ updatedData:", updatedData);
    dispatch(updateBonRetourInterne({ bonRetourInterneId, updatedData }));
    handleUpdateClose();
  };

  const columns = [
    {
      field: "id",
      headerName: t("bri.code"),
      flex: 1,
    },
    {
      field: "BS",
      headerName: t("bsi.code"),
      flex: 1,
    },
    {
      field: "detail",
      headerName: t("brf.details"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <InfoOutlinedIcon /> : null}
          endIcon={language === "fr" ? <InfoOutlinedIcon /> : null}
          variant="contained"
          color={"success"}
          onClick={() => handleDetailOpenModal(params.id)}
        >
          {t("brf.details")}
        </Button>
      ),
    },
    {
      field: "modifier",
      headerName: t("brf.modifier"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "PDF",
      headerName: t("bef.downloads"),
      flex: 0.6,
      renderCell: (params) => (
        <DownloadButton
          row={params.row}
          invoiceTitle={
            "REPUBLIQUE TUNISIENNE MINISTERE DE L'INTERIEUR COMMUNE DE JERBA MIDOUN"
          }
          date={"date: " + params.row.date}
          bonTitle={"Bon Retour Interne"}
          addressTitle={"service retour: " + params.row.Site}
          quantite={"quantité in bon sortie"}
          quantiteInput={"quantité retour"}
        />
      ),
    },
    {
      field: "Print",
      headerName: t("bef.imprimer"),
      flex: 0.5,
      renderCell: (params) => (
        <PrintButton
          row={params.row}
          invoiceTitle={
            "REPUBLIQUE TUNISIENNE MINISTERE DE L'INTERIEUR COMMUNE DE JERBA MIDOUN"
          }
          bonTitle={"Bon Retour Interne"}
          date={"date: " + params.row.date}
          addressTitle={"service retour: " + params.row.Site}
          quantite={"quantité in bon sortie"}
          quantiteInput={"quantité retour"}
        />
      ),
    },
  ];
  const columnsDetail = [
    {
      field: "UserC",
      headerName: t("brf.userc"),
      flex: 1,
    },
    {
      field: "DateC",
      headerName: t("brf.datecreation"),
      flex: 1,
    },
  ];
  const columnsListArticle = [
    {
      field: "designation",
      headerName: t("brf.designation"),
      flex: 1,
    },
    {
      field: "quantity",
      headerName: t("brf.quantite"),
      flex: 1,
    },
  ];

  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const dateCreate = new Date(item.date_create);
      const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const site = siteData.find(
        (site) => site.code_site == item.nom_department
      );
      return {
        id: item.code_bon_retour_interne,
        BS: item.code_bon_sortie,
        Site: site?.designation,
        etat: item.etat,
        list_article: JSON.parse(item.list_article).map((item) => {
          return {
            id: item.code_article,
            designation: item.designation,
            quantity: item.quantity,
            quantityInput: item.quantityInput,
          };
        }),
        date: formattedDateCreate,
      };
    });
  }, [data, siteData]);
  const DetailData = useMemo(() => {
    // Extracting properties from the selected object
    const { code_user_create, date_create } = selected;
    const date = new Date(date_create);
    const user = userData.find((user) => {
      return code_user_create == user.id;
    });
    const formattedDate = date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return [
      {
        id: selected.code_bon_retour_interne,
        UserC: user?.nom + " " + user?.prenom,
        DateC: formattedDate,
      },
    ];
  }, [selected, userData]);
  const DetailListArticle = useMemo(() => {
    // Extracting properties from the selected object
    const { list_article } = selected;
    const dataArray = JSON.parse(list_article || "[]");
    return dataArray.map((item) => {
      return {
        id: item.code_article,
        designation: item.designation,
        quantity: item.quantityInput,
      };
    });
  }, [selected]);

  const createBAButton = (
    <CustomButton
      children={t("bri.creerbr")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header title={t("bri.list")} button={createBAButton} search={handleSearchChange} />
      <CustomDataGrid
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.code_bon_retour_interne}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {t("bri.edit")}
              </Typography>
              <CustomSelect
                onChange={handleBSChange}
                label={t("bsi.code")}
                options={transformedBonSortieData}
              />

              {selectedBS && (
                <Box sx={{ width: "100%", mt: 2 }}>
                  <Typography variant="h6" mb={2}>
                    {t("bri.listartbr")}
                  </Typography>
                  {articles.length > 0 ? (
                    <Box sx={{ width: "100%" }}>
                      <DataGrid
                        rows={articles}
                        columns={[
                          {
                            field: "designation",
                            headerName: t("brf.designation"),
                            flex: 1,
                          },
                          {
                            field: "quantity",
                            headerName: t("brf.quantite"),
                            flex: 1,
                          },
                          {
                            field: "quantityInput",
                            headerName: t("brf.quantiter"),
                            flex: 1,
                            renderCell: (params) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={params.row.quantityInput}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      params.row.code_article,
                                      parseInt(e.target.value)
                                    )
                                  }
                                />
                              </Box>
                            ),
                          },
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1">{t("brf.listvide")}</Typography>
                  )}
                </Box>
              )}
              <CustomButton
                onClick={(e) =>
                  handleUpdate(e, selected.code_bon_retour_interne)
                }
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("brf.modifier")}
              />
            </form>
          }
        />
      )}
      {detailOpen && (
        <CustomModal
          onClose={handleDetailClose}
          open={detailOpen}
          children={
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "75vh",
                overflowY: "auto",
                gap: 10,
              }}
            >
              <Typography variant="h3" mt={"1rem"}>
                {t("bri.detail")} :
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailData}
                columns={columnsDetail}
                sx={{ "& .MuiDataGrid-footerContainer": { display: "none" } }}
              />
              <Typography variant="h3" mt={"1rem"}>
                {t("bri.listartbr")}:
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailListArticle}
                columns={columnsListArticle}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
              />
            </Box>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {t("bri.creerbr")}
              </Typography>
              <CustomSelect
                onChange={handleBSChange}
                label={t("bsi.code")}
                options={transformedBonSortieData}
              />

              {selectedBS && (
                <Box sx={{ width: "100%", mt: 2 }}>
                  <Typography variant="h6" mb={2}>
                    {t("bsi.listartbc")}
                  </Typography>
                  {articles.length > 0 ? (
                    <Box sx={{ width: "100%" }}>
                      <DataGrid
                        rows={articles}
                        columns={[
                          {
                            field: "designation",
                            headerName: t("brf.designation"),
                            flex: 1,
                          },
                          {
                            field: "quantity",
                            headerName: t("brf.quantite"),
                            flex: 1,
                          },
                          {
                            field: "quantityInput",
                            headerName: t("brf.quantiter"),
                            flex: 1,
                            renderCell: (params) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={params.row.quantityInput}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      params.row.code_article,
                                      parseInt(e.target.value)
                                    )
                                  }
                                />
                              </Box>
                            ),
                          },
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </Box>
                  ) : (
                    <Typography variant="body1">{t("baf.null")}.</Typography>
                  )}
                </Box>
              )}

              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("frs.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default BonRetourInterne;
