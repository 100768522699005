import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import CustomDataGrid from "components/DataGrid/DataGrid";
import Header from "components/Header/Header";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/Button/CustomButton";
import useBonCommandeFournisseur from "hooks/useBonCommandeFournisseur";
import CustomModal from "components/Modal/CustomModal";
import CustomInput from "components/Input/CustomInput";
import { DeleteForeverSharp } from "@mui/icons-material";
import CustomSelect from "components/Input/CustomSelect";
import {
  addBonCommandeFournisseur,
  updateBonCommandeFournisseur,
} from "store/BonCommandeFournisseur/actions";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BonCommandeFournisseur = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    detailOpen,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleDetailClose,
    handleDetailOpenModal,
    handleDetailOpen,
    cart,
    setCart,
    handleSearchChange,
  } = useBonCommandeFournisseur();

  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [depot, setDepot] = useState(0);
  const [fournisseur, setFournisseur] = useState(0);
  const [code, setCode] = useState("");
  const [image, setImage] = useState(null);
  const [nombre, setNombre] = useState(0);
  const [fileName, setFileName] = useState("");
  const { t } = useTranslation();
  const language = useSelector((state) => state.global.language);

  useEffect(() => {
    if (updateOpen && selected.list_article !== undefined) {
      try {
        const parsedCart = JSON.parse(selected.list_article);
        setCart(parsedCart);
      } catch (error) {
        setCart([]);
      }
    }
  }, [updateOpen, selected.list_article]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      try {
        const base64String = await convertFileToBase64(file);
        setImage(base64String);
      } catch (error) {
        console.error("Error converting image to base64:", error);
      }
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const userData = useSelector((state) => state.userReducer.data);
  const depotData = useSelector((state) => state.depotReducer.data);
  const fournisseurData = useSelector((state) => state.fournisseurReducer.data);
  const articleData = useSelector((state) => state.articleReducer.data);
  const transformedArticleData = articleData.map((product) => ({
    code_article: product.code_article,
    label: product.designation,
  }));
  const transformedFournisseurData = fournisseurData.map((fournisseur) => ({
    code_fournisseur: fournisseur.code_fournisseur,
    label: fournisseur.raison_sociale,
  }));
  const transformedDepotData = depotData.map((depot) => ({
    code_depot: depot.code_depot,
    label: depot.designation,
  }));
  const defaultDepotOption = transformedDepotData.find(
    (option) => option.code_depot === selected.code_depot
  );
  const defaultFournisseurOption = transformedFournisseurData.find(
    (option) => option.code_fournisseur === selected.code_fournisseur
  );

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };
  const handleCodeChange = handleChange(setCode);
  const handleNombreChange = handleChange(setNombre);

  const handleDepotChange = (event, selectedOption) => {
    setDepot(selectedOption);
  };
  const handleFournisseurChange = (event, selectedOption) => {
    setFournisseur(selectedOption);
  };
  const handleProductChange = (event, selectedOption) => {
    setSelectedProduct(selectedOption);
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value));
  };
  const handlePriceChange = (event) => {
    setPrice(parseInt(event.target.value));
  };

  const addToCart = () => {
    if (selectedProduct) {
      const { code_article, label: designation } = selectedProduct;
      const newItem = {
        code_article,
        designation,
        quantity,
        prix_actuel: price,
      };
      setCart([...cart, newItem]);
      setSelectedProduct(null);
      setQuantity(1);
      setPrice(0);
    }
  };

  const handleRemoveItem = (item) => {
    setCart(
      cart.filter((cartItem) => cartItem.code_article !== item.code_article)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      code: code,
      nombre: nombre,
      code_depot: depot.code_depot,
      code_fournisseur: fournisseur.code_fournisseur,
      list_article: cart,
      etat: 1,
      image_data: image,
    };
    dispatch(addBonCommandeFournisseur(postData));
    handleClose();
  };
  const handleUpdate = (e, bonCommandeFournisseurId) => {
    e.preventDefault();

    const selectedBCF = data.find(
      (BCF) => BCF.code_bon_commande_fournisseur === bonCommandeFournisseurId
    );

    const updatedData = {
      code_bon_commande_fournisseur:
        code !== "" ? code : selectedBCF.code_bon_commande_fournisseur,
      nombre: nombre !== 0 ? nombre : selectedBCF.nombre,
      code_depot: depot !== 0 ? depot.code_depot : selectedBCF.code_depot,
      code_fournisseur:
        fournisseur !== 0
          ? fournisseur.code_fournisseur
          : selectedBCF.code_fournisseur,
      list_article: cart !== "" ? cart : selectedBCF.list_article,
      image_data: image !== "" ? image : selectedBCF.image_data,
      etat: 1,
    };
    dispatch(
      updateBonCommandeFournisseur({ bonCommandeFournisseurId, updatedData })
    );
    handleUpdateClose();
  };

  const columns = [
    {
      field: "id",
      headerName: t("bcf.code"),
      flex: 1,
    },
    {
      field: "nombre",
      headerName: t("bcf.numadab"),
      flex: 1,
    },
    {
      field: "depot",
      headerName: t("bcf.depot"),
      flex: 1,
    },
    {
      field: "fournisseur",
      headerName: t("bcf.fournisseur"),
      flex: 1,
    },
    {
      field: "detail",
      headerName: t("bcf.details"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <InfoOutlinedIcon /> : null}
          endIcon={language === "fr" ? <InfoOutlinedIcon /> : null}
          variant="contained"
          color={"success"}
          onClick={() => handleDetailOpenModal(params.id)}
        >
          {t("bcf.details")}
        </Button>
      ),
    },
    {
      field: "modifier",
      headerName: t("bcf.modifier"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("bcf.modifier")}
        </Button>
      ),
    },
  ];
  const columnsDetail = [
    {
      field: "UserC",
      headerName: t("bcf.userc"),
      flex: 1,
    },
    {
      field: "DateC",
      headerName: t("bcf.datecreation"),
      flex: 1,
    },
    {
      field: "UserV",
      headerName: t("bcf.userv"),
      flex: 1,
    },
    {
      field: "DateV",
      headerName: t("bcf.datevalidation"),
      flex: 1,
    },
  ];
  const columnsListArticle = [
    {
      field: "designation",
      headerName: t("bcf.designation"),
      flex: 1,
    },
    {
      field: "quantity",
      headerName: t("bcf.quantite"),
      flex: 1,
    },
    {
      field: "prix",
      headerName: t("bcf.prixact"),
      flex: 1,
    },
  ];

  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const depot = depotData.find((dep) => dep.code_depot === item.code_depot);
      const depotDesignation = depot ? depot.designation : "";
      const fournisseur = fournisseurData.find(
        (fournisseur) => fournisseur.code_fournisseur === item.code_fournisseur
      );
      const fournisseurRaisonSociale = fournisseur
        ? fournisseur.raison_sociale
        : "";
      return {
        id: item.code_bon_commande_fournisseur,
        depot: depotDesignation,
        fournisseur: fournisseurRaisonSociale,
        nombre: item.nombre,
        etat: item.etat,
      };
    });
  }, [data]);
  const DetailData = useMemo(() => {
    // Extracting properties from the selected object
    const {
      code_user_create,
      code_user_validate,
      date_creation,
      date_validation,
    } = selected;
    const dateCreate = new Date(date_creation);
    const dateValidate = new Date(date_validation);
    const userCreate = userData.find((user) => {
      return code_user_create == user.id;
    });
    const userValidate = userData.find((user) => {
      return code_user_validate == user.id;
    });
    const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const formattedDateValidate = dateValidate.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return [
      {
        id: selected.code_bon_commande_fournisseur,
        UserC: userCreate?.nom + " " + userCreate?.prenom,
        UserV: userValidate?.nom + " " + userValidate?.prenom,
        DateC: formattedDateCreate,
        DateV: formattedDateValidate,
      },
    ];
  }, [selected]);
  const DetailListArticle = useMemo(() => {
    // Extracting properties from the selected object
    const { list_article } = selected;
    const dataArray = JSON.parse(list_article || "[]");
    return dataArray.map((item) => {
      return {
        id: item.code_article,
        designation: item.designation,
        quantity: item.quantity,
        prix: item.prix_actuel,
      };
    });
  }, [selected]);

  const createBCFButton = (
    <CustomButton
      children={t("bcf.createbcf")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );
  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={t("bcf.list")}
        search={handleSearchChange}
        button={createBCFButton}
      />
      <CustomDataGrid
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.code_bon_commande_fournisseur}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {t("bcf.edit")}
              </Typography>
              <CustomInput
                value={selected.code_bon_commande_fournisseur}
                label={t("bcf.code")}
                onChange={handleCodeChange}
              />
              <CustomInput
                label={t("bcf.numadab")}
                value={selected.nombre}
                onChange={handleNombreChange}
              />
              <CustomSelect
                defaultValue={defaultFournisseurOption}
                onChange={handleFournisseurChange}
                label={t("bcf.fournisseur")}
                options={transformedFournisseurData}
              />
              <CustomSelect
                defaultValue={defaultDepotOption}
                onChange={handleDepotChange}
                label={t("bcf.depot")}
                options={transformedDepotData}
              />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={language === "ar" ? <CloudUploadIcon /> : null}
                endIcon={language === "fr" ? <CloudUploadIcon /> : null}
              >
                <VisuallyHiddenInput type="file" onChange={handleImageChange} />
              </Button>
              {fileName && (
                <p>
                  {t("bcf.simg")} : {fileName}
                </p>
              )}
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" mb={"1rem"}>
                    {t("bcf.prs")}
                  </Typography>
                  <CustomSelect
                    onChange={handleProductChange}
                    label={t("bcf.listart")}
                    options={transformedArticleData}
                    value={
                      selectedProduct ? selectedProduct.code_article : null
                    }
                  />

                  {selectedProduct && (
                    <Fragment>
                      <TextField
                        label={t("bcf.quantite")}
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 1 }}
                      />
                      <TextField
                        label={t("bcf.prixact")}
                        type="number"
                        value={price}
                        onChange={handlePriceChange}
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 1 }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={addToCart}
                        disabled={!selectedProduct}
                      >
                        {t("bcf.add")}
                      </Button>
                    </Fragment>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" m={"1rem 0"}>
                    {t("bcf.listart")}
                  </Typography>
                  {cart.length === 0 ? (
                    <p>{t("bcf.listvide")}</p>
                  ) : (
                    <List>
                      {cart.map((item) => (
                        <ListItem
                          key={item.code_article}
                          secondaryAction={
                            <IconButton
                              onClick={() => handleRemoveItem(item)}
                              edge="end"
                            >
                              <DeleteForeverSharp />
                            </IconButton>
                          }
                        >
                          <ListItemText
                            primary={item.designation}
                            secondary={`${t("bcf.quantite")} : ${item.quantity} 
                            - ${t("bcf.prixact")} : ${item.prix_actuel}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
              <CustomButton
                onClick={(e) =>
                  handleUpdate(e, selected.code_bon_commande_fournisseur)
                }
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("frs.modifier")}
              />
            </form>
          }
        />
      )}
      {detailOpen && (
        <CustomModal
          onClose={handleDetailClose}
          open={detailOpen}
          children={
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "75vh",
                overflowY: "auto",
                gap: 10,
              }}
            >
              <Typography variant="h3" mt={"1rem"}>
                {t("bcf.detail")}:
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailData}
                columns={columnsDetail}
                sx={{ "& .MuiDataGrid-footerContainer": { display: "none" } }}
              />
              <Typography variant="h3" mt={"1rem"}>
                {t("bcf.listart")} :
              </Typography>
              <CustomDataGrid
                dataWithButtons={DetailListArticle}
                columns={columnsListArticle}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
              />
            </Box>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                maxHeight: "75vh",
                overflowY: "auto",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Typography variant="h2" mb={"1rem"}>
                {t("bcf.createbcf")}
              </Typography>
              <CustomInput
                defaultValue={code}
                label={t("bcf.code")}
                onChange={handleCodeChange}
              />
              <CustomInput
                label={t("bcf.numadab")}
                defaultValue={nombre}
                onChange={handleNombreChange}
              />
              <CustomSelect
                onChange={handleFournisseurChange}
                label={t("bcf.fournisseur")}
                options={transformedFournisseurData}
              />
              <CustomSelect
                onChange={handleDepotChange}
                label={t("bcf.depot")}
                options={transformedDepotData}
              />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={language === "ar" ? <CloudUploadIcon /> : null}
                endIcon={language === "fr" ? <CloudUploadIcon /> : null}
              >
                {t("bcf.image")}
                <VisuallyHiddenInput type="file" onChange={handleImageChange} />
              </Button>
              {fileName && (
                <p>
                  {t("bcf.simg")} : {fileName}
                </p>
              )}
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" mb={"1rem"}>
                    {t("bcf.prs")}
                  </Typography>
                  <CustomSelect
                    onChange={handleProductChange}
                    label={t("bcf.listart")}
                    options={transformedArticleData}
                    value={
                      selectedProduct ? selectedProduct.code_article : null
                    }
                  />

                  {selectedProduct && (
                    <Fragment>
                      <TextField
                        label={t("bcf.quantite")}
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 1 }}
                      />
                      <TextField
                        label={t("bcf.prixact")}
                        type="number"
                        value={price}
                        onChange={handlePriceChange}
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 1 }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={addToCart}
                        disabled={!selectedProduct}
                      >
                        {t("bcf.add")}
                      </Button>
                    </Fragment>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" m={"1rem 0"}>
                    {t("bcf.listart")}
                  </Typography>
                  {cart.length === 0 ? (
                    <p>{t("bcf.listvide")}.</p>
                  ) : (
                    <List>
                      {cart.map((item) => (
                        <ListItem
                          key={item.code_article}
                          secondaryAction={
                            <IconButton
                              onClick={() => handleRemoveItem(item)}
                              edge="end"
                            >
                              <DeleteForeverSharp />
                            </IconButton>
                          }
                        >
                          <ListItemText
                            primary={item.designation}
                            secondary={`${t("bcf.quantite")} : ${item.quantity} 
                            - ${t("bcf.prixact")} : ${item.prix_actuel}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Grid>
              </Grid>
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("frs.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default BonCommandeFournisseur;
