import { createSlice } from "@reduxjs/toolkit";
import {
  handleSelectAction,
  handleClearSelectedAction,
  getBonRetourFournisseur,
  addBonRetourFournisseur,
  updateBonRetourFournisseur,
} from "./actions";

const initialState = {
  data: [],
  filteredData: [],
  selected: {},
  loadingAll: "idle",
  loading: "idle",
  error: undefined,
};
const bonRetourFournisseurReducer = createSlice({
  name: "bonRetourFournisseur",
  initialState,
  reducers: {
    handleSelect: handleSelectAction,
    handleClearSelected: handleClearSelectedAction,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBonRetourFournisseur.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(getBonRetourFournisseur.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(getBonRetourFournisseur.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(addBonRetourFournisseur.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(addBonRetourFournisseur.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingAll = "succeeded";
      })
      .addCase(addBonRetourFournisseur.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(updateBonRetourFournisseur.pending, (state) => {
        state.loadingAll = "loading";
        state.error = undefined;
      })
      .addCase(updateBonRetourFournisseur.fulfilled, (state, action) => {
        state.data = action.payload.newData;
        state.loadingAll = "succeeded";
      })
      .addCase(updateBonRetourFournisseur.rejected, (state, action) => {
        state.loadingAll = "failed";
        state.error = action.payload;
      });
  },
});

export const { handleSelect, handleClearSelected } =
  bonRetourFournisseurReducer.actions;

export const selectBonRetourFournisseur = (state) => state.bonRetourFournisseur;

export default bonRetourFournisseurReducer.reducer;
