import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBonRetourInterne = createAsyncThunk(
  "GET-BON-RETOUR-INTERNE",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get(
        "https://www.gbgc.tn/bon_retour_interne/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addBonRetourInterne = createAsyncThunk(
  "ADD-BON-RETOUR-INTERNE",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const bonRetourInterneState = getState().bonRetourInterneReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/bon_retour_interne/",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      return [...bonRetourInterneState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBonRetourInterne = createAsyncThunk(
  "UPDATE-BON-RETOUR-INTERNE",
  async (
    { bonRetourInterneId, updatedData },
    { rejectWithValue, getState }
  ) => {
    console.log("🚀 ~ bonRetourInterneId:", bonRetourInterneId);
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().bonRetourInterneReducer.data;

      const response = await axios.put(
        `https://www.gbgc.tn/bon_retour_interne/update/${bonRetourInterneId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedBonRetourInterne = response.data;

      const newData = prevData.map((bonRetourInterne) =>
        bonRetourInterne.code_bon_retour_interne === bonRetourInterneId
          ? updatedBonRetourInterne
          : bonRetourInterne
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_bon_retour_interne === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
