import React, { useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomButton from "components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "components/Modal/CustomModal";

import CustomInput from "components/Input/CustomInput";
import CustomGrid from "components/Grid/CustomGrid";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";
import { addConfined, deleteConfined } from "store/confined/actions";
import useReceipt from "hooks/useReceipt";
import {
  addReceipt,
  deleteReceipt,
  updateReceipt,
} from "store/receipt/actions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
const Receipt = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useReceipt();
  const { t } = useTranslation();

  const language = useSelector((state) => state.global.language);
  const confinedData = useSelector((state) => state.confinedReducer.data);
  const [fullname, setFullname] = useState("");
  const [cin, setCIN] = useState("");
  const [dateCin, SetDateCin] = useState("");
  const [details, setDetails] = useState("");
  const [confined, setConfined] = useState("");
  const dispatch = useDispatch();
  const transformedConfinedData = confinedData.map((item) => ({
    id: item.id,
    label: item.fullName,
  }));
  const defaultConfinedOption = transformedConfinedData.find((option) => {
    return option.id == selected.code_confined;
  });
  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };
  const handleDateCinChange = (date) => {
    SetDateCin(date);
  };
  const handleConfinedChange = (event, selectedOption) => {
    setConfined(selectedOption);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      fullName: fullname,
      dateCin: dateCin ? dayjs(dateCin).format("YYYY-MM-DD") : null,
      detail: details,
      cin: cin,
      codeConfined: confined.id,
    };
    dispatch(addReceipt(postData));
    handleClose();
  };
  const handleUpdate = (e, ReceiptId) => {
    e.preventDefault();
    const updatedData = {
      fullName: fullname !== "" ? fullname : selected.full_name,
      dateCin: dateCin
        ? dayjs(dateCin).format("YYYY-MM-DD")
        : selected.date_cin,
      detail: details !== "" ? details : selected.detail,
      cin: cin !== "" ? cin : selected.cin,
      codeConfined:
        confined.id !== undefined ? confined.id : selected.code_confined,
    };
    
    dispatch(updateReceipt({ ReceiptId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (ReceiptId) => {
    dispatch(deleteReceipt(ReceiptId));
  };
  const columns = [
    {
      field: "fullName",
      headerName: "full name",
      flex: 0.4,
    },
    {
      field: "cin",
      headerName: "cin",
      flex: 0.4,
    },
    {
      field: "date_cin",
      headerName: "date_cin",
      flex: 0.4,
    },
    {
      field: "detail",
      headerName: "detail",
      flex: 0.4,
    },
    {
      field: "date",
      headerName: "date",
      flex: 0.4,
    },
    {
      field: "confined",
      headerName: "confined",
      flex: 0.4,
    },
    {
      field: "modifier",
      headerName: t("articles.modifier"),
      flex: 0.3,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("articles.supprimer"),
      flex: 0.3,
      renderCell: (params) => (
        <CustomButton
          children={t("famille.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const dateCreate = new Date(item.date_receipt);
      const dateCIN = new Date(item.date_cin);
      const formattedDateCreate = dateCreate.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const formattedDateCIN = dateCIN.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return {
        id: item.id,
        fullName: item.full_name,
        cin: item.cin,
        date_cin: formattedDateCIN,
        detail: item.detail,
        date: formattedDateCreate,
        confined: item.code_confined,
      };
    });
  }, [data]);
  const createConfinedButton = (
    <CustomButton
      children={t("articles.creerarticle")}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );

  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste des récus"}
        search={handleSearchChange}
        button={createConfinedButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.id}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {"modifier un agent confined"}
              </Typography>
              <CustomSelect
                defaultValue={defaultConfinedOption}
                options={transformedConfinedData}
                label={"confined"}
                onChange={handleConfinedChange}
              />
              <CustomInput
                value={selected.full_name}
                label={"Full Name"}
                onChange={handleChange(setFullname)}
              />
              <CustomInput
                value={selected.cin}
                label={"CIN"}
                onChange={setCIN}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  defaultValue={dayjs(selected.date_cin)}
                  label="date CIN"
                  onChange={handleDateCinChange}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
              <CustomInput
                value={selected.detail}
                label={"Details"}
                onChange={setDetails}
              />
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.id)}
                size={"large"}
                variant={"contained"}
                children={t("cat.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("articles.creerarticle")}
              </Typography>
              <CustomSelect
                options={transformedConfinedData}
                label={"confined"}
                onChange={handleConfinedChange}
              />
              <CustomInput
                label={"Full Name"}
                onChange={handleChange(setFullname)}
              />
              <CustomInput label={"CIN"} onChange={setCIN} />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="date CIN"
                  onChange={handleDateCinChange}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
              <CustomInput label={"Details"} onChange={setDetails} />
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("articles.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Receipt;
