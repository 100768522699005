import React, { useEffect, useMemo, useState } from "react";
import useArticle from "hooks/useArticle";
import {
  Box,
  Button,
  Checkbox,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Header from "components/Header/Header";
import CustomDataGrid from "components/DataGrid/DataGrid";
import CustomButton from "components/Button/CustomButton";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomModal from "components/Modal/CustomModal";

import CustomInput from "components/Input/CustomInput";
import CustomGrid from "components/Grid/CustomGrid";
import CustomSelect from "components/Input/CustomSelect";
import { useTranslation } from "react-i18next";
import { addSide, deleteSide } from "store/side/actions";
import useAgent from "hooks/useAgent";
import { addAgent, deleteAgent, updateAgent } from "store/agent/actions";
const Agent = () => {
  const {
    data,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    open,
    updateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleSearchChange,
  } = useAgent();
  const items = ["A1", "A", "C+E", "C", "D+E", "H", "D", "B", "B+E", "D1"];

  const { t } = useTranslation();
  const [uid, setUID] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [telephone, setTelephone] = useState("");
  const [checkedItems, setCheckedItems] = useState(
    items.reduce((acc, item) => {
      acc[item] = false;
      return acc;
    }, {})
  );
  useEffect(() => {
    if (selected && selected.permit) {
      const permits = JSON.parse(selected.permit);
      const updatedCheckedItems = items.reduce((acc, item) => {
        acc[item] = permits.includes(item);
        return acc;
      }, {});
      setCheckedItems(updatedCheckedItems);
      setUID(selected.uid);
      setNom(selected.nom);
      setPrenom(selected.prenom);
      setTelephone(selected.telephone);
    }
  }, [selected]);

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const language = useSelector((state) => state.global.language);

  const dispatch = useDispatch();

  const handleChange = (setStateFn) => (data) => {
    setStateFn(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedPermits = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    );
    const postData = {
      uid: uid,
      nom: nom,
      prenom: prenom,
      telephone: telephone,
      permit: selectedPermits,
    };
    dispatch(addAgent(postData));
    handleClose();
  };
  const handleUpdate = (e, agentId) => {
    e.preventDefault();
    const selectedPermits = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    );
    const updatedData = {
      nom: nom !== "" ? nom : selected.nom,
      prenom: prenom !== "" ? prenom : selected.prenom,
      telephone: telephone !== "" ? telephone : selected.telephone,
      permit: selectedPermits,
    };
    dispatch(updateAgent({ agentId, updatedData }));
    handleUpdateClose();
  };
  const handleDelete = async (agentId) => {
    dispatch(deleteAgent(agentId));
  };
  const columns = [
    {
      field: "id",
      headerName: "uid",
      flex: 0.4,
    },
    {
      field: "nom",
      headerName: "nom",
      flex: 0.4,
    },
    {
      field: "prenom",
      headerName: "prenom",
      flex: 0.4,
    },
    {
      field: "telephone",
      headerName: "telephone",
      flex: 0.4,
    },
    {
      field: "permit",
      headerName: "permit",
      flex: 0.7,
    },

    {
      field: "modifier",
      headerName: t("articles.modifier"),
      flex: 0.3,
      renderCell: (params) => (
        <Button
          startIcon={language === "ar" ? <CreateIcon /> : null}
          endIcon={language === "fr" ? <CreateIcon /> : null}
          variant="contained"
          color={"warning"}
          onClick={() => handleOpenModal(params.id)}
        >
          {t("brf.modifier")}
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: t("articles.supprimer"),
      flex: 0.3,
      renderCell: (params) => (
        <CustomButton
          children={t("famille.supprimer")}
          startIcon={language === "ar" ? <DeleteIcon /> : null}
          endIcon={language === "fr" ? <DeleteIcon /> : null}
          color={"error"}
          size={"small"}
          variant={"contained"}
          onClick={() => handleDelete(params.id)}
        />
      ),
    },
  ];
  const dataWithButtons = useMemo(() => {
    return data.map((item) => {
      const permits = JSON.parse(item.permit);
      const permitString = permits.join(", ");
      return {
        id: item.uid,
        nom: item.nom,
        prenom: item.prenom,
        telephone: item.telephone,
        permit: permitString,
      };
    });
  }, [data]);
  const createConfinedButton = (
    <CustomButton
      children={"cree un brigade"}
      startIcon={language === "ar" ? <AddIcon /> : null}
      endIcon={language === "fr" ? <AddIcon /> : null}
      color={"success"}
      size={"small"}
      variant={"contained"}
      onClick={handleOpen}
    />
  );

  return (
    <Box m={"1.5rem 2.5rem"}>
      <Header
        title={"liste des brigades de police"}
        search={handleSearchChange}
        button={createConfinedButton}
      />
      <CustomDataGrid
        data={data}
        loading={loadingAll === "loading" || !data}
        dataWithButtons={dataWithButtons}
        columns={columns}
      />
      {updateOpen && (
        <CustomModal
          key={selected.uid}
          onClick={handleUpdateOpen}
          onClose={handleUpdateClose}
          open={updateOpen}
          backgroundColor={"#fff"}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {"modifier un agent confined"}
              </Typography>

              <CustomInput
                value={selected.nom}
                label={"nom"}
                onChange={handleChange(setNom)}
              />
              <CustomInput
                value={selected.prenom}
                label={"prenom"}
                onChange={handleChange(setPrenom)}
              />
              <CustomInput
                value={selected.telephone}
                type={"number"}
                label={"telephone"}
                onChange={handleChange(setTelephone)}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {items.map((item) => (
                  <Box key={item}>
                    <Checkbox
                      checked={checkedItems[item]}
                      onChange={handleCheck}
                      name={item}
                      inputProps={{ "aria-label": item }}
                    />
                    {item}
                  </Box>
                ))}
              </Box>
              <CustomButton
                onClick={(e) => handleUpdate(e, selected.uid)}
                size={"large"}
                variant={"contained"}
                children={t("cat.modifier")}
              />
            </form>
          }
        />
      )}
      {open && (
        <CustomModal
          onClose={handleClose}
          open={open}
          children={
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Typography variant="h3" mb={"1rem"}>
                {t("articles.creerarticle")}
              </Typography>
              <CustomInput label={"uid"} onChange={handleChange(setUID)} />
              <CustomInput label={"nom"} onChange={handleChange(setNom)} />
              <CustomInput
                label={"prenom"}
                onChange={handleChange(setPrenom)}
              />
              <CustomInput
                type={"number"}
                label={"telephone"}
                onChange={handleChange(setTelephone)}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {items.map((item) => (
                  <Box key={item}>
                    <Checkbox
                      checked={checkedItems[item]}
                      onChange={handleCheck}
                      name={item}
                      inputProps={{ "aria-label": item }}
                    />
                    {item}
                  </Box>
                ))}
              </Box>
              <CustomButton
                onClick={handleSubmit}
                size={"large"}
                variant={"contained"}
                color={"success"}
                children={t("articles.creer")}
              />
            </form>
          }
        />
      )}
    </Box>
  );
};

export default Agent;
