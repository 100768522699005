import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "state";
import categorieReducer from "./categorie/slice";
import familleReducer from "./famille/slice";
import depotReducer from "./depot/slice";
import fournisseurReducer from "./fournisseur/slice";
import articleReducer from "./article/slice";
import bonCommandeFournisseurReducer from "./BonCommandeFournisseur/slice";
import bonCommandeInterneReducer from "./BonCommandeInterne/slice";
import bonEntreReducer from "./BonEntre/slice";
import bonAvoirReducer from "./BonAvoir/slice";
import bonSortieReducer from "./BonSortie/slice";
import bonRetourInterneReducer from "./bonRetourInterne/slice";
import bonRetourFournisseurReducer from "./BonRetourFournisseur/slice";
import siteReducer from "./site/slice";
import userReducer from "./user/slice";
import inventaireReducer from "./inventaire/slice";
import dashboardReducer from "./Dashboard/slice";
import quotaReducer from "./quota/slice";
import confinedReducer from "./confined/slice";
import receiptReducer from "./receipt/slice";
import sideReducer from "./side/slice";
import agentConfinedReducer from "./agentConfined/slice";
import produitReducer from "./produit/slice";
import enginReducer from "./engin/slice";
import consommableReducer from "./consommable/slice";
import agentReducer from "./agent/slice";
import consommationEnginReducer from "./consommationEngin/slice";
import consommationMaterielReducer from "./consommationMateriel/slice";
import petitMaterielReducer from "./petitMateriaux/slice";

const store = configureStore({
  reducer: {
    global: globalReducer,
    dashboardReducer: dashboardReducer,
    categorieReducer: categorieReducer,
    familleReducer: familleReducer,
    depotReducer: depotReducer,
    fournisseurReducer: fournisseurReducer,
    articleReducer: articleReducer,
    bonCommandeFournisseurReducer: bonCommandeFournisseurReducer,
    bonEntreReducer: bonEntreReducer,
    bonAvoirReducer: bonAvoirReducer,
    bonRetourFournisseurReducer: bonRetourFournisseurReducer,
    bonCommandeInterneReducer: bonCommandeInterneReducer,
    bonSortieReducer: bonSortieReducer,
    bonRetourInterneReducer: bonRetourInterneReducer,
    siteReducer: siteReducer,
    userReducer: userReducer,
    inventaireReducer: inventaireReducer,
    quotaReducer: quotaReducer,
    confinedReducer: confinedReducer,
    receiptReducer: receiptReducer,
    sideReducer: sideReducer,
    agentConfinedReducer: agentConfinedReducer,
    produitReducer: produitReducer,
    enginReducer: enginReducer,
    consommableReducer: consommableReducer,
    agentReducer: agentReducer,
    consommationEnginReducer: consommationEnginReducer,
    consommationMaterielReducer: consommationMaterielReducer,
    petitMaterielReducer: petitMaterielReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
    ];
  },
});

export { store };
