import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
function TunisiaFlagIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 36 36">
      <path
        fill="#E70013"
        d="M32 5H4a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4z"
      ></path>
      <circle fill="#FFF" cx="18" cy="18" r="6.5"></circle>
      <path
        fill="#E70013"
        d="M15.4 18a3.9 3.9 0 0 1 6.541-2.869a4.875 4.875 0 1 0 0 5.738A3.9 3.9 0 0 1 15.4 18z"
      ></path>
      <path
        fill="#E70013"
        d="M19.645 16.937l-1.249-1.719v2.125L16.375 18l2.021.657v2.125l1.249-1.719l2.021.656L20.417 18l1.249-1.719z"
      ></path>
      <path
        fill="#E70013"
        d="M11.9 8.9a0.8 0.8 0 1 1 0-1.6 0.8 0.8 0 1 1 0 1.6z"
      />
    </SvgIcon>
  );
}
export default TunisiaFlagIcon;
