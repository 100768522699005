import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSelect } from "store/BonSortie/slice";

const useBonSortie = () => {
  const { data, loadingAll, loading, selected, error } = useSelector(
    (state) => state.bonSortieReducer
  );
  const [articles, setArticles] = useState([]);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setArticles([]);
  };
  const handleUpdateOpen = () => setUpdateOpen(true);
  const handleUpdateClose = () => {
    setUpdateOpen(false);
    setArticles([]);
  };
  const handleDetailOpen = () => setDetailOpen(true);
  const handleDetailClose = () => setDetailOpen(false);
  const dispatch = useDispatch();

  const _handleSelectOne = (key) => {
    dispatch(handleSelect({ key: key }));
  };
  const handleOpenModal = (key) => {
    _handleSelectOne(key);
    handleUpdateOpen();
  };
  const handleDetailOpenModal = (key) => {
    _handleSelectOne(key);
    handleDetailOpen();
  };

  const handleSearchChange = (search) => {
    setSearchTerm(search.target.value);
    if (search) {
      const filteredItems = data.filter((article) =>
        article.code_bon_sortie
          .toLowerCase()
          .includes(search.target.value.toLowerCase())
      );
      setFilteredData(filteredItems);
    } else {
      setFilteredData(data);
    }
  };
  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  return {
    data :filteredData,
    loadingAll,
    loading,
    error,
    handleOpenModal,
    selected,
    setOpen,
    open,
    detailOpen,
    updateOpen,
    setUpdateOpen,
    handleOpen,
    handleClose,
    handleUpdateClose,
    handleUpdateOpen,
    handleDetailClose,
    handleDetailOpenModal,
    handleDetailOpen,
    handleSearchChange,
    articles,
    setArticles,
  };
};

export default useBonSortie;
