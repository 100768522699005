import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAgentConfined = createAsyncThunk(
  "GET-AGENT-CONFINED",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/agent_confined/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data.agentConfined;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addAgentConfined = createAsyncThunk(
  "ADD-AGENT-CONFINED",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const agentConfinedState = getState().agentConfinedReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/agent_confined/add",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );
      return [...agentConfinedState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteAgentConfined = createAsyncThunk(
  "DELETE-AGENT-CONFINED",
  async (params, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().agentConfinedReducer.data;
      const prevFilteredData = getState().agentConfinedReducer.filteredData;
      let newData = [];
      let newFilteredData = [];

      await axios.delete(`https://www.gbgc.tn/agent_confined/${params}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      newData = [...prevData.filter((item) => item.uid !== params)];
      newFilteredData = [
        ...prevFilteredData.filter((item) => item.uid !== params),
      ];

      return { newData, newFilteredData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateAgentConfined = createAsyncThunk(
  "UPDATE-AGENT-CONFINED",
  async ({ agentConfinedId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().agentConfinedReducer.data;
      const response = await axios.put(
        `https://www.gbgc.tn/agent_confined/${agentConfinedId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedAgentConfined = response.data;

      const newData = prevData.map((agentConfined) =>
        agentConfined.uid === agentConfinedId
          ? updatedAgentConfined
          : agentConfined
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find((s) => s.uid === action.payload.key);
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
