import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSites = createAsyncThunk(
  "GET-SITE",
  async (_, { rejectWithValue }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const response = await axios.get("https://www.gbgc.tn/api/sites", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const addSite = createAsyncThunk(
  "ADD-SITE",
  async (postData, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const siteState = getState().siteReducer.data;

      const response = await axios.post(
        "https://www.gbgc.tn/api/sites",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      return [...siteState, { ...response.data }];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteSite = createAsyncThunk(
  "DELETE-SITE",
  async (params, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().siteReducer.data;
      const prevFilteredData = getState().siteReducer.filteredData;
      let newData = [];
      let newFilteredData = [];

      await axios.delete(`https://www.gbgc.tn/api/sites/${params}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${JwtToken}`,
        },
      });
      newData = [...prevData.filter((item) => item.code_site !== params)];
      newFilteredData = [
        ...prevFilteredData.filter((item) => item.code_site !== params),
      ];

      return { newData, newFilteredData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateSite = createAsyncThunk(
  "UPDATE-SITE",
  async ({ siteId, updatedData }, { rejectWithValue, getState }) => {
    try {
      const JwtToken = localStorage.getItem("token");
      const prevData = getState().siteReducer.data;

      // Assuming your backend API supports updating a category by its ID
      const response = await axios.put(
        `https://www.gbgc.tn/api/sites/${siteId}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${JwtToken}`,
          },
        }
      );

      const updatedSite = response.data;

      const newData = prevData.map((site) =>
        site.code_site === siteId ? updatedSite : site
      );

      return { newData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const handleSelectAction = (state, action) => {
  const findSelected = state.data.find(
    (s) => s.code_site === action.payload.key
  );
  state.selected = findSelected;
};

export const handleClearSelectedAction = (state) => {
  state.selected = {};
};
